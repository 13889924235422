unit Auth.Service;

interface

uses
  SysUtils, Web, JS,
  XData.Web.Client;

const
  TOKEN_NAME = 'GA_TENOVUS_TOKEN';

type
  TOnLoginSuccess = reference to procedure;
  TOnLoginError = reference to procedure(AMsg: string);

  TAuthService = class
  private
    FClient: TXDataWebClient;
    procedure DeleteToken;
    function GetLocationType: String;
    function GetScope: String;
    function GetUserLevel: String;
    function GetUserId: Integer;
    function GetUserFullName: String;
    function GetIsAdminUserOrBetter: Boolean;
    function GetIsAdministrator: Boolean;
  public
    constructor Create; reintroduce;
    destructor Destroy; override;
    procedure Login(AUser, APassword: string; ASuccess: TOnLoginSuccess;
      AError: TOnLoginError);
    procedure Logout(const UserInitiated: Boolean = False);
    procedure SetToken(AToken: string);
    function GetToken: string;
    function Authenticated: Boolean;
    function TokenExpirationDate: TDateTime;
    function TokenExpired: Boolean;
    function TokenPayload: JS.TJSObject;
    function ClaimValue(const AName: string): String;
    property UserLevel: String read GetUserLevel;
    property UserScope: String read GetScope;
    property UserFullName: String read GetUserFullName;
    property LocationType: String read GetLocationType;
    property UserId: Integer read GetUserId;
    property IsAdminUserOrBetter: Boolean read GetIsAdminUserOrBetter;
    property IsAdministrator: Boolean read GetIsAdministrator;
  end;

  TJwtHelper = class
  private
    class function HasExpirationDate(AToken: string): Boolean;
  public
    class function TokenExpirationDate(AToken: string): TJSDate;
    class function TokenExpired(AToken: string): Boolean;
    class function DecodePayload(AToken: string): string;
    class function ClaimValue(const AToken, AName: string): String; overload;
    class function ClaimValue(const AToken: TJSObject; const AName: string): String; overload;
    class function ClaimValueInt(const AToken, AName: string): Integer;
    class function PayloadObject(const AToken: String): TJSObject;
  end;

  function AuthService: TAuthService;

implementation

uses
  MainDataModule, SMX.Shared;

var
  _AuthService: TAuthService;

const
AUTH_SERVICE_LOGIN = 'ILoginService.Login';

function AuthService: TAuthService;
begin
  if not Assigned(_AuthService) then
  begin
    _AuthService := TAuthService.Create;
  end;
  Result := _AuthService;
end;

{ TAuthService }

function TAuthService.Authenticated: Boolean;
begin
  Result := not isNull(window.localStorage.getItem(TOKEN_NAME)) and
            (window.localStorage.getItem(TOKEN_NAME) <> '');
end;

function TAuthService.ClaimValue(const AName: string): String;
begin
  Result := TJWTHelper.ClaimValue(GetToken, AName);
end;

constructor TAuthService.Create;
begin
  FClient := TXDataWebClient.Create(nil);
  FClient.Connection := MainData.AuthConnection;
end;

procedure TAuthService.DeleteToken;
begin
  window.localStorage.removeItem(TOKEN_NAME);
end;

destructor TAuthService.Destroy;
begin
  FClient.Free;
  inherited;
end;

function TAuthService.GetIsAdministrator: Boolean;
var lLevel: String;
begin
  lLevel := GetScope;
  Result := (lLevel = SCOPE_ADMIN);
end;

function TAuthService.GetIsAdminUserOrBetter: Boolean;
var lLevel: String;
begin
  lLevel := GetScope;
  Result := (lLevel = SCOPE_ADMIN) or (lLevel = SCOPE_SUPERUSER);
end;

function TAuthService.GetLocationType: String;
begin
  Result := ClaimValue(CLAIM_LOCATIONTYPE);
end;

function TAuthService.GetScope: String;
begin
  Result := ClaimValue(CLAIM_SCOPE);
end;

function TAuthService.GetToken: string;
begin
  Result := window.localStorage.getItem(TOKEN_NAME);
end;

function TAuthService.GetUserFullName: String;
begin
  Result := ClaimValue(CLAIM_FULLNAME);
end;

function TAuthService.GetUserId: Integer;
begin
    Result := TJWTHelper.ClaimValueInt(GetToken, CLAIM_USERID);
end;

function TAuthService.GetUserLevel: String;
begin
  Result := ClaimValue(CLAIM_ROLE);
end;

procedure TAuthService.Login(AUser, APassword: string; ASuccess: TOnLoginSuccess;
  AError: TOnLoginError);

  procedure OnLoad(Response: TXDataClientResponse);
  var
    Token: JS.TJSObject;
  begin
    Token := Response.ResultAsObject;
    SetToken(JS.toString(Token.Properties['value']));
    ASuccess;
  end;

  procedure OnError(Error: TXDataClientError);
  begin
    AError(Format('%s: %s', [Error.ErrorCode, Error.ErrorMessage]));
  end;

begin
  if (AUser = '') or (APassword = '') then
  begin
    AError('Enter the username and password!');
    Exit;
  end;

  FClient.RawInvoke(
    AUTH_SERVICE_LOGIN, [AUser, APassword],
    @OnLoad, @OnError
  );
end;

procedure TAuthService.Logout(const UserInitiated: Boolean = False);
begin
  //call back to server logout(timeout or user)
  DeleteToken;
end;

procedure TAuthService.SetToken(AToken: string);
begin
  window.localStorage.setItem(TOKEN_NAME, AToken);
end;

function TAuthService.TokenExpirationDate: TDateTime;
var
  ExpirationDate: TJSDate;
begin
  if not Authenticated then
    Exit(Now);

  ExpirationDate := TJwtHelper.TokenExpirationDate(GetToken);

  Result := EncodeDate(
              ExpirationDate.FullYear,
              ExpirationDate.Month + 1,
              ExpirationDate.Date
            ) +
            EncodeTime(
              ExpirationDate.Hours,
              ExpirationDate.Minutes,
              ExpirationDate.Seconds,
              0
            );
end;

function TAuthService.TokenExpired: Boolean;
begin
  if not Authenticated then
    Exit(False);
  Result := TJwtHelper.TokenExpired(GetToken);
end;

function TAuthService.TokenPayload: JS.TJSObject;
begin
  if not Authenticated then
    Exit(nil);
  Result := TJSObject(TJSJSON.parse(TJwtHelper.DecodePayload(GetToken)));
end;

{ TJwtHelper }

class function TJwtHelper.ClaimValue(const AToken, AName: string): String;
var
  Payload: string;
  Obj: TJSObject;
begin
  Result := '';
  Payload := DecodePayload(AToken);
  Obj := TJSObject(TJSJSON.parse(Payload));
  if Obj.hasOwnProperty(AName) then
     Result := JS.toString(Obj.Properties[AName]);
end;

class function TJwtHelper.ClaimValue(const AToken: TJSObject; const AName: string): String;
begin
  if AToken.hasOwnProperty(AName) then
     Result := JS.toString(AToken.Properties[AName])
  else
     Result := '';
end;

class function TJwtHelper.ClaimValueInt(const AToken, AName: string): Integer;
var
  Payload: string;
  Obj: TJSObject;
begin
  Result := -1;
  Payload := DecodePayload(AToken);
  Obj := TJSObject(TJSJSON.parse(Payload));
  if Obj.hasOwnProperty(AName) then
     Result := JS.toInteger(Obj.Properties[AName]);
end;

class function TJwtHelper.DecodePayload(AToken: string): string;
begin
  if Trim(AToken) = '' then
    Exit('');
  Result := '';
  asm
    var Token = AToken.split('.');
    if (Token.length = 3) {
      Result = Token[1];
      Result = atob(Result);
    }
  end;
end;

class function TJwtHelper.HasExpirationDate(AToken: string): Boolean;
var
  Payload: string;
  Obj: TJSObject;
begin
  Payload := DecodePayload(AToken);
  Obj := TJSObject(TJSJSON.parse(Payload));
  Result := Obj.hasOwnProperty('exp');
end;

class function TJwtHelper.PayloadObject(const AToken: String): TJSObject;
var
  Payload: string;
begin
  Payload := DecodePayload(AToken);
  Result := TJSObject(TJSJSON.parse(Payload));
end;

class function TJwtHelper.TokenExpirationDate(AToken: string): TJSDate;
var
  Payload: string;
  Obj: TJSObject;
  Epoch: NativeInt;
begin
  if not HasExpirationDate(AToken) then
    raise Exception.Create('Token has not expiration date');

  Payload := DecodePayload(AToken);
  Obj := TJSObject(TJSJSON.parse(Payload));
  Epoch := toInteger(Obj.Properties['exp']);
  Result := TJSDate.New(Epoch * 1000);
end;

class function TJwtHelper.TokenExpired(AToken: string): Boolean;
begin
  if not HasExpirationDate(AToken) then
    Exit(False);
  Result := TJSDate.now > toInteger(TokenExpirationDate(AToken).valueOf);
end;

end.
