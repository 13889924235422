unit GiftAidClaimsList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Toast,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  App.Utils,
  smx.webcore.types,
  WEBLib.ExtCtrls;

type
  TGiftAidClaims = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetTransactionId: TStringField;
    MainDatasetClaimRef: TStringField;
    MainDatasetCorrelationId: TStringField;
    MainDatasetIRMark: TStringField;
    MainDatasetIRMarkReceipt: TBlobField;
    MainDatasetFirstDonationDate: TDateField;
    MainDatasetLastDonationDate: TDateField;
    MainDatasetSubmissionDate: TDateTimeField;
    MainDatasetEarliestSubmissionDate: TDateField;
    MainDatasetDonationCount: TIntegerField;
    MainDatasetGADAmount: TFloatField;
    MainDatasetOtherIncome: TFloatField;
    MainDatasetAdjustment: TFloatField;
    MainDatasetGASDAmount: TFloatField;
    MainDatasetGASDSAdjustment: TFloatField;
    MainDatasetGiftAidClaim: TFloatField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetLastStatusChange: TDateTimeField;
    MainDatasetSubmittedBy: TIntegerField;
    MainDatasetHMRCOfficial: TIntegerField;
    MainDatasetComments: TStringField;
    MainDatasetOtherAmounts: TFloatField;
    MainDatasetIRMark64: TStringField;
    MainDatasetNotificationId: TIntegerField;
    MainDatasetPaymentReceivedDate: TDateField;
    procedure MainDatasetCalcFields(Dataset: TDataSet);
    procedure MainDatasetClaimStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MainDatasetExportedForREGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MainDatasetGADAmountGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MainDatasetGiftAidClaimGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MainDatasetOtherAmountsGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MainDatasetSubmissionDateGetText(Sender: TField; var Text: string; DisplayText: Boolean);
  private
    { Private declarations }
    [async]
    procedure MarkAsPaid(const AId: Integer); async;
  protected
    function RecordViewOption: TViewOption; override;
    function GetEditFormClass: TFormClass; override;
    procedure PrepareForm; override;
    procedure LoadData; override;
    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  GiftAidClaims: TGiftAidClaims;

implementation

uses
  WEBLib.WebCtrls,
  System.DateUtils,
  MainDataModule, App.Layout.Utils, GiftAidDetailsForm;

{$R *.dfm}

procedure TGiftAidClaims.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure MarkThisAsPaid(Sender: TObject);
  begin
    MarkAsPaid(ARecordId.ToInteger);
  end;

var
  Span: THTMLSpan;
begin
  inherited;
  if (MainDatasetClaimStatus.Value = 'Confirmed') then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-hand-receiving');
    Span.OnClick := @MarkThisAsPaid;
  end;
end;

function TGiftAidClaims.GetEditFormClass: TFormClass;
begin
  Result := TGiftAidDetails;
end;

function TGiftAidClaims.InitSortCol: Integer;
begin
  Result := 0;
end;

function TGiftAidClaims.InitSortDirection: TSortDirection;
begin
  Result := sdDesc;
end;

procedure TGiftAidClaims.LoadData;
begin
  FGridPlugin.Load;
end;

procedure TGiftAidClaims.MainDatasetCalcFields(Dataset: TDataSet);
begin
  MainDatasetOtherAmounts.Value := MainDatasetGASDAmount.Value + MainDatasetOtherIncome.Value -
    MainDatasetAdjustment.Value - MainDatasetGASDSAdjustment.Value;
end;

procedure TGiftAidClaims.MainDatasetClaimStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := TSysUtils.SplitOnCaps((Sender as TStringField).AsString);
end;

procedure TGiftAidClaims.MainDatasetExportedForREGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  if not Sender.IsNull then
    Text := FormatDateTime(SYS_DATE_FORMAT, (Sender as TDateField).AsDateTime);
end;

procedure TGiftAidClaims.MainDatasetGADAmountGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatFloat(SYS_MONEY_FORMAT, (Sender as TFloatField).AsFloat);
end;

procedure TGiftAidClaims.MainDatasetGiftAidClaimGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatFloat(SYS_MONEY_FORMAT, (Sender as TFloatField).AsFloat);
end;

procedure TGiftAidClaims.MainDatasetOtherAmountsGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatFloat(SYS_MONEY_FORMAT, (Sender as TFloatField).AsFloat);
end;

procedure TGiftAidClaims.MainDatasetSubmissionDateGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  if not Sender.IsNull then
    Text := FormatDateTime(SYS_DATE_FORMAT, (Sender as TDateTimeField).AsDateTime);
end;

procedure TGiftAidClaims.MarkAsPaid(const AId: Integer);
begin
  if not MainDataset.Locate('Id', AId, []) then
  begin
    ShowMessage('please refresh the page and try again');
    Exit;
  end;

  MainDataset.Edit;
  MainDatasetClaimStatus.Value := 'PaymentReceived';
  MainDatasetPaymentReceivedDate.Value := Today;
  MainDatasetLastStatusChange.Value := Now;
  MainDataset.Post;
end;

procedure TGiftAidClaims.PrepareForm;
begin
  FIdField := 'Id';
end;

function TGiftAidClaims.RecordViewOption: TViewOption;
begin
  Result := TViewOption.voReadOnly;
end;

procedure TGiftAidClaims.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetTransactionId := TStringField.Create(Self);
  MainDatasetClaimRef := TStringField.Create(Self);
  MainDatasetCorrelationId := TStringField.Create(Self);
  MainDatasetIRMark := TStringField.Create(Self);
  MainDatasetIRMarkReceipt := TBlobField.Create(Self);
  MainDatasetFirstDonationDate := TDateField.Create(Self);
  MainDatasetLastDonationDate := TDateField.Create(Self);
  MainDatasetSubmissionDate := TDateTimeField.Create(Self);
  MainDatasetEarliestSubmissionDate := TDateField.Create(Self);
  MainDatasetDonationCount := TIntegerField.Create(Self);
  MainDatasetGADAmount := TFloatField.Create(Self);
  MainDatasetOtherIncome := TFloatField.Create(Self);
  MainDatasetAdjustment := TFloatField.Create(Self);
  MainDatasetGASDAmount := TFloatField.Create(Self);
  MainDatasetGASDSAdjustment := TFloatField.Create(Self);
  MainDatasetGiftAidClaim := TFloatField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetLastStatusChange := TDateTimeField.Create(Self);
  MainDatasetSubmittedBy := TIntegerField.Create(Self);
  MainDatasetHMRCOfficial := TIntegerField.Create(Self);
  MainDatasetComments := TStringField.Create(Self);
  MainDatasetOtherAmounts := TFloatField.Create(Self);
  MainDatasetIRMark64 := TStringField.Create(Self);
  MainDatasetNotificationId := TIntegerField.Create(Self);
  MainDatasetPaymentReceivedDate := TDateField.Create(Self);

  DataTable.BeforeLoadDFMValues;
  SearchButton.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetTransactionId.BeforeLoadDFMValues;
  MainDatasetClaimRef.BeforeLoadDFMValues;
  MainDatasetCorrelationId.BeforeLoadDFMValues;
  MainDatasetIRMark.BeforeLoadDFMValues;
  MainDatasetIRMarkReceipt.BeforeLoadDFMValues;
  MainDatasetFirstDonationDate.BeforeLoadDFMValues;
  MainDatasetLastDonationDate.BeforeLoadDFMValues;
  MainDatasetSubmissionDate.BeforeLoadDFMValues;
  MainDatasetEarliestSubmissionDate.BeforeLoadDFMValues;
  MainDatasetDonationCount.BeforeLoadDFMValues;
  MainDatasetGADAmount.BeforeLoadDFMValues;
  MainDatasetOtherIncome.BeforeLoadDFMValues;
  MainDatasetAdjustment.BeforeLoadDFMValues;
  MainDatasetGASDAmount.BeforeLoadDFMValues;
  MainDatasetGASDSAdjustment.BeforeLoadDFMValues;
  MainDatasetGiftAidClaim.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetLastStatusChange.BeforeLoadDFMValues;
  MainDatasetSubmittedBy.BeforeLoadDFMValues;
  MainDatasetHMRCOfficial.BeforeLoadDFMValues;
  MainDatasetComments.BeforeLoadDFMValues;
  MainDatasetOtherAmounts.BeforeLoadDFMValues;
  MainDatasetIRMark64.BeforeLoadDFMValues;
  MainDatasetNotificationId.BeforeLoadDFMValues;
  MainDatasetPaymentReceivedDate.BeforeLoadDFMValues;
  try
    DataTable.Top := 110;
    DataTable.Options.ScrollVertical := True;
    DataTable.Options.ResizeColumns := True;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
      TitleElementClassName := 'sorting_desc';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimRef';
      Title := 'Claim Ref';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'SubmissionDate';
      Title := 'Submission Date';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimStatus';
      Title := 'Status';
    end;
    with DataTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'GADAmount';
      Title := 'GAD Amount';
    end;
    with DataTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'OtherAmounts';
      Title := 'Other';
    end;
    with DataTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'GiftAidClaim';
      Title := 'GiftAid Claim';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    SearchButton.Visible := False;
    SetEvent(MainDataset, Self, 'OnCalcFields', 'MainDatasetCalcFields');
    MainDataset.EntitySetName := 'GiftAidClaim';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetTransactionId.SetParentComponent(MainDataset);
    MainDatasetTransactionId.Name := 'MainDatasetTransactionId';
    MainDatasetTransactionId.FieldName := 'TransactionId';
    MainDatasetTransactionId.Size := 32;
    MainDatasetClaimRef.SetParentComponent(MainDataset);
    MainDatasetClaimRef.Name := 'MainDatasetClaimRef';
    MainDatasetClaimRef.FieldName := 'ClaimRef';
    MainDatasetClaimRef.Size := 32;
    MainDatasetCorrelationId.SetParentComponent(MainDataset);
    MainDatasetCorrelationId.Name := 'MainDatasetCorrelationId';
    MainDatasetCorrelationId.FieldName := 'CorrelationId';
    MainDatasetCorrelationId.Size := 32;
    MainDatasetIRMark.SetParentComponent(MainDataset);
    MainDatasetIRMark.Name := 'MainDatasetIRMark';
    MainDatasetIRMark.FieldName := 'IRMark';
    MainDatasetIRMark.Size := 32;
    MainDatasetIRMarkReceipt.SetParentComponent(MainDataset);
    MainDatasetIRMarkReceipt.Name := 'MainDatasetIRMarkReceipt';
    MainDatasetIRMarkReceipt.FieldName := 'IRMarkReceipt';
    MainDatasetFirstDonationDate.SetParentComponent(MainDataset);
    MainDatasetFirstDonationDate.Name := 'MainDatasetFirstDonationDate';
    MainDatasetFirstDonationDate.FieldName := 'FirstDonationDate';
    MainDatasetLastDonationDate.SetParentComponent(MainDataset);
    MainDatasetLastDonationDate.Name := 'MainDatasetLastDonationDate';
    MainDatasetLastDonationDate.FieldName := 'LastDonationDate';
    MainDatasetSubmissionDate.SetParentComponent(MainDataset);
    MainDatasetSubmissionDate.Name := 'MainDatasetSubmissionDate';
    MainDatasetSubmissionDate.FieldName := 'SubmissionDate';
    SetEvent(MainDatasetSubmissionDate, Self, 'OnGetText', 'MainDatasetSubmissionDateGetText');
    MainDatasetSubmissionDate.DisplayFormat := 'dd/mm/yyyy';
    MainDatasetEarliestSubmissionDate.SetParentComponent(MainDataset);
    MainDatasetEarliestSubmissionDate.Name := 'MainDatasetEarliestSubmissionDate';
    MainDatasetEarliestSubmissionDate.FieldName := 'EarliestSubmissionDate';
    MainDatasetDonationCount.SetParentComponent(MainDataset);
    MainDatasetDonationCount.Name := 'MainDatasetDonationCount';
    MainDatasetDonationCount.FieldName := 'DonationCount';
    MainDatasetDonationCount.Required := True;
    MainDatasetDonationCount.DisplayFormat := '#,##0';
    MainDatasetGADAmount.SetParentComponent(MainDataset);
    MainDatasetGADAmount.Name := 'MainDatasetGADAmount';
    MainDatasetGADAmount.FieldName := 'GADAmount';
    MainDatasetGADAmount.Required := True;
    SetEvent(MainDatasetGADAmount, Self, 'OnGetText', 'MainDatasetGADAmountGetText');
    MainDatasetGADAmount.DisplayFormat := '#,##0.00';
    MainDatasetOtherIncome.SetParentComponent(MainDataset);
    MainDatasetOtherIncome.Name := 'MainDatasetOtherIncome';
    MainDatasetOtherIncome.FieldName := 'OtherIncome';
    MainDatasetOtherIncome.Required := True;
    MainDatasetOtherIncome.DisplayFormat := '#,##0.00';
    MainDatasetAdjustment.SetParentComponent(MainDataset);
    MainDatasetAdjustment.Name := 'MainDatasetAdjustment';
    MainDatasetAdjustment.FieldName := 'Adjustment';
    MainDatasetAdjustment.Required := True;
    MainDatasetAdjustment.DisplayFormat := '#,##0.00';
    MainDatasetGASDAmount.SetParentComponent(MainDataset);
    MainDatasetGASDAmount.Name := 'MainDatasetGASDAmount';
    MainDatasetGASDAmount.FieldName := 'GASDAmount';
    MainDatasetGASDAmount.Required := True;
    MainDatasetGASDAmount.DisplayFormat := '#,##0.00';
    MainDatasetGASDSAdjustment.SetParentComponent(MainDataset);
    MainDatasetGASDSAdjustment.Name := 'MainDatasetGASDSAdjustment';
    MainDatasetGASDSAdjustment.FieldName := 'GASDSAdjustment';
    MainDatasetGASDSAdjustment.Required := True;
    MainDatasetGASDSAdjustment.DisplayFormat := '#,##0.00';
    MainDatasetGiftAidClaim.SetParentComponent(MainDataset);
    MainDatasetGiftAidClaim.Name := 'MainDatasetGiftAidClaim';
    MainDatasetGiftAidClaim.FieldName := 'GiftAidClaim';
    MainDatasetGiftAidClaim.Required := True;
    SetEvent(MainDatasetGiftAidClaim, Self, 'OnGetText', 'MainDatasetGiftAidClaimGetText');
    MainDatasetGiftAidClaim.DisplayFormat := '#,##0.00';
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    SetEvent(MainDatasetClaimStatus, Self, 'OnGetText', 'MainDatasetClaimStatusGetText');
    MainDatasetClaimStatus.Size := 15;
    MainDatasetLastStatusChange.SetParentComponent(MainDataset);
    MainDatasetLastStatusChange.Name := 'MainDatasetLastStatusChange';
    MainDatasetLastStatusChange.FieldName := 'LastStatusChange';
    MainDatasetSubmittedBy.SetParentComponent(MainDataset);
    MainDatasetSubmittedBy.Name := 'MainDatasetSubmittedBy';
    MainDatasetSubmittedBy.FieldName := 'SubmittedBy';
    MainDatasetHMRCOfficial.SetParentComponent(MainDataset);
    MainDatasetHMRCOfficial.Name := 'MainDatasetHMRCOfficial';
    MainDatasetHMRCOfficial.FieldName := 'HMRCOfficial';
    MainDatasetComments.SetParentComponent(MainDataset);
    MainDatasetComments.Name := 'MainDatasetComments';
    MainDatasetComments.FieldName := 'Comments';
    MainDatasetComments.Size := 4096;
    MainDatasetOtherAmounts.SetParentComponent(MainDataset);
    MainDatasetOtherAmounts.Name := 'MainDatasetOtherAmounts';
    MainDatasetOtherAmounts.FieldKind := fkCalculated;
    MainDatasetOtherAmounts.FieldName := 'OtherAmounts';
    SetEvent(MainDatasetOtherAmounts, Self, 'OnGetText', 'MainDatasetOtherAmountsGetText');
    MainDatasetOtherAmounts.DisplayFormat := '#,##0.00';
    MainDatasetOtherAmounts.Calculated := True;
    MainDatasetIRMark64.SetParentComponent(MainDataset);
    MainDatasetIRMark64.Name := 'MainDatasetIRMark64';
    MainDatasetIRMark64.FieldName := 'IRMark64';
    MainDatasetIRMark64.Size := 28;
    MainDatasetNotificationId.SetParentComponent(MainDataset);
    MainDatasetNotificationId.Name := 'MainDatasetNotificationId';
    MainDatasetNotificationId.FieldName := 'NotificationId';
    MainDatasetPaymentReceivedDate.SetParentComponent(MainDataset);
    MainDatasetPaymentReceivedDate.Name := 'MainDatasetPaymentReceivedDate';
    MainDatasetPaymentReceivedDate.FieldName := 'PaymentReceivedDate';
  finally
    DataTable.AfterLoadDFMValues;
    SearchButton.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetTransactionId.AfterLoadDFMValues;
    MainDatasetClaimRef.AfterLoadDFMValues;
    MainDatasetCorrelationId.AfterLoadDFMValues;
    MainDatasetIRMark.AfterLoadDFMValues;
    MainDatasetIRMarkReceipt.AfterLoadDFMValues;
    MainDatasetFirstDonationDate.AfterLoadDFMValues;
    MainDatasetLastDonationDate.AfterLoadDFMValues;
    MainDatasetSubmissionDate.AfterLoadDFMValues;
    MainDatasetEarliestSubmissionDate.AfterLoadDFMValues;
    MainDatasetDonationCount.AfterLoadDFMValues;
    MainDatasetGADAmount.AfterLoadDFMValues;
    MainDatasetOtherIncome.AfterLoadDFMValues;
    MainDatasetAdjustment.AfterLoadDFMValues;
    MainDatasetGASDAmount.AfterLoadDFMValues;
    MainDatasetGASDSAdjustment.AfterLoadDFMValues;
    MainDatasetGiftAidClaim.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetLastStatusChange.AfterLoadDFMValues;
    MainDatasetSubmittedBy.AfterLoadDFMValues;
    MainDatasetHMRCOfficial.AfterLoadDFMValues;
    MainDatasetComments.AfterLoadDFMValues;
    MainDatasetOtherAmounts.AfterLoadDFMValues;
    MainDatasetIRMark64.AfterLoadDFMValues;
    MainDatasetNotificationId.AfterLoadDFMValues;
    MainDatasetPaymentReceivedDate.AfterLoadDFMValues;
  end;
end;

end.
