unit SupportForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseCoreForm,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls;

type
  TSupportPage = class(TCoreWebForm)
    SupportFrom: TLabel;
    SupportMessage: TLabel;
    SupportSubject: TEdit;
    SupportContent: TMemo;
    SupportSendButton: TButton;
    SupportCancelButton: TButton;
    procedure WebFormCreate(Sender: TObject);
    procedure SupportCancelButtonClick(Sender: TObject);
    procedure SupportSendButtonClick(Sender: TObject);
  private
    FErrorData: TApplicationError;
    procedure SetErrorData(const Value: TApplicationError);
    [async]
    procedure ContactSupport; async;
    { Private declarations }
    function ErrorDataExists: Boolean;
  public
    { Public declarations }
    property ErrorData: TApplicationError write SetErrorData;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  App.Utils,
  Auth.Service, MainDataModule;

procedure TSupportPage.WebFormCreate(Sender: TObject);
begin
  inherited;
  SupportFrom.Caption := AuthService.UserFullName;
end;

{ TTSupportPage }

procedure TSupportPage.ContactSupport;
var lObj: JS.TJSObject;
    lErr: String;
begin
  if ErrorDataExists then
  begin
    lObj := JS.TJSObject.new;
    lObj.Properties['File'] := FErrorData.AFile;
    lObj.Properties['Message'] := FErrorData.AMessage;
    lObj.Properties['LineNumber'] := FErrorData.ALineNumber;
    lObj.Properties['ColNumber'] := FErrorData.AColNumber;
    lObj.Properties['Stack'] := FErrorData.AStack;
    lErr := JS.TJSJSON.stringify(lObj);
  end
  else
    lErr := '';

  //ASubject, AFrom, AMessage, AError
  MainData.WebClient.RawInvoke(ISMXSUPPORTSVC_CONTACT_SUPPORT, [SupportSubject.Text, SupportFrom.Caption, SupportContent.Lines.Text, lErr]);
  ModalResult := mrOK;
end;

function TSupportPage.ErrorDataExists: Boolean;
begin
  Result := Assigned(FErrorData) and (FErrorData.AFile <> '');
end;

procedure TSupportPage.SetErrorData(const Value: TApplicationError);
begin
  FErrorData := Value;
  SupportMessage.Caption :=
    'There has been an error in the application. It would be of great help if you would tell us what you were doing at the time. Many thanks.';
  SupportSubject.Text := 'System Error';
end;

procedure TSupportPage.SupportCancelButtonClick(Sender: TObject);
begin
  if ErrorDataExists then
     MainData.WebClient.RawInvoke(ISMXSUPPORTSVC_REPORT_ERROR, [FErrorData.AMessage, FErrorData.AFile, FErrorData.ALineNumber,
        FErrorData.AColNumber, FErrorData.AStack, JS.TJSJSON.stringify(FErrorData.AError)]);

  ModalResult := mrCancel;
end;

procedure TSupportPage.SupportSendButtonClick(Sender: TObject);
begin
  ContactSupport;
end;

procedure TSupportPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SupportFrom := TLabel.Create('SupportFrom');
  SupportMessage := TLabel.Create('SupportMessage');
  SupportSubject := TEdit.Create('SupportSubject');
  SupportContent := TMemo.Create('SupportContent');
  SupportSendButton := TButton.Create('SupportSendButton');
  SupportCancelButton := TButton.Create('SupportCancelButton');

  SupportFrom.BeforeLoadDFMValues;
  SupportMessage.BeforeLoadDFMValues;
  CloseThisButton.BeforeLoadDFMValues;
  SupportSubject.BeforeLoadDFMValues;
  SupportContent.BeforeLoadDFMValues;
  SupportSendButton.BeforeLoadDFMValues;
  SupportCancelButton.BeforeLoadDFMValues;
  try
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SupportFrom.SetParentComponent(Self);
    SupportFrom.Name := 'SupportFrom';
    SupportFrom.Left := 48;
    SupportFrom.Top := 56;
    SupportFrom.Width := 70;
    SupportFrom.Height := 15;
    SupportFrom.Caption := 'SupportFrom';
    SupportFrom.ElementFont := efCSS;
    SupportFrom.HeightStyle := ssAuto;
    SupportFrom.HeightPercent := 100.000000000000000000;
    SupportFrom.HTMLType := tSPAN;
    SupportFrom.WidthPercent := 100.000000000000000000;
    SupportMessage.SetParentComponent(Self);
    SupportMessage.Name := 'SupportMessage';
    SupportMessage.Left := 48;
    SupportMessage.Top := 96;
    SupportMessage.Width := 223;
    SupportMessage.Height := 15;
    SupportMessage.Caption := 'Please tell us what you'#39'd like support with.';
    SupportMessage.ElementFont := efCSS;
    SupportMessage.HeightStyle := ssAuto;
    SupportMessage.HeightPercent := 100.000000000000000000;
    SupportMessage.HTMLType := tSPAN;
    SupportMessage.WidthStyle := ssAuto;
    SupportMessage.WidthPercent := 100.000000000000000000;
    CloseThisButton.Visible := False;
    SupportSubject.SetParentComponent(Self);
    SupportSubject.Name := 'SupportSubject';
    SupportSubject.Left := 48;
    SupportSubject.Top := 160;
    SupportSubject.Width := 233;
    SupportSubject.Height := 22;
    SupportSubject.ChildOrder := 3;
    SupportSubject.ElementClassName := 'form-control';
    SupportSubject.ElementFont := efCSS;
    SupportSubject.ElementPosition := epIgnore;
    SupportSubject.HeightStyle := ssAuto;
    SupportSubject.HeightPercent := 100.000000000000000000;
    SupportSubject.WidthStyle := ssAuto;
    SupportSubject.WidthPercent := 100.000000000000000000;
    SupportContent.SetParentComponent(Self);
    SupportContent.Name := 'SupportContent';
    SupportContent.Left := 48;
    SupportContent.Top := 208;
    SupportContent.Width := 449;
    SupportContent.Height := 185;
    SupportContent.AutoSize := False;
    SupportContent.ElementClassName := 'form-control';
    SupportContent.ElementFont := efCSS;
    SupportContent.ElementPosition := epIgnore;
    SupportContent.HeightStyle := ssAuto;
    SupportContent.HeightPercent := 100.000000000000000000;
    SupportContent.SelLength := 0;
    SupportContent.SelStart := 0;
    SupportContent.WidthStyle := ssAuto;
    SupportContent.WidthPercent := 100.000000000000000000;
    SupportSendButton.SetParentComponent(Self);
    SupportSendButton.Name := 'SupportSendButton';
    SupportSendButton.Left := 512;
    SupportSendButton.Top := 432;
    SupportSendButton.Width := 96;
    SupportSendButton.Height := 25;
    SupportSendButton.Caption := 'Send';
    SupportSendButton.ChildOrder := 5;
    SupportSendButton.ElementClassName := 'btn btn-primary';
    SupportSendButton.ElementFont := efCSS;
    SupportSendButton.ElementPosition := epIgnore;
    SupportSendButton.HeightStyle := ssAuto;
    SupportSendButton.HeightPercent := 100.000000000000000000;
    SupportSendButton.WidthStyle := ssAuto;
    SupportSendButton.WidthPercent := 100.000000000000000000;
    SetEvent(SupportSendButton, Self, 'OnClick', 'SupportSendButtonClick');
    SupportCancelButton.SetParentComponent(Self);
    SupportCancelButton.Name := 'SupportCancelButton';
    SupportCancelButton.Left := 392;
    SupportCancelButton.Top := 432;
    SupportCancelButton.Width := 96;
    SupportCancelButton.Height := 25;
    SupportCancelButton.Caption := 'Cancel';
    SupportCancelButton.ChildOrder := 6;
    SupportCancelButton.ElementClassName := 'btn btn-primary';
    SupportCancelButton.ElementFont := efCSS;
    SupportCancelButton.ElementPosition := epIgnore;
    SupportCancelButton.HeightStyle := ssAuto;
    SupportCancelButton.HeightPercent := 100.000000000000000000;
    SupportCancelButton.WidthStyle := ssAuto;
    SupportCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(SupportCancelButton, Self, 'OnClick', 'SupportCancelButtonClick');
  finally
    SupportFrom.AfterLoadDFMValues;
    SupportMessage.AfterLoadDFMValues;
    CloseThisButton.AfterLoadDFMValues;
    SupportSubject.AfterLoadDFMValues;
    SupportContent.AfterLoadDFMValues;
    SupportSendButton.AfterLoadDFMValues;
    SupportCancelButton.AfterLoadDFMValues;
  end;
end;

end.
