unit App.Helper;

interface

uses
  System.Classes,
  System.SysUtils;

type

  THTMLHelper = class
  public

    class function stringsToUL(AStrings: TStrings; const AListClass: string = ''; AItemClass: string = ''): string;

    class procedure addClass(const aElementId: string; const AClassName: string);
    class procedure removeClass(const aElementId: string; const AClassName: string);
    class procedure hideElement(const aElementId: string);
    class function elementHeight(const aElementId: string): integer;
    class function elementWidth(const aElementId: string): integer;

    class procedure enterAsTab(const aElementId: string);

  end;

implementation

{ THTMLHelper }

class procedure THTMLHelper.addClass(const aElementId, AClassName: string);
begin
  asm
    $("#" + aElementId).addClass(AClassName);
  end;
end;

class function THTMLHelper.elementHeight(const aElementId: string): integer;
begin
  asm
    Result = parseInt($("#" + aElementId).height());
  end;
end;

class function THTMLHelper.elementWidth(const aElementId: string): integer;
begin
  asm
    Result = parseInt($("#" + aElementId).width());
  end;
end;

class procedure THTMLHelper.enterAsTab(const aElementId: string);
begin
  asm
    var n = $("input:text").length;
    var nextIndex = $('#' + aElementId).index() + 1;
    if(nextIndex < n) {
    $('input:text')[nextIndex].focus(); };
  end;
end;

class procedure THTMLHelper.hideElement(const aElementId: string);
begin
  asm
    $("#" + aElementId).hide();
  end;
end;

class procedure THTMLHelper.removeClass(const aElementId, AClassName: string);
begin
  asm
    $("#" + aElementId).removeClass(AClassName);
  end;
end;

class function THTMLHelper.stringsToUL(AStrings: TStrings; const AListClass: string = '';
  AItemClass: string = ''): string;
var
  I: integer;
  lClass: string;
begin
  result := '';

  if AItemClass <> '' then
    lClass := format(' class="%s">', [AItemClass])
  else
    lClass := '>';

  for I := 0 to AStrings.Count - 1 do
    result := result + '<li' + lClass + AStrings[I] + '</li>';

  if AListClass <> '' then
    lClass := format(' class="%s">', [AListClass])
  else
    lClass := '>';

  result := '<ul' + lClass + result + '</ul>';
end;

end.
