{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2022                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}
unit WEBLib.DropDown;

interface

// overflow hidden of parent elements????
// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_js_dropdown_hover

uses
  Classes, WEBLib.Controls, WEBLib.Forms, Web, JS, WEBLib.Menus, WEBLib.Graphics;

type
  TCustomDropDownControl = class(TWebCustomControl)
  private
    FDoClickLayer: pointer;
    FLayer: TJSHTMLElement;
    FControl: TWebCustomControl;
    FDropDown: TJSHTMLElement;
    FTextElement: TJSHTMLElement;
    FButton: TJSHTMLElement;
    FOnCloseUp: TNotifyEvent;
    FOnDropDown: TNotifyEvent;
    FText: string;
    FAutoDropDown: boolean;
    FElementInputClassName: TElementClassName;
    FButtonImageURL: string;
    FDropDownHeight: integer;
    FDropDownWidth: integer;
    FDropDownColor: TColor;
    FDropDownShadow: boolean;
    procedure SetText(const Value: string);
    function GetText: string;
    function GetDroppedDown: boolean;
    procedure SetButtonImageURL(const Value: string);
  protected
    procedure UpdateElementVisual; override;
    procedure CreateInitialize; override;
    function HandleDoClickLayer(Event: TJSMouseEvent): Boolean; virtual;
    procedure Click; override;
    function HasEdit: boolean; virtual;
    procedure CreateChildElements(AContainer: TJSElement); override;
    function CreateElement: TJSElement; override;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
    procedure DoDropDown; virtual;
    procedure DoCloseUp; virtual;
  public
    procedure ShowDropDown; virtual;
    procedure HideDropDown; virtual;
    procedure ToggleDropDown; virtual;
    property DroppedDown: boolean read GetDroppedDown;
    property Text: string read GetText write SetText;
    property BorderStyle;
    property Control: TWebCustomControl read FControl write FControl;
    property ElementInputClassName: TElementClassName read FElementInputClassName write FElementInputClassName;
    property ButtonImageURL: string read FButtonImageURL write SetButtonImageURL;
  published
    property AutoDropDown: boolean read FAutoDropDown write FAutoDropDown default false;
    property DropDownColor: TColor read FDropDownColor write FDropDownColor default clWhite;
    property DropDownWidth: integer read FDropDownWidth write FDropDownWidth default 0;
    property DropDownHeight: integer read FDropDownHeight write FDropDownHeight default 0;
    property DropDownShadow: boolean read FDropDownShadow write FDropDownShadow default true;
    property OnDropDown: TNotifyEvent read FOnDropDown write FOnDropDown;
    property OnCloseUp: TNotifyEvent read FOnCloseUp write FOnCloseUp;
  end;

  TDropDownControl = class(TCustomDropDownControl)
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BiDiMode;
    property BorderStyle;
    property ButtonImageURL;
    property ChildOrder;
    property Color;
    property Control;
    property DragMode;
    property Font;
    property ElementClassName;
    property ElementInputClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Left;
    property ParentColor;
    property ParentFont;
    property PopupMenu;
    property Role;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnCloseUp;
    property OnDblClick;
    property OnDropDown;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnMouseWheel;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebDropDownControl = class(TDropDownControl);

  TEditDropDownControl = class(TCustomDropDownControl)
  private
    function GetEditElement: TJSHTMLElement;
  protected
    function GetElementBindHandle: TJSEventTarget; override;
    function HasEdit: boolean; override;
  public
    property EditElement: TJSHTMLElement read GetEditElement;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BiDiMode;
    property BorderStyle;
    property ButtonImageURL;
    property ChildOrder;
    property Color;
    property Control;
    property DragMode;
    property Font;
    property ElementClassName;
    property ElementInputClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Left;
    property ParentColor;
    property ParentFont;
    property PopupMenu;
    property Role;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnCloseUp;
    property OnDblClick;
    property OnDropDown;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnMouseWheel;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebEditDropDownControl = class(TEditDropDownControl);

implementation

uses
  SysUtils, Math;

{ TWebDropDownControl }

procedure TCustomDropDownControl.ClearMethodPointers;
begin
  inherited;
  FDoClickLayer := nil;
end;

procedure TCustomDropDownControl.Click;
begin
  inherited;

  if (ElementEvent.target = FButton) or (TJSElement(ElementEvent.target).parentElement = FButton) then
  begin
    ToggleDropDown;
  end
  else
    if AutoDropDown then
      ToggleDropDown;
end;

procedure TCustomDropDownControl.CreateChildElements(AContainer: TJSElement);
var
  r: TJSDOMRect;
begin
  if HasEdit then
  begin
    FTextElement := TJSHTMLElement(document.createElement('INPUT'));
    FTextElement['type'] := 'TEXT';
    if ElementFont = efProperty then
      SetHTMLElementFont(FTextElement, Font, ElementFont = efCSS);
  end
  else
  begin
    FTextElement := TJSHTMLElement(document.createElement('DIV'));
    FTextElement['type'] := 'TEXT';
    FTextElement.style.setProperty('padding-left','2px');
    FTextElement.style.setProperty('padding-right','2px');
    FTextElement.style.setProperty('text-overflow','ellipsis');
    if ElementFont = efProperty then
      SetHTMLElementFont(FTextElement, Font, ElementFont = efCSS);
  end;

  FButton := TJSHTMLElement(document.createElement('DIV'));

//  TJSHTMLElement(FTextElement).style.setProperty('background-color','white');
  TJSHTMLElement(FTextElement).style.setProperty('display','inline-block');
  TJSHTMLElement(FTextElement).style.setProperty('width','calc(100% - 25px)');

  r := AContainer.getBoundingClientRect;
  if r.height = 0 then
    r.height := 26;

  TJSHTMLElement(FTextElement).style.setProperty('height',r.height.ToString+'px');
  TJSHTMLElement(FTextElement).style.setProperty('box-sizing','border-box');
  TJSHTMLElement(FTextElement).style.setProperty('line-height', r.height.ToString + 'px');
  TJSHTMLElement(FTextElement).style.setProperty('vertical-align','middle');

  TJSHTMLElement(FButton).style.setProperty('background-color','silver');
  TJSHTMLElement(FButton).style.setProperty('display','inline-block');
  TJSHTMLElement(FButton).style.setProperty('width','25px');
  TJSHTMLElement(FButton).style.setProperty('height', r.height.ToString +'px');
  TJSHTMLElement(FButton).style.setProperty('line-height', r.height.ToString + 'px');
  TJSHTMLElement(FButton).style.setProperty('text-align','center');
  TJSHTMLElement(FButton).style.setProperty('vertical-align','middle');

  FDropDown := TJSHTMLElement(document.createElement('DIV'));
  TJSHTMLElement(FDropDown).style.setProperty('position','absolute');
  TJSHTMLElement(FDropDown).style.setProperty('display','none');
  TJSHTMLElement(FDropDown).style.setProperty('background-color','#f1f1f1');
//  TJSHTMLElement(FDropDown).style.setProperty('min-width','160px');
  TJSHTMLElement(FDropDown).style.setProperty('box-shadow','0px 8px 8px 0px rgba(0,0,0,0.2)');
  TJSHTMLElement(FDropDown).style.setProperty('z-index','1');
//  TJSHTMLElement(FDropDown).style.setProperty('min-height','200px');
  //FDropDOwn.innerHTML := 'hello world<br>more content<br>is here<br>in the dropdown';

  FTextElement.innerHTML := '&nbsp;';
//  FButton.innerHTML := '&#x25BC;';

  AContainer.appendChild(FTextElement);
  AContainer.appendChild(FButton);

  FLayer := TJSHTMLElement(document.createElement('SPAN'));
  FLayer.style.setProperty('top', '0');
  FLayer.style.setProperty('left', '0');
  FLayer.style.setProperty('right', '0');
  FLayer.style.setProperty('bottom', '0');

  FLayer.style.setProperty('webkit-user-select', 'none');
  FLayer.style.setProperty('moz-user-select', 'none');
  FLayer.style.setProperty('khtml-user-select', 'none');
  FLayer.style.setProperty('ms-user-select', 'none');
  FLayer.style.setProperty('user-select', 'none');
  FLayer.style.setProperty('position', 'absolute');

  AddInstanceStyle('.arrow { border: solid black; border-width: 0 2px 2px 0;  display: inline-block;  padding: 2px;}'+#13#10+
     '.down { transform: rotate(45deg); -webkit-transform: rotate(45deg);};');

  FButton.innerHTML := '<i class="arrow down"></i>';
end;

function TCustomDropDownControl.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

procedure TCustomDropDownControl.CreateInitialize;
begin
  inherited;
  FAutoDropDown := false;
  FDropDownColor := clWhite;
  FDropDownWidth := 0;
  FDropDownHeight := 0;
  FDropDownShadow := true;
end;

procedure TCustomDropDownControl.DoCloseUp;
begin
  if Assigned(OnCloseUp) then
    OnCloseUp(Self);
end;

procedure TCustomDropDownControl.DoDropDown;
begin
  if Assigned(OnDropDown) then
    OnDropDown(Self);
end;

function TCustomDropDownControl.GetDroppedDown: boolean;
begin
  Result := FDropDown.style.getPropertyValue('display') <> 'none';
end;

procedure TCustomDropDownControl.GetMethodPointers;
begin
  inherited;
  if FDoClickLayer = nil then
  begin
    FDoClickLayer := @HandleDoClickLayer;
  end;
end;

function TCustomDropDownControl.GetText: string;
begin
  Result := FText;

  if HasEdit then
  begin
    asm
      Result = this.FTextElement.value;
    end;
  end;
end;

procedure TCustomDropDownControl.SetButtonImageURL(const Value: string);
begin
  if (FButtonImageURL <> Value) then
  begin
    FButtonImageURL := Value;
    UpdateELement;
  end;
end;

procedure TCustomDropDownControl.SetText(const Value: string);
begin
  FText := Value;

  if HasEdit then
  begin
    asm
      this.FTextElement.value = Value;
    end;
  end
  else
  begin
    if Value <> '' then
      FTextElement.innerHTML := Value
    else
      FTextElement.innerHTML := '&nbsp;';
  end;
end;

procedure TCustomDropDownControl.ShowDropDown;
var
  dr: TJSDOMRect;
  w,h: single;
begin
  if not Assigned(ElementHandle) then
    Exit;

  FLayer.style.setProperty('z-index', Application.MaxZIndexStr);
  document.body.appendChild(FLayer);

  FLayer.addEventListener('click',FDoClickLayer);

  FLayer.appendChild(FDropDown);

  FDropDown.style.setProperty('display','block');

  if Assigned(Control) then
  begin
    Control.Left := 0;
    Control.Top := 0;
    Control.ElementPosition := epAbsolute;
    Control.ElementFont := ElementFont;
    Control.Visible := true;

    dr := ElementHandle.getBoundingClientRect;

    FDropDown.style.setProperty('left', dr.Left.ToString + 'px');
    FDropDown.style.setProperty('top', dr.Bottom.ToString + 'px');

    dr := Control.ElementHandle.getBoundingClientRect;

    if DropDownWidth > 0 then
      w := DropDownWidth
    else
      w := Max(dr.Width, Control.Width);

    FDropDown.style.setProperty('width', w.ToString + 'px');

    if DropDownHeight > 0 then
      h := DropDownHeight
    else
      h := Max(dr.Height, Control.Height);

    FDropDown.style.setProperty('height', h.ToString + 'px');

    if DropDownColor <> clNone then
      FDropDown.style.setProperty('background-color', ColorToHTML(FDropDownColor));

    FDropDown.appendChild(Control.ElementHandle);
  end;

  if DropDownShadow then
    TJSHTMLElement(FDropDown).style.setProperty('box-shadow','0px 8px 8px 0px rgba(0,0,0,0.2)');

  DoDropDown;
end;

procedure TCustomDropDownControl.ToggleDropDown;
begin
  if FDropDown.style.getPropertyValue('display') = 'none' then
    ShowDropDown
  else
    HideDropDown;
end;

procedure TCustomDropDownControl.UpdateElementVisual;
var
  h: integer;
begin
  inherited;

  if not HasEdit and (BorderStyle = bsSingle) then
    ElementHandle.style.setProperty('border','1px solid silver');

  ElementHandle.style.removeProperty('overflow');

  h := Height - 1;

 // ElementHandle.style.setProperty('padding','2px');
  SetHTMLElementFont(FTextElement, Font, ElementFont = efCSS);

  TJSHTMLElement(FButton).style.setProperty('height', h.ToString +'px');
  TJSHTMLElement(FTextElement).style.setProperty('height', h.ToString +'px');
  TJSHTMLElement(FButton).style.setProperty('line-height', h.ToString +'px');
  TJSHTMLElement(FTextElement).style.setProperty('line-height', h.ToString +'px');

  if ButtonImageURL <> '' then
    FButton.innerHTML := '<img src="'+ ButtonImageURL+'">'
  else
    FButton.innerHTML := '<i class="arrow down"></i>';


  if FElementInputClassName <> '' then
    FTextElement.classList.add(FElementInputClassName)
  else
    if FTextElement.classList.contains(FElementInputClassName) then
      FTextElement.classList.remove(FElementInputClassName);
end;

function TCustomDropDownControl.HandleDoClickLayer(Event: TJSMouseEvent): Boolean;
begin
  HideDropDown;
  Result := true;
end;

function TCustomDropDownControl.HasEdit: boolean;
begin
  Result := false;
end;

procedure TCustomDropDownControl.HideDropDown;
begin
  DoCloseUp;

  FLayer.removeEventListener('click',FDoClickLayer);
  FLayer.parentElement.removeChild(FLayer);
  FDropDown.style.setProperty('display','none');
  FDropDown.parentElement.removeChild(FDropDown);
end;

{ TEditDropDownControl }

function TEditDropDownControl.GetEditElement: TJSHTMLElement;
begin
  Result := TJSHTMLElement(FTextElement);
end;

function TEditDropDownControl.GetElementBindHandle: TJSEventTarget;
begin
  Result := TJSHTMLElement(FTextElement);
end;

function TEditDropDownControl.HasEdit: boolean;
begin
  Result := true;
end;

end.
