unit BaseForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.StdCtrls,
  WEBLib.ExtCtrls,
  Data.DB,
  WEBLib.DB,
  XData.Web.Client,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.Actions,
  WEBLib.DBCtrls,
  BaseCoreForm,
  App.Utils,
  WEBLib.WebCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.Imaging.GIFImg,
  WEBLib.Toast;

type
  TBaseEditForm = class(TCoreWebForm)
    CancelButton: TButton;
    FormCaption: TLabel;
    SaveButton: TButton;
    DataSource: TDataSource;
    MessageLabel: TLabel;
    EditElements: TElementActionList;
    DataState: THTMLSpan;
    PriorButton: TButton;
    NextButton: TButton;
    WaitMessage: TWaitMessage;
    CloseTimer: TTimer;
    AuditInfo: THTMLDiv;
    UpdatedByLabel: TLabel;
    AddedByLabel: TLabel;
    AddedByDate: TLabel;
    UpdatedByDate: TLabel;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
    procedure CancelButtonClick(Sender: TObject);
    procedure DataSourceDataChange(Sender: TObject; Field: TField);
    procedure DataSourceStateChange(Sender: TObject);
    procedure EditElementsActions0Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure EditElementsActions1Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure NextButtonClick(Sender: TObject);
    procedure PriorButtonClick(Sender: TObject);
    procedure SaveButtonClick(Sender: TObject);
    procedure CheckEnterKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure CloseTimerTimer(Sender: TObject);
  private
    FErrorList: TStrings;
    FRecordViewOption: TViewOption;
    FTargetId: string;
    function GetErrorList: TStrings;
    procedure NavButtonsStatus;
    procedure SetDataset(const Value: TDataset);
    function ValidateForm: boolean;
    procedure SetRecordViewOption(const Value: TViewOption);
    [async]
    procedure GetAuditInfo; async;
    function GetWebClient: TXDataWebClient;
    function GetDataset: TDataset;
  protected
    FWebClient: TXDataWebClient;
    FLoading: boolean;
    FLastActionState: TDatasetState;
    FLastProcessedId: string;
    FIdField: string;
    FSaveMessagesHandled: boolean;
    FPopUpShowing: boolean;

    function ActionText: string;
    // function CanClose: boolean; override; //not to be used in popups
    procedure TrapEnterPressSetUp;
    function CanSaveThis: boolean; virtual;
    function Validate(const AControlName: string): boolean; virtual;
    procedure AfterSetDataset; virtual;
    procedure RecordChanged; virtual;
    procedure BeforePostRecord; virtual;
    procedure AfterApplyUpdates(Sender: TDataset; Info: TResolveResults); virtual;
    function IsErrorList: boolean;

    function SaveTitle: string; virtual;
    function SaveDescription: string; virtual;
    function GetRequiredEditLevel: string; virtual;
    function CloseOnSave: boolean; virtual;

    property ErrorList: TStrings read GetErrorList;
    property WebClient: TXDataWebClient read GetWebClient;

  public
    { Public declarations }
    destructor Destroy; override;
    procedure SetLookUpValues(const AName: string; Value: TLookupValues); virtual;
    property Dataset: TDataset read GetDataset write SetDataset;
    property TargetId: string read FTargetId write FTargetId;
    property IdField: string write FIdField;
    property RequiredEditLevel: string read GetRequiredEditLevel;
    property RecordViewOption: TViewOption read FRecordViewOption write SetRecordViewOption;
  protected procedure LoadDFMValues; override; end;

  TBaseEditFormClass = class of TBaseEditForm;

implementation

uses
  MainDataModule,
  ErrorForm,
  App.Helper,
  SMX.Shared,
  Auth.Service,
  InputQueryModule;

{$R *.dfm}

procedure TBaseEditForm.WebFormCreate(Sender: TObject);
begin
  MessageLabel.Caption := '';
  FLoading := True;
  FSaveMessagesHandled := False;
  FPopUpShowing := False;

  if RequiredEditLevel.Contains(AuthService.UserScope) then
    RecordViewOption := TViewOption.voEdit
  else
    RecordViewOption := TViewOption.voReadOnly;
end;

procedure TBaseEditForm.WebFormDestroy(Sender: TObject);
begin
  TXDataWebDataset(DataSource.Dataset).AfterApplyUpdates := nil;
  if Assigned(FWebClient) then
  begin
    FWebClient.Free;
    FWebClient := nil;
  end;
end;

function TBaseEditForm.SaveDescription: string;
begin
  Result := 'Your changes have been saved.';
end;

function TBaseEditForm.ActionText: string;
begin
  case DataSource.State of
    dsEdit:
      Result := 'Editing ';
    dsInsert:
      Result := 'Adding ';
  else
    Result := 'Viewing ';
  end;
end;

procedure TBaseEditForm.AfterApplyUpdates(Sender: TDataset; Info: TResolveResults);
begin
  // for descedants
end;

procedure TBaseEditForm.AfterSetDataset;
begin
  RecordChanged;
end;

procedure TBaseEditForm.BeforePostRecord;
begin
  //
end;

procedure TBaseEditForm.CancelButtonClick(Sender: TObject);
begin
  if (DataSource.State in dsEditModes) then
  begin
    DataSource.Dataset.Cancel;
    if CloseOnSave then
      ModalResult := mrCancel
  end
  else
    ModalResult := mrCancel;
end;

function TBaseEditForm.CanSaveThis: boolean;
begin
  if Assigned(FErrorList) then
    FErrorList.Clear;

  FLastActionState := DataSource.State;
  Result := (DataSource.State in dsEditModes) and DataSource.Dataset.Modified;
end;

procedure TBaseEditForm.CheckEnterKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
var
  lBack: boolean;
begin
  if Key = 13 then
  begin
    if Sender is TWinControl then
    begin
      lBack := ssShift in Shift;
      SelectNext(Sender as TWinControl, not lBack, True);
    end;
  end;
end;

procedure TBaseEditForm.DataSourceDataChange(Sender: TObject; Field: TField);
begin
  if FLoading then
    Exit;
  if Field = nil then
  begin
    RecordChanged;
    NavButtonsStatus;
  end;
end;

destructor TBaseEditForm.Destroy;
begin
  if Assigned(FErrorList) then
  begin
    FErrorList.Free;
    FErrorList := nil;
  end;
  inherited;
end;

function TBaseEditForm.CloseOnSave: boolean;
begin
  Result := True;
end;

procedure TBaseEditForm.CloseTimerTimer(Sender: TObject);
begin
  CloseTimer.Enabled := False;
  WaitMessage.Hide;
  ModalResult := mrOK;
end;

procedure TBaseEditForm.DataSourceStateChange(Sender: TObject);
begin
  case DataSource.State of
    dsBrowse:
      begin
        if FRecordViewOption = TViewOption.voReadOnly then
          DataState.HTML.Text := 'Viewing (Read Only)'
        else
          DataState.HTML.Text := 'Viewing';
      end;
    dsEdit:
      DataState.HTML.Text := 'Editing';
    dsInsert:
      DataState.HTML.Text := 'Adding';
  else
    DataState.HTML.Text := 'Viewing';
  end;
  ValidateForm;
end;

procedure TBaseEditForm.EditElementsActions0Execute(Sender: TObject; Element: TJSHTMLElementRecord;
  Event: TJSEventParameter);
begin
  if TJSKeyboardEvent(Event.JSEvent).Key = 'Enter' then
  begin
    Event.JSEvent.preventDefault;
    THTMLHelper.enterAsTab(Element.Element.id);
  end;
end;

procedure TBaseEditForm.EditElementsActions1Execute(Sender: TObject; Element: TJSHTMLElementRecord;
  Event: TJSEventParameter);
begin
  Validate(Element.Element.id);
  ValidateForm;
end;

function TBaseEditForm.SaveTitle: string;
begin
  Result := 'Saved';
end;

procedure TBaseEditForm.GetAuditInfo;
var
  lAddedBy, lUpdatedBy: Integer;
  lField: TField;
  lRetval: TXDataClientResponse;
  lResult: TJSObject;
  lData: string;
  lDisplay: boolean;
begin

  if (DataSource.State = dsInsert) then
  begin
    AuditInfo.Visible := False;
    Exit;
  end;

  lAddedBy := 0;
  lUpdatedBy := 0;

  lField := Dataset.FindField('DateAdded');
  if lField <> nil then
    AddedByDate.Caption := FormatDateTime('dd/mm/yyyy', lField.AsDateTime)
  else
    AddedByDate.Caption := '';

  lField := Dataset.FindField('LastUpdated');
  if lField <> nil then
    UpdatedByDate.Caption := FormatDateTime('dd/mm/yyyy', lField.AsDateTime)
  else
    UpdatedByDate.Caption := '';

  lField := Dataset.FindField('AddedBy');
  if lField <> nil then
    lAddedBy := lField.AsInteger;
  lField := Dataset.FindField('LastUpdatedBy');
  if lField <> nil then
    lUpdatedBy := lField.AsInteger;

  if (lAddedBy > 0) or (lUpdatedBy > 0) then
  begin

    lRetval := await(TXDataClientResponse, WebClient.RawInvokeAsync(SVC_GETRECUSERS, [lAddedBy, lUpdatedBy]));

    lResult := lRetval.ResultAsObject;

    lData := JS.ToString(lResult['AddedBy']);
    if lData <> '' then
      AddedByLabel.Caption := lData
    else
      AddedByLabel.Caption := '';

    lData := JS.ToString(lResult['UpdatedBy']);
    if lData <> '' then
      UpdatedByLabel.Caption := lData
    else
      UpdatedByLabel.Caption := '';

    AuditInfo.Visible := True;
  end
  else
  begin
    AuditInfo.Visible := False;
  end;

end;

function TBaseEditForm.GetDataset: TDataset;
begin
  Result := DataSource.Dataset;
end;

function TBaseEditForm.GetErrorList: TStrings;
begin
  if not Assigned(FErrorList) then
  begin
    FErrorList := TStringList.Create;
    FErrorList.LineBreak := '<br>';
  end;
  Result := FErrorList;
end;

function TBaseEditForm.GetRequiredEditLevel: string;
begin
  Result := SCOPE_ADMIN;
end;

function TBaseEditForm.GetWebClient: TXDataWebClient;
begin
  if not Assigned(FWebClient) then
  begin
    FWebClient := TXDataWebClient.Create(Self);
    FWebClient.Connection := MainData.DataConnection;
  end;
  Result := FWebClient;
end;

function TBaseEditForm.IsErrorList: boolean;
begin
  Result := Assigned(FErrorList);
end;

procedure TBaseEditForm.NavButtonsStatus;
begin
  PriorButton.Enabled := not DataSource.Dataset.Bof;
  NextButton.Enabled := not DataSource.Dataset.Eof;
end;

procedure TBaseEditForm.NextButtonClick(Sender: TObject);
begin
  DataSource.Dataset.Next;
end;

procedure TBaseEditForm.PriorButtonClick(Sender: TObject);
begin
  DataSource.Dataset.Prior;
end;

procedure TBaseEditForm.RecordChanged;
var
  lId: string;
begin
  lId := Dataset.FieldByName(FIdField).AsString;
  if lId = FLastProcessedId then
    Exit;

  FLastProcessedId := lId;

  ValidateForm;

  // GetAuditInfo;
end;

procedure TBaseEditForm.SaveButtonClick(Sender: TObject);
begin
  FSaveMessagesHandled := False;
  if not CanSaveThis then
  begin
    if not FSaveMessagesHandled then
    begin
      if Assigned(FErrorList) then
        TErrorPage.Display('You can''t save this yet', THTMLHelper.stringsToUL(FErrorList, 'list-group',
          'list-group-item'), True, True)
      else
        ShowMessage('Some details need completing before you can save this');
    end
    else
      FSaveMessagesHandled := False;
    Exit;
  end;

  BeforePostRecord;
  DataSource.Dataset.Post;

  if CloseOnSave then
  begin
    ShowAToast(SaveTitle, SaveDescription, 'ok.png');
    WaitMessage.Show;
    CloseTimer.Enabled := True;
  end;

end;

{ TBaseEditForm }

procedure TBaseEditForm.SetDataset(const Value: TDataset);
begin
  DataSource.Dataset := Value;
  TXDataWebDataset(DataSource.Dataset).AfterApplyUpdates := AfterApplyUpdates;
  AfterSetDataset;
  FLoading := False;
  ValidateForm;
end;

procedure TBaseEditForm.SetLookUpValues(const AName: string; Value: TLookupValues);
begin
  // For Descendants if needed
end;

procedure TBaseEditForm.SetRecordViewOption(const Value: TViewOption);
begin
  FRecordViewOption := Value;
  DataSource.AutoEdit := (FRecordViewOption in [voEdit, voCreateAndEdit]);
end;

procedure TBaseEditForm.TrapEnterPressSetUp;
var
  I: Integer;
begin
  for I := 0 to ControlCount - 1 do
  begin
    if Controls[I] is TEdit then
      TEdit(Controls[I]).OnKeyDown := CheckEnterKeyDown
    else if Controls[I] is TComboBox then
      TComboBox(Controls[I]).OnKeyDown := CheckEnterKeyDown;
  end;
end;

function TBaseEditForm.Validate(const AControlName: string): boolean;
begin
  // for descendants if needed
  Result := True;
end;

function TBaseEditForm.ValidateForm: boolean;
begin
  if FPopUpShowing then
     Exit;

  if FRecordViewOption = TViewOption.voReadOnly then
  begin
    CancelButton.Caption := 'Close';
    ButtonInvalid(SaveButton);
    SaveButton.Visible := False;
    Exit;
  end;

  if ((DataSource.State in dsEditModes) and DataSource.Dataset.Modified) and CanSaveThis then
  begin
    ButtonValid(SaveButton);
    CancelButton.Caption := 'Cancel';
  end
  else
  begin
    ButtonInvalid(SaveButton);
    if (not(DataSource.State in dsEditModes)) or (not DataSource.Dataset.Modified) then
      CancelButton.Caption := 'Close'
    else
      CancelButton.Caption := 'Cancel';
  end;
end;

procedure TBaseEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormCaption := TLabel.Create('FormCaption');
  MessageLabel := TLabel.Create('MessageLabel');
  CancelButton := TButton.Create('CancelButton');
  SaveButton := TButton.Create('SaveButton');
  DataState := THTMLSpan.Create('DataState');
  PriorButton := TButton.Create('PriorButton');
  NextButton := TButton.Create('NextButton');
  WaitMessage := TWaitMessage.Create(Self);
  AuditInfo := THTMLDiv.Create('AuditInfo');
  UpdatedByLabel := TLabel.Create('UpdatedByLabel');
  AddedByLabel := TLabel.Create('AddedByLabel');
  AddedByDate := TLabel.Create('AddedByDate');
  UpdatedByDate := TLabel.Create('UpdatedByDate');
  DataSource := TDataSource.Create(Self);
  EditElements := TElementActionList.Create(Self);
  CloseTimer := TTimer.Create(Self);

  FormCaption.BeforeLoadDFMValues;
  MessageLabel.BeforeLoadDFMValues;
  CloseThisButton.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  DataState.BeforeLoadDFMValues;
  PriorButton.BeforeLoadDFMValues;
  NextButton.BeforeLoadDFMValues;
  WaitMessage.BeforeLoadDFMValues;
  AuditInfo.BeforeLoadDFMValues;
  UpdatedByLabel.BeforeLoadDFMValues;
  AddedByLabel.BeforeLoadDFMValues;
  AddedByDate.BeforeLoadDFMValues;
  UpdatedByDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  EditElements.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  try
    Width := 1440;
    Height := 1080;
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    FormCaption.SetParentComponent(Self);
    FormCaption.Name := 'FormCaption';
    FormCaption.Left := 72;
    FormCaption.Top := 12;
    FormCaption.Width := 73;
    FormCaption.Height := 15;
    FormCaption.Margins.Left := 5;
    FormCaption.Margins.Top := 5;
    FormCaption.Margins.Right := 5;
    FormCaption.Margins.Bottom := 5;
    FormCaption.Caption := 'Form Caption';
    FormCaption.ElementClassName := 'HeadlineClass';
    FormCaption.ElementFont := efCSS;
    FormCaption.ElementPosition := epIgnore;
    FormCaption.HeightStyle := ssAuto;
    FormCaption.HeightPercent := 100.000000000000000000;
    FormCaption.HTMLType := tDIV;
    FormCaption.WidthStyle := ssAuto;
    FormCaption.WidthPercent := 100.000000000000000000;
    MessageLabel.SetParentComponent(Self);
    MessageLabel.Name := 'MessageLabel';
    MessageLabel.Left := 68;
    MessageLabel.Top := 41;
    MessageLabel.Width := 74;
    MessageLabel.Height := 15;
    MessageLabel.Margins.Left := 5;
    MessageLabel.Margins.Top := 5;
    MessageLabel.Margins.Right := 5;
    MessageLabel.Margins.Bottom := 5;
    MessageLabel.Caption := 'MessageLabel';
    MessageLabel.ElementFont := efCSS;
    MessageLabel.ElementPosition := epIgnore;
    MessageLabel.HeightStyle := ssAuto;
    MessageLabel.HeightPercent := 100.000000000000000000;
    MessageLabel.HTMLType := tDIV;
    MessageLabel.WidthStyle := ssAuto;
    MessageLabel.WidthPercent := 100.000000000000000000;
    CloseThisButton.Left := 1350;
    CloseThisButton.Top := 18;
    CloseThisButton.Width := 72;
    CloseThisButton.Height := 57;
    CloseThisButton.Margins.Left := 8;
    CloseThisButton.Margins.Top := 8;
    CloseThisButton.Margins.Right := 8;
    CloseThisButton.Margins.Bottom := 8;
    CloseThisButton.Visible := False;
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 252;
    CancelButton.Top := 444;
    CancelButton.Width := 144;
    CancelButton.Height := 38;
    CancelButton.Margins.Left := 5;
    CancelButton.Margins.Top := 5;
    CancelButton.Margins.Right := 5;
    CancelButton.Margins.Bottom := 5;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 3;
    CancelButton.ElementClassName := 'btn btn-primary';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 72;
    SaveButton.Top := 444;
    SaveButton.Width := 144;
    SaveButton.Height := 38;
    SaveButton.Margins.Left := 5;
    SaveButton.Margins.Top := 5;
    SaveButton.Margins.Right := 5;
    SaveButton.Margins.Bottom := 5;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 2;
    SaveButton.ElementClassName := 'btn btn-secondary';
    SaveButton.ElementFont := efCSS;
    SaveButton.ElementPosition := epIgnore;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthStyle := ssAuto;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    DataState.SetParentComponent(Self);
    DataState.Name := 'DataState';
    DataState.Left := 54;
    DataState.Top := 69;
    DataState.Width := 150;
    DataState.Height := 38;
    DataState.Margins.Left := 5;
    DataState.Margins.Top := 5;
    DataState.Margins.Right := 5;
    DataState.Margins.Bottom := 5;
    DataState.HeightStyle := ssAuto;
    DataState.WidthStyle := ssAuto;
    DataState.HeightPercent := 100.000000000000000000;
    DataState.WidthPercent := 100.000000000000000000;
    DataState.ChildOrder := 5;
    DataState.ElementPosition := epIgnore;
    DataState.ElementFont := efCSS;
    DataState.Role := '';
    PriorButton.SetParentComponent(Self);
    PriorButton.Name := 'PriorButton';
    PriorButton.Left := 228;
    PriorButton.Top := 69;
    PriorButton.Width := 50;
    PriorButton.Height := 38;
    PriorButton.Margins.Left := 5;
    PriorButton.Margins.Top := 5;
    PriorButton.Margins.Right := 5;
    PriorButton.Margins.Bottom := 5;
    PriorButton.Caption := '<';
    PriorButton.ChildOrder := 6;
    PriorButton.ElementClassName := 'btn btn-info';
    PriorButton.ElementFont := efCSS;
    PriorButton.ElementPosition := epIgnore;
    PriorButton.HeightStyle := ssAuto;
    PriorButton.HeightPercent := 100.000000000000000000;
    PriorButton.Visible := False;
    PriorButton.WidthStyle := ssAuto;
    PriorButton.WidthPercent := 100.000000000000000000;
    SetEvent(PriorButton, Self, 'OnClick', 'PriorButtonClick');
    NextButton.SetParentComponent(Self);
    NextButton.Name := 'NextButton';
    NextButton.Left := 287;
    NextButton.Top := 69;
    NextButton.Width := 49;
    NextButton.Height := 38;
    NextButton.Margins.Left := 5;
    NextButton.Margins.Top := 5;
    NextButton.Margins.Right := 5;
    NextButton.Margins.Bottom := 5;
    NextButton.Caption := '>';
    NextButton.ChildOrder := 7;
    NextButton.ElementClassName := 'btn btn-info';
    NextButton.ElementFont := efCSS;
    NextButton.ElementPosition := epIgnore;
    NextButton.HeightStyle := ssAuto;
    NextButton.HeightPercent := 100.000000000000000000;
    NextButton.Visible := False;
    NextButton.WidthStyle := ssAuto;
    NextButton.WidthPercent := 100.000000000000000000;
    SetEvent(NextButton, Self, 'OnClick', 'NextButtonClick');
    WaitMessage.SetParentComponent(Self);
    WaitMessage.Name := 'WaitMessage';
    WaitMessage.Left := 228;
    WaitMessage.Top := 576;
    WaitMessage.Width := 24;
    WaitMessage.Height := 24;
    WaitMessage.Margins.Left := 5;
    WaitMessage.Margins.Top := 5;
    WaitMessage.Margins.Right := 5;
    WaitMessage.Margins.Bottom := 5;
    WaitMessage.HeightStyle := ssAuto;
    WaitMessage.WidthStyle := ssAuto;
    WaitMessage.HeightPercent := 100.000000000000000000;
    WaitMessage.WidthPercent := 100.000000000000000000;
    WaitMessage.Opacity := 0.200000000000000000;
    WaitMessage.PictureURL := 'images/Whirligig.gif';
    AuditInfo.SetParentComponent(Self);
    AuditInfo.Name := 'AuditInfo';
    AuditInfo.Left := 599;
    AuditInfo.Top := 635;
    AuditInfo.Width := 349;
    AuditInfo.Height := 73;
    AuditInfo.Margins.Left := 5;
    AuditInfo.Margins.Top := 5;
    AuditInfo.Margins.Right := 5;
    AuditInfo.Margins.Bottom := 5;
    AuditInfo.HeightStyle := ssAuto;
    AuditInfo.WidthStyle := ssAuto;
    AuditInfo.HeightPercent := 100.000000000000000000;
    AuditInfo.WidthPercent := 100.000000000000000000;
    AuditInfo.ChildOrder := 11;
    AuditInfo.ElementPosition := epIgnore;
    AuditInfo.ElementFont := efCSS;
    AuditInfo.Role := '';
    AuditInfo.Visible := False;
    UpdatedByLabel.SetParentComponent(AuditInfo);
    UpdatedByLabel.Name := 'UpdatedByLabel';
    UpdatedByLabel.Left := 192;
    UpdatedByLabel.Top := 5;
    UpdatedByLabel.Width := 86;
    UpdatedByLabel.Height := 15;
    UpdatedByLabel.Margins.Left := 5;
    UpdatedByLabel.Margins.Top := 5;
    UpdatedByLabel.Margins.Right := 5;
    UpdatedByLabel.Margins.Bottom := 5;
    UpdatedByLabel.Caption := 'UpdatedByLabel';
    UpdatedByLabel.ElementFont := efCSS;
    UpdatedByLabel.HeightStyle := ssAuto;
    UpdatedByLabel.HeightPercent := 100.000000000000000000;
    UpdatedByLabel.HTMLType := tSPAN;
    UpdatedByLabel.WidthPercent := 100.000000000000000000;
    AddedByLabel.SetParentComponent(AuditInfo);
    AddedByLabel.Name := 'AddedByLabel';
    AddedByLabel.Left := 24;
    AddedByLabel.Top := 5;
    AddedByLabel.Width := 76;
    AddedByLabel.Height := 15;
    AddedByLabel.Margins.Left := 5;
    AddedByLabel.Margins.Top := 5;
    AddedByLabel.Margins.Right := 5;
    AddedByLabel.Margins.Bottom := 5;
    AddedByLabel.Caption := 'AddedByLabel';
    AddedByLabel.ElementFont := efCSS;
    AddedByLabel.HeightStyle := ssAuto;
    AddedByLabel.HeightPercent := 100.000000000000000000;
    AddedByLabel.HTMLType := tSPAN;
    AddedByLabel.WidthPercent := 100.000000000000000000;
    AddedByDate.SetParentComponent(AuditInfo);
    AddedByDate.Name := 'AddedByDate';
    AddedByDate.Left := 24;
    AddedByDate.Top := 33;
    AddedByDate.Width := 72;
    AddedByDate.Height := 15;
    AddedByDate.Margins.Left := 5;
    AddedByDate.Margins.Top := 5;
    AddedByDate.Margins.Right := 5;
    AddedByDate.Margins.Bottom := 5;
    AddedByDate.Caption := 'AddedByDate';
    AddedByDate.ElementFont := efCSS;
    AddedByDate.HeightStyle := ssAuto;
    AddedByDate.HeightPercent := 100.000000000000000000;
    AddedByDate.HTMLType := tSPAN;
    AddedByDate.WidthPercent := 100.000000000000000000;
    UpdatedByDate.SetParentComponent(AuditInfo);
    UpdatedByDate.Name := 'UpdatedByDate';
    UpdatedByDate.Left := 192;
    UpdatedByDate.Top := 33;
    UpdatedByDate.Width := 82;
    UpdatedByDate.Height := 15;
    UpdatedByDate.Margins.Left := 5;
    UpdatedByDate.Margins.Top := 5;
    UpdatedByDate.Margins.Right := 5;
    UpdatedByDate.Margins.Bottom := 5;
    UpdatedByDate.Caption := 'UpdatedByDate';
    UpdatedByDate.ElementFont := efCSS;
    UpdatedByDate.HeightStyle := ssAuto;
    UpdatedByDate.HeightPercent := 100.000000000000000000;
    UpdatedByDate.HTMLType := tSPAN;
    UpdatedByDate.WidthPercent := 100.000000000000000000;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    SetEvent(DataSource, Self, 'OnStateChange', 'DataSourceStateChange');
    SetEvent(DataSource, Self, 'OnDataChange', 'DataSourceDataChange');
    DataSource.Left := 280;
    DataSource.Top := 40;
    EditElements.SetParentComponent(Self);
    EditElements.Name := 'EditElements';
    EditElements.Actions.Clear;
    with EditElements.Actions.Add do
    begin
      Enabled := False;
      Event := heKeydown;
      ID := '';
      Name := 'EnterToTab';
      PreventDefault := False;
      Selector := 'input';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'EditElementsActions0Execute');
    end;
    with EditElements.Actions.Add do
    begin
      Event := heBlur;
      ID := '';
      Name := 'ValidateData';
      PreventDefault := False;
      Selector := 'input,select';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'EditElementsActions1Execute');
    end;
    EditElements.Left := 424;
    EditElements.Top := 24;
    CloseTimer.SetParentComponent(Self);
    CloseTimer.Name := 'CloseTimer';
    CloseTimer.Enabled := False;
    CloseTimer.Interval := 2000;
    SetEvent(CloseTimer, Self, 'OnTimer', 'CloseTimerTimer');
    CloseTimer.Left := 72;
    CloseTimer.Top := 376;
  finally
    FormCaption.AfterLoadDFMValues;
    MessageLabel.AfterLoadDFMValues;
    CloseThisButton.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    DataState.AfterLoadDFMValues;
    PriorButton.AfterLoadDFMValues;
    NextButton.AfterLoadDFMValues;
    WaitMessage.AfterLoadDFMValues;
    AuditInfo.AfterLoadDFMValues;
    UpdatedByLabel.AfterLoadDFMValues;
    AddedByLabel.AfterLoadDFMValues;
    AddedByDate.AfterLoadDFMValues;
    UpdatedByDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    EditElements.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
  end;
end;

end.
