unit DonationList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseCoreForm,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset;

type

  TDonationListType = (dltImport, dltDonor);

  TDonations_List = class(TCoreWebForm)
    DonationsData: TXDataWebDataSet;
    DonationsDataId: TIntegerField;
    DonationsDataDateAdded: TDateTimeField;
    DonationsDataAddedBy: TIntegerField;
    DonationsDataLastUpdatedBy: TIntegerField;
    DonationsDataLastUpdated: TDateTimeField;
    DonationsDataExternalId: TStringField;
    DonationsDataSponsored: TBooleanField;
    DonationsDataDonationDate: TDateTimeField;
    DonationsDataImportId: TIntegerField;
    DonationsDataSourceMethod: TStringField;
    DonationsDataClaimId: TIntegerField;
    DonationsDataGiftAid: TFloatField;
    DonationsDataAmount: TFloatField;
    DonationsDataDonorId: TIntegerField;
    DonationsDataClaimStatus: TStringField;
    DonationsDataClaimDate: TDateTimeField;
    DonationsDataTaxYear: TIntegerField;
    DonationsDataClaimRef: TStringField;
    DonationsDataDonorPostCode: TStringField;
    DonationsDataDonorName: TStringField;
    DonationsSource: TDataSource;
    DonationsTable: TDBTableControl;
    DonationsCloseButton: TButton;
    procedure CloseThisButtonClick(Sender: TObject);
    procedure DonationsCloseButtonClick(Sender: TObject);
  private
    FId: Integer;
    FListType: TDonationListType;
    procedure SetDonorId(const Value: Integer);
    procedure SetImportId(const Value: Integer);
    { Private declarations }
  public
    { Public declarations }
    procedure AfterCreated;
    property DonorId: Integer write SetDonorId;
    property ImportId: Integer write SetImportId;
  protected procedure LoadDFMValues; override; end;

var
  Donations_List: TDonations_List;

implementation

{$R *.dfm}

uses MainDataModule;
{ TForm1 }

procedure TDonations_List.AfterCreated;
const
filter_field: array[TDonationListType] of string = ('ImportId', 'DonorId');
begin
  DonationsData.QueryString := '$filter=' + filter_field[FListType] + ' eq ' + FId.ToString;
  DonationsData.Load;
end;

procedure TDonations_List.CloseThisButtonClick(Sender: TObject);
begin
 ModalResult := mrOK;
end;

procedure TDonations_List.DonationsCloseButtonClick(Sender: TObject);
begin
 ModalResult := mrOK;
end;

procedure TDonations_List.SetDonorId(const Value: Integer);
begin
  FListType := dltDonor;
  FId := Value;
end;

procedure TDonations_List.SetImportId(const Value: Integer);
begin
  FListType := dltImport;
  FId := Value;
end;

procedure TDonations_List.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DonationsTable := TDBTableControl.Create('DonationsTable');
  DonationsCloseButton := TButton.Create('DonationsCloseButton');
  DonationsData := TXDataWebDataSet.Create(Self);
  DonationsDataId := TIntegerField.Create(Self);
  DonationsDataDateAdded := TDateTimeField.Create(Self);
  DonationsDataAddedBy := TIntegerField.Create(Self);
  DonationsDataLastUpdatedBy := TIntegerField.Create(Self);
  DonationsDataLastUpdated := TDateTimeField.Create(Self);
  DonationsDataExternalId := TStringField.Create(Self);
  DonationsDataSponsored := TBooleanField.Create(Self);
  DonationsDataDonationDate := TDateTimeField.Create(Self);
  DonationsDataImportId := TIntegerField.Create(Self);
  DonationsDataSourceMethod := TStringField.Create(Self);
  DonationsDataClaimId := TIntegerField.Create(Self);
  DonationsDataGiftAid := TFloatField.Create(Self);
  DonationsDataAmount := TFloatField.Create(Self);
  DonationsDataDonorId := TIntegerField.Create(Self);
  DonationsDataClaimStatus := TStringField.Create(Self);
  DonationsDataClaimDate := TDateTimeField.Create(Self);
  DonationsDataTaxYear := TIntegerField.Create(Self);
  DonationsDataClaimRef := TStringField.Create(Self);
  DonationsDataDonorPostCode := TStringField.Create(Self);
  DonationsDataDonorName := TStringField.Create(Self);
  DonationsSource := TDataSource.Create(Self);

  CloseThisButton.BeforeLoadDFMValues;
  DonationsTable.BeforeLoadDFMValues;
  DonationsCloseButton.BeforeLoadDFMValues;
  DonationsData.BeforeLoadDFMValues;
  DonationsDataId.BeforeLoadDFMValues;
  DonationsDataDateAdded.BeforeLoadDFMValues;
  DonationsDataAddedBy.BeforeLoadDFMValues;
  DonationsDataLastUpdatedBy.BeforeLoadDFMValues;
  DonationsDataLastUpdated.BeforeLoadDFMValues;
  DonationsDataExternalId.BeforeLoadDFMValues;
  DonationsDataSponsored.BeforeLoadDFMValues;
  DonationsDataDonationDate.BeforeLoadDFMValues;
  DonationsDataImportId.BeforeLoadDFMValues;
  DonationsDataSourceMethod.BeforeLoadDFMValues;
  DonationsDataClaimId.BeforeLoadDFMValues;
  DonationsDataGiftAid.BeforeLoadDFMValues;
  DonationsDataAmount.BeforeLoadDFMValues;
  DonationsDataDonorId.BeforeLoadDFMValues;
  DonationsDataClaimStatus.BeforeLoadDFMValues;
  DonationsDataClaimDate.BeforeLoadDFMValues;
  DonationsDataTaxYear.BeforeLoadDFMValues;
  DonationsDataClaimRef.BeforeLoadDFMValues;
  DonationsDataDonorPostCode.BeforeLoadDFMValues;
  DonationsDataDonorName.BeforeLoadDFMValues;
  DonationsSource.BeforeLoadDFMValues;
  try
    CloseThisButton.Left := 556;
    CloseThisButton.Top := 36;
    CloseThisButton.Caption := 'Close';
    CloseThisButton.Visible := False;
    DonationsTable.SetParentComponent(Self);
    DonationsTable.Name := 'DonationsTable';
    DonationsTable.Left := 32;
    DonationsTable.Top := 104;
    DonationsTable.Width := 513;
    DonationsTable.Height := 249;
    DonationsTable.HeightStyle := ssAuto;
    DonationsTable.WidthStyle := ssAuto;
    DonationsTable.BorderColor := clSilver;
    DonationsTable.ChildOrder := 4;
    DonationsTable.ElementFont := efCSS;
    DonationsTable.ElementHeaderClassName := 'thead-light';
    DonationsTable.ElementPosition := epIgnore;
    DonationsTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    DonationsTable.Columns.Clear;
    with DonationsTable.Columns.Add do
    begin
      DataField := 'DonationDate';
      Title := 'DonationDate';
    end;
    with DonationsTable.Columns.Add do
    begin
      DataField := 'DonorName';
      Title := 'Donor';
    end;
    with DonationsTable.Columns.Add do
    begin
      DataField := 'DonorPostCode';
      Title := 'Post Code';
    end;
    with DonationsTable.Columns.Add do
    begin
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with DonationsTable.Columns.Add do
    begin
      DataField := 'ClaimStatus';
      Title := 'Status';
    end;
    with DonationsTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    DonationsTable.DataSource := DonationsSource;
    DonationsCloseButton.SetParentComponent(Self);
    DonationsCloseButton.Name := 'DonationsCloseButton';
    DonationsCloseButton.Left := 449;
    DonationsCloseButton.Top := 372;
    DonationsCloseButton.Width := 96;
    DonationsCloseButton.Height := 25;
    DonationsCloseButton.Caption := 'Close';
    DonationsCloseButton.ChildOrder := 3;
    DonationsCloseButton.ElementClassName := 'btn btn-light';
    DonationsCloseButton.ElementFont := efCSS;
    DonationsCloseButton.ElementPosition := epIgnore;
    DonationsCloseButton.HeightStyle := ssAuto;
    DonationsCloseButton.HeightPercent := 100.000000000000000000;
    DonationsCloseButton.WidthStyle := ssAuto;
    DonationsCloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(DonationsCloseButton, Self, 'OnClick', 'DonationsCloseButtonClick');
    DonationsData.SetParentComponent(Self);
    DonationsData.Name := 'DonationsData';
    DonationsData.EntitySetName := 'Donation';
    DonationsData.Connection := MainData.DataConnection;
    DonationsData.Left := 64;
    DonationsData.Top := 8;
    DonationsDataId.SetParentComponent(DonationsData);
    DonationsDataId.Name := 'DonationsDataId';
    DonationsDataId.FieldName := 'Id';
    DonationsDataId.Required := True;
    DonationsDataDateAdded.SetParentComponent(DonationsData);
    DonationsDataDateAdded.Name := 'DonationsDataDateAdded';
    DonationsDataDateAdded.FieldName := 'DateAdded';
    DonationsDataDateAdded.Required := True;
    DonationsDataAddedBy.SetParentComponent(DonationsData);
    DonationsDataAddedBy.Name := 'DonationsDataAddedBy';
    DonationsDataAddedBy.FieldName := 'AddedBy';
    DonationsDataAddedBy.Required := True;
    DonationsDataLastUpdatedBy.SetParentComponent(DonationsData);
    DonationsDataLastUpdatedBy.Name := 'DonationsDataLastUpdatedBy';
    DonationsDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    DonationsDataLastUpdated.SetParentComponent(DonationsData);
    DonationsDataLastUpdated.Name := 'DonationsDataLastUpdated';
    DonationsDataLastUpdated.FieldName := 'LastUpdated';
    DonationsDataExternalId.SetParentComponent(DonationsData);
    DonationsDataExternalId.Name := 'DonationsDataExternalId';
    DonationsDataExternalId.FieldName := 'ExternalId';
    DonationsDataExternalId.Required := True;
    DonationsDataExternalId.Size := 255;
    DonationsDataSponsored.SetParentComponent(DonationsData);
    DonationsDataSponsored.Name := 'DonationsDataSponsored';
    DonationsDataSponsored.FieldName := 'Sponsored';
    DonationsDataSponsored.Required := True;
    DonationsDataDonationDate.SetParentComponent(DonationsData);
    DonationsDataDonationDate.Name := 'DonationsDataDonationDate';
    DonationsDataDonationDate.FieldName := 'DonationDate';
    DonationsDataDonationDate.Required := True;
    DonationsDataImportId.SetParentComponent(DonationsData);
    DonationsDataImportId.Name := 'DonationsDataImportId';
    DonationsDataImportId.FieldName := 'ImportId';
    DonationsDataSourceMethod.SetParentComponent(DonationsData);
    DonationsDataSourceMethod.Name := 'DonationsDataSourceMethod';
    DonationsDataSourceMethod.FieldName := 'SourceMethod';
    DonationsDataSourceMethod.Required := True;
    DonationsDataSourceMethod.Size := 9;
    DonationsDataClaimId.SetParentComponent(DonationsData);
    DonationsDataClaimId.Name := 'DonationsDataClaimId';
    DonationsDataClaimId.FieldName := 'ClaimId';
    DonationsDataGiftAid.SetParentComponent(DonationsData);
    DonationsDataGiftAid.Name := 'DonationsDataGiftAid';
    DonationsDataGiftAid.FieldName := 'GiftAid';
    DonationsDataGiftAid.Required := True;
    DonationsDataAmount.SetParentComponent(DonationsData);
    DonationsDataAmount.Name := 'DonationsDataAmount';
    DonationsDataAmount.FieldName := 'Amount';
    DonationsDataAmount.Required := True;
    DonationsDataDonorId.SetParentComponent(DonationsData);
    DonationsDataDonorId.Name := 'DonationsDataDonorId';
    DonationsDataDonorId.FieldName := 'DonorId';
    DonationsDataDonorId.Required := True;
    DonationsDataClaimStatus.SetParentComponent(DonationsData);
    DonationsDataClaimStatus.Name := 'DonationsDataClaimStatus';
    DonationsDataClaimStatus.FieldName := 'ClaimStatus';
    DonationsDataClaimStatus.Required := True;
    DonationsDataClaimStatus.Size := 9;
    DonationsDataClaimDate.SetParentComponent(DonationsData);
    DonationsDataClaimDate.Name := 'DonationsDataClaimDate';
    DonationsDataClaimDate.FieldName := 'ClaimDate';
    DonationsDataTaxYear.SetParentComponent(DonationsData);
    DonationsDataTaxYear.Name := 'DonationsDataTaxYear';
    DonationsDataTaxYear.FieldName := 'TaxYear';
    DonationsDataClaimRef.SetParentComponent(DonationsData);
    DonationsDataClaimRef.Name := 'DonationsDataClaimRef';
    DonationsDataClaimRef.FieldName := 'ClaimRef';
    DonationsDataClaimRef.Size := 32;
    DonationsDataDonorPostCode.SetParentComponent(DonationsData);
    DonationsDataDonorPostCode.Name := 'DonationsDataDonorPostCode';
    DonationsDataDonorPostCode.FieldName := 'DonorPostCode';
    DonationsDataDonorPostCode.Size := 15;
    DonationsDataDonorName.SetParentComponent(DonationsData);
    DonationsDataDonorName.Name := 'DonationsDataDonorName';
    DonationsDataDonorName.FieldName := 'DonorName';
    DonationsDataDonorName.Size := 50;
    DonationsSource.SetParentComponent(Self);
    DonationsSource.Name := 'DonationsSource';
    DonationsSource.DataSet := DonationsData;
    DonationsSource.Left := 176;
    DonationsSource.Top := 8;
  finally
    CloseThisButton.AfterLoadDFMValues;
    DonationsTable.AfterLoadDFMValues;
    DonationsCloseButton.AfterLoadDFMValues;
    DonationsData.AfterLoadDFMValues;
    DonationsDataId.AfterLoadDFMValues;
    DonationsDataDateAdded.AfterLoadDFMValues;
    DonationsDataAddedBy.AfterLoadDFMValues;
    DonationsDataLastUpdatedBy.AfterLoadDFMValues;
    DonationsDataLastUpdated.AfterLoadDFMValues;
    DonationsDataExternalId.AfterLoadDFMValues;
    DonationsDataSponsored.AfterLoadDFMValues;
    DonationsDataDonationDate.AfterLoadDFMValues;
    DonationsDataImportId.AfterLoadDFMValues;
    DonationsDataSourceMethod.AfterLoadDFMValues;
    DonationsDataClaimId.AfterLoadDFMValues;
    DonationsDataGiftAid.AfterLoadDFMValues;
    DonationsDataAmount.AfterLoadDFMValues;
    DonationsDataDonorId.AfterLoadDFMValues;
    DonationsDataClaimStatus.AfterLoadDFMValues;
    DonationsDataClaimDate.AfterLoadDFMValues;
    DonationsDataTaxYear.AfterLoadDFMValues;
    DonationsDataClaimRef.AfterLoadDFMValues;
    DonationsDataDonorPostCode.AfterLoadDFMValues;
    DonationsDataDonorName.AfterLoadDFMValues;
    DonationsSource.AfterLoadDFMValues;
  end;
end;

end.
