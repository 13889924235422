unit App.Types;

interface

uses
  JS,
  WEBLib.Dialogs,
  WebLib.Forms;

type
  TProc = reference to procedure;

  TSuccessProc = reference to procedure;
  TLogoutProc = reference to procedure(AMessage: string = ''; const LoggingOut: Boolean = False);
  TUnauthorizedAccessProc = reference to procedure(AMessage: string);

  TListProc = reference to procedure;
  TEditProc = reference to procedure(AId: JSValue);

  TShowMessageProc = procedure(const AMessage: string; DlgType: TMsgDlgType) of object;
  TErrorMessageProc = procedure(Value: TApplicationError; var AHandled: boolean) of object;
  TXDataErrorProc = procedure(Value: TApplicationError; var AHandled: boolean) of object;

implementation

end.
