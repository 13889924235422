program GiftAiderLive;

uses
  Vcl.Forms,
  WEBLib.Forms,
  MainForm in 'MainForm.pas'{*.html},
  MainDataModule in 'MainDataModule.pas',
  AppConfig in 'AppConfig.pas',
  DashboardForm in 'DashboardForm.pas'{*.html},
  Auth.Service in 'Core\Auth.Service.pas',
  App.Types in 'Core\App.Types.pas',
  LoginForm in 'LoginForm.pas'{*.html},
  ErrorForm in 'ErrorForm.pas'{*.html},
  SMX.Shared in '..\Shared\SMX.Shared.pas',
  BaseList in 'BaseList.pas'{*.html},
  Dataset.Plugins in 'Core\Dataset.Plugins.pas',
  Grid.Plugins in 'Core\Grid.Plugins.pas',
  Grid.Settings in 'Core\Grid.Settings.pas',
  Paginator.Plugins in 'Core\Paginator.Plugins.pas',
  BaseForm in 'BaseForm.pas'{*.html},
  UserForm in 'UserForm.pas'{*.html},
  UserList in 'UserList.pas'{*.html},
  App.Helper in 'Core\App.Helper.pas',
  JobDetailForm in 'JobDetailForm.pas'{*.html},
  SelfUpdateForm in 'SelfUpdateForm.pas'{*.html},
  BaseCoreForm in 'BaseCoreForm.pas'{*.html},
  ForgottenPasswordForm in 'ForgottenPasswordForm.pas'{*.html},
  JobBaseForm in 'JobBaseForm.pas'{*.html},
  JobErrorForm in 'JobErrorForm.pas'{*.html},
  smx.webcore.types in 'Core\smx.webcore.types.pas',
  SysConfigForm in 'SysConfigForm.pas'{*.html},
  DashDataForm in 'DashDataForm.pas'{*.html},
  SupportForm in 'SupportForm.pas'{*.html},
  InputQueryModule in 'InputQueryModule.pas'{*.html},
  App.Utils in 'Core\App.Utils.pas',
  CRM.Utils in 'crm\CRM.Utils.pas',
  DonorForm in 'DonorForm.pas'{*.html},
  DonorList in 'DonorList.pas'{*.html},
  GiftAidClaimsList in 'GiftAidClaimsList.pas'{*.html},
  DonorBaseViewForm in 'DonorBaseViewForm.pas'{*.html},
  AdvancedSearchForm in 'AdvancedSearchForm.pas'{*.html},
  Contact.Utils in 'Core\Contact.Utils.pas',
  DupesForm in 'DupesForm.pas'{*.html},
  App.Layout.Utils in 'Core\App.Layout.Utils.pas',
  DonationsForm in 'DonationsForm.pas'{*.html},
  ImportForm in 'ImportForm.pas',
  GiftAidDetailsForm in 'GiftAidDetailsForm.pas'{*.html},
  GiftAidClaimForm in 'GiftAidClaimForm.pas'{*.html},
  GAConfigForm in 'GAConfigForm.pas'{*.html},
  ImportList in 'ImportList.pas'{*.html},
  ImportBatch in 'ImportBatch.pas'{*.html},
  DonationList in 'DonationList.pas'{*.html},
  ImportData in 'ImportData.pas'{*.html};

{$R *.res}

procedure DisplayLoginPage(AMessage: string = ''; const LoggingOut: Boolean = False); forward;

procedure DisplayMainView;

  procedure ConnectProc;
  begin
    if Assigned(LoginPage) then
    begin
      LoginPage.Free;
      LoginPage := Nil;
    end;

    TMainPage.Display('publishhere', @DisplayLoginPage);
  end;

begin
  if not MainData.DataConnection.Connected then
    MainData.DataConnection.Open(@ConnectProc)
  else
    ConnectProc;
end;

procedure DisplayLoginPage(AMessage: string; const LoggingOut: Boolean);
begin
  if Assigned(LoginPage) then
     Exit;
  if LoggingOut then
  begin
    if Assigned(MainPage) then
    begin
      MainPage.Free;
      MainPage := Nil;
    end;
  end;
  AuthService.Logout(LoggingOut);
  MainData.DataConnection.Connected := False;
  TLoginPage.Display('publishhere', @DisplayMainView, AMessage);
end;

procedure UnauthorizedAccessProc(AMessage: string);
begin
  MainPage.ReLogin(AMessage);
//  DisplayLoginPage(AMessage);
end;

procedure StartApplication;
begin
  if (not AuthService.Authenticated) or AuthService.TokenExpired then
    DisplayLoginPage
  else
    DisplayMainView;
end;

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TMainData, MainData);
  Application.Run;
  MainData.InitApp(@StartApplication, @UnauthorizedAccessProc)
end.
