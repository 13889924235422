unit MainForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.ExtCtrls,
  App.Types,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Toast,
  BaseCoreForm,
  SupportForm;

type
  TMainPage = class(TForm)
    DashboardLabel: TLabel;
    UserAdminLabel: TLabel;
    LoggedInUserLabel: TLabel;
    LogoutLabel: TLabel;
    UserScopeLabel: TLabel;
    LocationTypeLabel: TLabel;
    JobsDataset: TXDataWebDataSet;
    JobsDatasetId: TIntegerField;
    JobsDatasetJobType: TStringField;
    JobsDatasetSubmittedBy: TIntegerField;
    JobsDatasetDateSubmitted: TDateTimeField;
    JobsDatasetStatus: TStringField;
    JobsDatasetTimeTaken: TFloatField;
    JobsDatasetDateRun: TDateTimeField;
    JobsDatasetVisibility: TStringField;
    JobsDatasetData: TStringField;
    JobsDatasetOutputOption: TStringField;
    JobsDatasetCompletedBy: TIntegerField;
    JobsDatasetOwnedBy: TIntegerField;
    JobsDatasetTitle: TStringField;
    JobsDatasetNotifyOnCompletion: TBooleanField;
    JobsDatasetDateCompleted: TDateTimeField;
    WebTimer1: TTimer;
    MainToast: TToast;
    UpdateMe: TLabel;
    MainMessageDlg: TMessageDlg;
    JobsDatasetHasErrors: TBooleanField;
    JobsDatasetStats: TStringField;
    JobsDatasetDownloads: TStringField;
    JobsDatasetProgress: TFloatField;
    JobsDatasetRunLevel: TStringField;
    ExceptionTestLink: TLabel;
    SysConfigLink: TLabel;
    TTL: TLabel;
    TTLTimer: TTimer;
    SupportLink: TLabel;
    StopTimersCheckbox: TCheckBox;
    JobsDatasetJobCategory: TStringField;
    JobsDatasetLastPolled: TDateTimeField;
    JobsDatasetPollInterval: TLargeintField;
    JobsDatasetPollCount: TIntegerField;
    ImportLink: TLabel;
    NewDonorsLabel: TLabel;
    DonorAdminLabel: TLabel;
    GiftAidLink: TLabel;
    UnclaimedDonationsLink: TLabel;
    MakeAClaimLink: TLabel;
    GAConfigLink: TLabel;
    procedure WebFormCreate(Sender: TObject);
    procedure DashboardLabelClick(Sender: TObject);
    procedure DonorAdminLabelClick(Sender: TObject);
    procedure ExceptionTestLinkClick(Sender: TObject);
    procedure GAConfigLinkClick(Sender: TObject);
    procedure GiftAidLinkClick(Sender: TObject);
    procedure ImportLinkClick(Sender: TObject);
    procedure JobsDatasetAfterOpen(Dataset: TDataSet);
    procedure JobsDatasetAfterPost(Dataset: TDataSet);
    procedure LogoutLabelClick(Sender: TObject);
    procedure MakeAClaimLinkClick(Sender: TObject);
    procedure NewDonorsLabelClick(Sender: TObject);
    procedure SupportLinkClick(Sender: TObject);
    procedure SysConfigLinkClick(Sender: TObject);
    procedure TTLClick(Sender: TObject);
    procedure TTLTimerTimer(Sender: TObject);
    procedure UnclaimedDonationsLinkClick(Sender: TObject);
    procedure UpdateMeClick(Sender: TObject);
    procedure UserAdminLabelClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebTimer1Timer(Sender: TObject);
  private
    { Private declarations }
    FLogoutProc: TLogoutProc;
    FDisplayedForm: TForm;
    FJobsLocked: Boolean;
    FJobsUpdating: Boolean;
    FLastCompletedDate: TDateTime;
    FTTLShow: Int64;
    FTTLHighlight: Int64;
    FErrorSupportForm: TSupportPage;
    procedure OnFormSelfClose(Sender: TObject);
    procedure ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc = nil);
    function CloseDisplayedForm: Boolean;
    [async]
    procedure ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc = nil); async;
    procedure ShowDashboard;
    procedure LoadJobs;
    procedure SetLinksAuth;
    procedure OnAuthUpdated(Sendr: TObject);
    procedure UpdateTTL;
    procedure ToggleTimers(const AEnabled: Boolean);
    [async]
    procedure MakeAClaim; async;
  public
    { Public declarations }
    class procedure Display(const AElementId: string; LogoutProc: TLogoutProc);
    procedure SetLabels;
    procedure LockJobsData;
    procedure UnlockJobsData(const ARefresh: Boolean = False);
    procedure RefreshJobs;
    procedure DisplayMessage(const AMessage: string; DlgType: TMsgDlgType = mtInformation);
    [async]
    procedure ErrorHandler(Value: TApplicationError; var AHandled: Boolean); async;
    procedure ShowAToast(const ATitle, AMessage, AImage: string);
    procedure ShowAnAwesomeToast(const ATitle, AMessage, AIcon: string);
    [async]
    procedure ReLogin(AMessage: string); async;
  protected procedure LoadDFMValues; override; end;

var
  MainPage: TMainPage;

implementation

{$R *.dfm}

uses
  DashboardForm,
  Auth.Service,
  SMX.Shared,
  UserList,
  MainDataModule,
  System.DateUtils,
  App.Helper,
  SelfUpdateForm,
  SysConfigForm,
  GAConfigForm,
  App.Utils,
  LoginForm,
  ImportForm,
  DonorList,
  UserForm,
  XData.Web.Client,
  GiftAidClaimsList,
  DonationsForm, GiftAidClaimForm, ImportList;

procedure TMainPage.WebFormCreate(Sender: TObject);
var
  lAdmin, lUserId: string;
begin
  FTTLShow := 15 * 60;
  FTTLHighlight := 3 * 60;
  FLastCompletedDate := Now;
  MainData.ShowMessageProc := DisplayMessage;
  MainData.OnAuthUpdatedProc := OnAuthUpdated;
  MainData.ErrorProc := ErrorHandler;

  JobsDataset.DisableControls;

  LoadJobs;

  ShowDashboard;
end;

function TMainPage.CloseDisplayedForm: Boolean;
begin
  Result := True;
  if Assigned(FDisplayedForm) then
  begin
    if FDisplayedForm.ClassType.InheritsFrom(TCoreWebForm) then
      Result := TCoreWebForm(FDisplayedForm).CanClose;
    if not Result then
      Exit;
    FDisplayedForm.Free;
    FDisplayedForm := nil;
  end;
end;

procedure TMainPage.DashboardLabelClick(Sender: TObject);
begin
  ShowDashboard;
end;

class procedure TMainPage.Display(const AElementId: string; LogoutProc: TLogoutProc);

  procedure AfterCreate(AForm: TObject);
  begin
    TMainPage(AForm).FLogoutProc := LogoutProc;
    TMainPage(AForm).SetLabels;
    TMainPage(AForm).SetLinksAuth;
  end;

begin
  if not Assigned(MainPage) then
  begin
    MainPage := TMainPage.CreateNew(AElementId, @AfterCreate)
  end;
end;

procedure TMainPage.DisplayMessage(const AMessage: string; DlgType: TMsgDlgType);
begin
  MainMessageDlg.ShowDialog(AMessage, DlgType, [mbOK]);
end;

procedure TMainPage.DonorAdminLabelClick(Sender: TObject);
begin
  ShowForm(TDonorListForm);
end;

procedure TMainPage.ErrorHandler(Value: TApplicationError; var AHandled: Boolean);
var
  lRetval: TModalResult;
begin
  if Assigned(FErrorSupportForm) then
    Exit;
  ToggleTimers(False);
  FErrorSupportForm := TSupportPage.Create(Self);
  try
    FErrorSupportForm.Popup := True;
    FErrorSupportForm.PopupOpacity := 1;
    await(TForm, FErrorSupportForm.Load());
    FErrorSupportForm.ErrorData := Value;
    lRetval := await(TModalResult, FErrorSupportForm.Execute);
    AHandled := (lRetval = mrOK);
    if AHandled then
      DisplayMessage('Thank you. This has been sent to the developers')
    else
      DisplayMessage('The error has been sent to the developers');
  finally
    FErrorSupportForm.Free;
    FErrorSupportForm := nil;
  end;
  ToggleTimers(True);
end;

procedure TMainPage.ExceptionTestLinkClick(Sender: TObject);
begin
  raise Exception.Create('This is a test exception');
end;

procedure TMainPage.GAConfigLinkClick(Sender: TObject);
begin
    ShowPopUp(TGAConfig);
end;

procedure TMainPage.GiftAidLinkClick(Sender: TObject);
begin
  ShowForm(TGiftAidClaims);
end;

procedure TMainPage.ImportLinkClick(Sender: TObject);
begin
  ShowForm(TImportListForm);
end;

procedure TMainPage.JobsDatasetAfterOpen(Dataset: TDataSet);
begin
  JobsDataset.EnableControls;
  if (FDisplayedForm is TDashboard) then
    TDashboard(FDisplayedForm).RefreshJobsList;
  FJobsLocked := False;
  WebTimer1.Enabled := True;
end;

procedure TMainPage.JobsDatasetAfterPost(Dataset: TDataSet);
begin
  if not FJobsUpdating then
    JobsDataset.ApplyUpdates;
end;

procedure TMainPage.LoadJobs;
begin
  FJobsLocked := True;
  JobsDataset.DisableControls;
  JobsDataset.Close;
  JobsDataset.Load;
end;

procedure TMainPage.LockJobsData;
begin
  WebTimer1.Enabled := False;
  FJobsLocked := True;
end;

procedure TMainPage.LogoutLabelClick(Sender: TObject);
begin
  FLogoutProc('', True);
end;

procedure TMainPage.MakeAClaim;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  lCount: Integer;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderService.UnclaimedDonations', []));
  lResult := TJSObject(lRetval.Result);
  lCount := JS.toInteger(lResult['value']);

  if lCount = 0 then
  begin
    ShowMessage('There are no donations that are unclaimed');
    Exit;
  end
  else
    ShowPopUp(TGiftAidClaimJob);
end;

procedure TMainPage.MakeAClaimLinkClick(Sender: TObject);
begin
  MakeAClaim;
end;

procedure TMainPage.NewDonorsLabelClick(Sender: TObject);
begin
  // ShowForm(TAddDonorsForm);
end;

procedure TMainPage.OnAuthUpdated(Sendr: TObject);
begin
  UpdateTTL;
end;

procedure TMainPage.OnFormSelfClose(Sender: TObject);
begin
  ShowDashboard;
end;

procedure TMainPage.RefreshJobs;
begin
  LoadJobs;
end;

procedure TMainPage.ReLogin(AMessage: string);
var
  lRetval: TModalResult;
  lW, lH: Integer;
begin
  if Assigned(LoginPage) then
    Exit;
  ToggleTimers(False);

  AuthService.Logout(False);
  MainData.DataConnection.Connected := False;
  // LoginPage := TLoginPage.CreateNew('LoginAgainForm.html');
  LoginPage := TLoginPage.Create(Self);
  try
    LoginPage.Popup := True;
    LoginPage.PopupOpacity := 1;
    LoginPage.IsModalPopUp := True;
    await(TForm, LoginPage.Load());

    LoginPage.AMessage := AMessage;
    lRetval := await(TModalResult, LoginPage.Execute);
    if lRetval = mrOK then
    begin
      ToggleTimers(True);
    end;

  finally
    LoginPage.Free;
    LoginPage := nil;
  end;
end;

procedure TMainPage.SetLabels;
begin
  LoggedInUserLabel.Caption := AuthService.ClaimValue(CLAIM_FULLNAME);
  UserScopeLabel.Caption := AuthService.UserScope;
  LocationTypeLabel.Caption := AuthService.LocationType;
end;

procedure TMainPage.SetLinksAuth;

  procedure SetLabelVisibility(const ALabel: TLabel; AVisible: Boolean);
  begin
    ALabel.Visible := AVisible;
    ALabel.Enabled := AVisible;
  end;

var
  lIsAdministrator: Boolean;
  lIsAdminUserOrBetter: Boolean;
begin
  lIsAdministrator := AuthService.IsAdministrator;
  lIsAdminUserOrBetter := AuthService.IsAdminUserOrBetter;

  SetLabelVisibility(UserAdminLabel, lIsAdminUserOrBetter);

  SetLabelVisibility(SysConfigLink, False); // lIsAdministrator);

  SetLabelVisibility(ExceptionTestLink, AuthService.LocationType = 'System');
{$IFDEF RELEASE}
  StopTimersCheckbox.Visible := False;
{$ELSE}
  StopTimersCheckbox.Visible := (AuthService.LocationType = 'System');
{$ENDIF}
end;

procedure TMainPage.ShowAnAwesomeToast(const ATitle, AMessage, AIcon: string);
const
  MESSAGE_TEMPLATE = '<span class="ToastMessage"><i class="%s fa-fw fa-lg"></i>%s</span>';
var
  lToast: TToastItem;
begin
  lToast := MainToast.Items.Add(ATitle, Format(MESSAGE_TEMPLATE, [AIcon, AMessage]));
  lToast.Show;
end;

procedure TMainPage.ShowAToast(const ATitle, AMessage, AImage: string);
const
  MESSAGE_TEMPLATE = '<span class="ToastMessage">%s</span>';
var
  lMessage: string;
  lToast: TToastItem;
begin
  if AImage = '' then
    lMessage := AMessage
  else
    lMessage := Format('<img src="images/%s"> %s', [AImage, AMessage]);
  lToast := MainToast.Items.Add(ATitle, Format(MESSAGE_TEMPLATE, [lMessage]));
  lToast.Show;
end;

procedure TMainPage.ShowDashboard;

  procedure DashboardCreated(AForm: TObject);
  begin
    TDashboard(AForm).JobsDataset := JobsDataset;
  end;

begin
  ShowForm(TDashboard, @DashboardCreated);
end;

procedure TMainPage.ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc);

  procedure AfterFormCreated(AForm: TObject);
  begin

    if AFormClass.InheritsFrom(TCoreWebForm) then
      TCoreWebForm(AForm).OnSelfClose := OnFormSelfClose;

    if Assigned(AProc) then
      AProc(AForm);

    if AFormClass.InheritsFrom(TCoreWebForm) then
       TCoreWebForm(AForm).AfterCreated;

  end;

begin
  // Note that MainPanel is no longer a component. This now just uses a div on the
  // template with that id.
  if Assigned(FDisplayedForm) and (FDisplayedForm.ClassType = AFormClass) then
    Exit;

  if not CloseDisplayedForm then
    Exit;

  Application.CreateForm(AFormClass, 'MainPanel', FDisplayedForm, @AfterFormCreated)

end;

procedure TMainPage.ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc = nil);
var
  AForm: TForm;
  lRetval: TModalResult;
begin
  AForm := AFormClass.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TForm, AForm.Load());
    lRetval := await(TModalResult, AForm.Execute);
    if (lRetval = mrOK) and (OnSuccessProc <> nil) then
      OnSuccessProc();
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TMainPage.SupportLinkClick(Sender: TObject);
begin
  ShowPopUp(TSupportPage);
end;

procedure TMainPage.SysConfigLinkClick(Sender: TObject);
begin
  ShowPopUp(TSysConfig);
end;

procedure TMainPage.ToggleTimers(const AEnabled: Boolean);
begin
  WebTimer1.Enabled := AEnabled;
  TTLTimer.Enabled := AEnabled;
end;

procedure TMainPage.TTLClick(Sender: TObject);
begin
  ReLogin('');
end;

procedure TMainPage.TTLTimerTimer(Sender: TObject);
begin
  if StopTimersCheckbox.Checked then
    Exit;
  UpdateTTL;
end;

procedure TMainPage.UnclaimedDonationsLinkClick(Sender: TObject);

  procedure DonationsCreated(AForm: TObject);
  begin
    TDonationsList(AForm).ShowStatus := 'Unclaimed';
    TDonationsList(AForm).ShowCaption := 'Unclaimed Donations';
  end;

begin
  ShowForm(TDonationsList, @DonationsCreated);
end;

procedure TMainPage.UnlockJobsData(const ARefresh: Boolean);
begin
  if ARefresh then
    RefreshJobs
  else
  begin
    FJobsLocked := False;
    WebTimer1.Enabled := True;
  end;
end;

procedure TMainPage.UpdateMeClick(Sender: TObject);
begin
  ShowForm(TSelfUpdate);
end;

procedure TMainPage.UpdateTTL;

  procedure SetTTLTimer(const AInterval: Int64);
  begin
    if TTLTimer.Interval = AInterval then
      Exit;
    TTLTimer.Enabled := False;
    TTLTimer.Interval := AInterval;
    TTLTimer.Enabled := True;
  end;

var
  lExpiry: TDateTime;
  lTTL: Int64;
begin
  lExpiry := AuthService.TokenExpirationDate;
  if lExpiry <= Now then
  begin
    ReLogin('Token expired');
    Exit;
  end;

  lTTL := SecondsBetween(Now, lExpiry);

  if lTTL < FTTLShow then
  begin
    TTL.Caption := TSysUtils.SecondsAsTime(lTTL);
    if lTTL < FTTLHighlight then
      TTL.Font.Color := clRed
    else
      TTL.Font.Color := clWindowText;

    TTL.Visible := True;
    SetTTLTimer(1000);
  end
  else
  begin
    TTL.Visible := True;
    TTL.Caption := 'Lock Application';
    SetTTLTimer(60000);
  end;

  if not TTLTimer.Enabled then
    TTLTimer.Enabled := True;

end;

procedure TMainPage.UserAdminLabelClick(Sender: TObject);
begin
  ShowForm(TUserListForm);
end;

procedure TMainPage.WebFormShow(Sender: TObject);
begin
  UpdateTTL;
end;

procedure TMainPage.WebTimer1Timer(Sender: TObject);
begin
  if StopTimersCheckbox.Checked then
    Exit;
  { TODO : This should work both ways - prevent a jobs detail form opening until this is is complete }
  if FJobsLocked then
    Exit;
  LoadJobs;
end;

procedure TMainPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  UserAdminLabel := TLabel.Create('UsersAdminLink');
  DashboardLabel := TLabel.Create('DashboardLink');
  LoggedInUserLabel := TLabel.Create('UserNameLabel');
  LogoutLabel := TLabel.Create('LogoutLink');
  UserScopeLabel := TLabel.Create('UserScopeLabel');
  LocationTypeLabel := TLabel.Create('LocationTypeLabel');
  UpdateMe := TLabel.Create('UpdateMe');
  ExceptionTestLink := TLabel.Create('ExceptionTestLink');
  SysConfigLink := TLabel.Create('SysConfigLink');
  TTL := TLabel.Create('TTL');
  SupportLink := TLabel.Create('SupportLink');
  ImportLink := TLabel.Create('ImportLink');
  NewDonorsLabel := TLabel.Create('NewDonorsLink');
  DonorAdminLabel := TLabel.Create('DonorAdminLink');
  GiftAidLink := TLabel.Create('GiftAidLink');
  UnclaimedDonationsLink := TLabel.Create('UnclaimedDonationsLink');
  MakeAClaimLink := TLabel.Create('MakeAClaimLink');
  GAConfigLink := TLabel.Create('GAConfigLink');
  MainMessageDlg := TMessageDlg.Create(Self);
  StopTimersCheckbox := TCheckBox.Create('StopTimersCheckbox');
  JobsDataset := TXDataWebDataSet.Create(Self);
  JobsDatasetId := TIntegerField.Create(Self);
  JobsDatasetJobType := TStringField.Create(Self);
  JobsDatasetSubmittedBy := TIntegerField.Create(Self);
  JobsDatasetDateSubmitted := TDateTimeField.Create(Self);
  JobsDatasetStatus := TStringField.Create(Self);
  JobsDatasetTimeTaken := TFloatField.Create(Self);
  JobsDatasetDateRun := TDateTimeField.Create(Self);
  JobsDatasetVisibility := TStringField.Create(Self);
  JobsDatasetData := TStringField.Create(Self);
  JobsDatasetOutputOption := TStringField.Create(Self);
  JobsDatasetCompletedBy := TIntegerField.Create(Self);
  JobsDatasetOwnedBy := TIntegerField.Create(Self);
  JobsDatasetTitle := TStringField.Create(Self);
  JobsDatasetNotifyOnCompletion := TBooleanField.Create(Self);
  JobsDatasetDateCompleted := TDateTimeField.Create(Self);
  JobsDatasetProgress := TFloatField.Create(Self);
  JobsDatasetRunLevel := TStringField.Create(Self);
  JobsDatasetHasErrors := TBooleanField.Create(Self);
  JobsDatasetStats := TStringField.Create(Self);
  JobsDatasetDownloads := TStringField.Create(Self);
  JobsDatasetJobCategory := TStringField.Create(Self);
  JobsDatasetLastPolled := TDateTimeField.Create(Self);
  JobsDatasetPollInterval := TLargeintField.Create(Self);
  JobsDatasetPollCount := TIntegerField.Create(Self);
  WebTimer1 := TTimer.Create(Self);
  MainToast := TToast.Create(Self);
  TTLTimer := TTimer.Create(Self);

  UserAdminLabel.BeforeLoadDFMValues;
  DashboardLabel.BeforeLoadDFMValues;
  LoggedInUserLabel.BeforeLoadDFMValues;
  LogoutLabel.BeforeLoadDFMValues;
  UserScopeLabel.BeforeLoadDFMValues;
  LocationTypeLabel.BeforeLoadDFMValues;
  UpdateMe.BeforeLoadDFMValues;
  ExceptionTestLink.BeforeLoadDFMValues;
  SysConfigLink.BeforeLoadDFMValues;
  TTL.BeforeLoadDFMValues;
  SupportLink.BeforeLoadDFMValues;
  ImportLink.BeforeLoadDFMValues;
  NewDonorsLabel.BeforeLoadDFMValues;
  DonorAdminLabel.BeforeLoadDFMValues;
  GiftAidLink.BeforeLoadDFMValues;
  UnclaimedDonationsLink.BeforeLoadDFMValues;
  MakeAClaimLink.BeforeLoadDFMValues;
  GAConfigLink.BeforeLoadDFMValues;
  MainMessageDlg.BeforeLoadDFMValues;
  StopTimersCheckbox.BeforeLoadDFMValues;
  JobsDataset.BeforeLoadDFMValues;
  JobsDatasetId.BeforeLoadDFMValues;
  JobsDatasetJobType.BeforeLoadDFMValues;
  JobsDatasetSubmittedBy.BeforeLoadDFMValues;
  JobsDatasetDateSubmitted.BeforeLoadDFMValues;
  JobsDatasetStatus.BeforeLoadDFMValues;
  JobsDatasetTimeTaken.BeforeLoadDFMValues;
  JobsDatasetDateRun.BeforeLoadDFMValues;
  JobsDatasetVisibility.BeforeLoadDFMValues;
  JobsDatasetData.BeforeLoadDFMValues;
  JobsDatasetOutputOption.BeforeLoadDFMValues;
  JobsDatasetCompletedBy.BeforeLoadDFMValues;
  JobsDatasetOwnedBy.BeforeLoadDFMValues;
  JobsDatasetTitle.BeforeLoadDFMValues;
  JobsDatasetNotifyOnCompletion.BeforeLoadDFMValues;
  JobsDatasetDateCompleted.BeforeLoadDFMValues;
  JobsDatasetProgress.BeforeLoadDFMValues;
  JobsDatasetRunLevel.BeforeLoadDFMValues;
  JobsDatasetHasErrors.BeforeLoadDFMValues;
  JobsDatasetStats.BeforeLoadDFMValues;
  JobsDatasetDownloads.BeforeLoadDFMValues;
  JobsDatasetJobCategory.BeforeLoadDFMValues;
  JobsDatasetLastPolled.BeforeLoadDFMValues;
  JobsDatasetPollInterval.BeforeLoadDFMValues;
  JobsDatasetPollCount.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  MainToast.BeforeLoadDFMValues;
  TTLTimer.BeforeLoadDFMValues;
  try
    Name := 'MainPage';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    UserAdminLabel.SetParentComponent(Self);
    UserAdminLabel.Name := 'UserAdminLabel';
    UserAdminLabel.Left := 28;
    UserAdminLabel.Top := 29;
    UserAdminLabel.Width := 28;
    UserAdminLabel.Height := 15;
    UserAdminLabel.Cursor := crHandPoint;
    UserAdminLabel.Caption := 'Users';
    UserAdminLabel.ElementPosition := epIgnore;
    UserAdminLabel.HeightStyle := ssAuto;
    UserAdminLabel.HeightPercent := 100.000000000000000000;
    UserAdminLabel.WidthStyle := ssAuto;
    UserAdminLabel.WidthPercent := 100.000000000000000000;
    SetEvent(UserAdminLabel, Self, 'OnClick', 'UserAdminLabelClick');
    DashboardLabel.SetParentComponent(Self);
    DashboardLabel.Name := 'DashboardLabel';
    DashboardLabel.Left := 28;
    DashboardLabel.Top := 8;
    DashboardLabel.Width := 57;
    DashboardLabel.Height := 15;
    DashboardLabel.Cursor := crHandPoint;
    DashboardLabel.Caption := 'Dashboard';
    DashboardLabel.ElementPosition := epIgnore;
    DashboardLabel.HeightStyle := ssAuto;
    DashboardLabel.HeightPercent := 100.000000000000000000;
    DashboardLabel.WidthStyle := ssAuto;
    DashboardLabel.WidthPercent := 100.000000000000000000;
    SetEvent(DashboardLabel, Self, 'OnClick', 'DashboardLabelClick');
    LoggedInUserLabel.SetParentComponent(Self);
    LoggedInUserLabel.Name := 'LoggedInUserLabel';
    LoggedInUserLabel.Left := 16;
    LoggedInUserLabel.Top := 360;
    LoggedInUserLabel.Width := 101;
    LoggedInUserLabel.Height := 15;
    LoggedInUserLabel.Caption := 'LoggedInUserLabel';
    LoggedInUserLabel.ElementPosition := epIgnore;
    LoggedInUserLabel.HeightStyle := ssAuto;
    LoggedInUserLabel.HeightPercent := 100.000000000000000000;
    LoggedInUserLabel.HTMLType := tDIV;
    LoggedInUserLabel.WidthStyle := ssAuto;
    LoggedInUserLabel.WidthPercent := 100.000000000000000000;
    LogoutLabel.SetParentComponent(Self);
    LogoutLabel.Name := 'LogoutLabel';
    LogoutLabel.Left := 8;
    LogoutLabel.Top := 436;
    LogoutLabel.Width := 38;
    LogoutLabel.Height := 15;
    LogoutLabel.Cursor := crHandPoint;
    LogoutLabel.Caption := 'Logout';
    LogoutLabel.ElementPosition := epIgnore;
    LogoutLabel.HeightStyle := ssAuto;
    LogoutLabel.HeightPercent := 100.000000000000000000;
    LogoutLabel.HTMLType := tDIV;
    LogoutLabel.WidthStyle := ssAuto;
    LogoutLabel.WidthPercent := 100.000000000000000000;
    SetEvent(LogoutLabel, Self, 'OnClick', 'LogoutLabelClick');
    UserScopeLabel.SetParentComponent(Self);
    UserScopeLabel.Name := 'UserScopeLabel';
    UserScopeLabel.Left := 16;
    UserScopeLabel.Top := 376;
    UserScopeLabel.Width := 83;
    UserScopeLabel.Height := 15;
    UserScopeLabel.Caption := 'UserScopeLabel';
    UserScopeLabel.ElementPosition := epIgnore;
    UserScopeLabel.HeightStyle := ssAuto;
    UserScopeLabel.HeightPercent := 100.000000000000000000;
    UserScopeLabel.HTMLType := tDIV;
    UserScopeLabel.Visible := False;
    UserScopeLabel.WidthStyle := ssAuto;
    UserScopeLabel.WidthPercent := 100.000000000000000000;
    LocationTypeLabel.SetParentComponent(Self);
    LocationTypeLabel.Name := 'LocationTypeLabel';
    LocationTypeLabel.Left := 16;
    LocationTypeLabel.Top := 414;
    LocationTypeLabel.Width := 98;
    LocationTypeLabel.Height := 15;
    LocationTypeLabel.Caption := 'LocationTypeLabel';
    LocationTypeLabel.ElementPosition := epIgnore;
    LocationTypeLabel.HeightStyle := ssAuto;
    LocationTypeLabel.HeightPercent := 100.000000000000000000;
    LocationTypeLabel.HTMLType := tDIV;
    LocationTypeLabel.Visible := False;
    LocationTypeLabel.WidthStyle := ssAuto;
    LocationTypeLabel.WidthPercent := 100.000000000000000000;
    UpdateMe.SetParentComponent(Self);
    UpdateMe.Name := 'UpdateMe';
    UpdateMe.Left := 192;
    UpdateMe.Top := 8;
    UpdateMe.Width := 55;
    UpdateMe.Height := 15;
    UpdateMe.Caption := 'UpdateMe';
    UpdateMe.ElementFont := efCSS;
    UpdateMe.ElementPosition := epIgnore;
    UpdateMe.HeightStyle := ssAuto;
    UpdateMe.HeightPercent := 100.000000000000000000;
    UpdateMe.WidthStyle := ssAuto;
    UpdateMe.WidthPercent := 100.000000000000000000;
    SetEvent(UpdateMe, Self, 'OnClick', 'UpdateMeClick');
    ExceptionTestLink.SetParentComponent(Self);
    ExceptionTestLink.Name := 'ExceptionTestLink';
    ExceptionTestLink.Left := 189;
    ExceptionTestLink.Top := 80;
    ExceptionTestLink.Width := 75;
    ExceptionTestLink.Height := 15;
    ExceptionTestLink.Cursor := crHandPoint;
    ExceptionTestLink.Caption := 'Exception Test';
    ExceptionTestLink.ElementFont := efCSS;
    ExceptionTestLink.HeightStyle := ssAuto;
    ExceptionTestLink.HeightPercent := 100.000000000000000000;
    ExceptionTestLink.WidthPercent := 100.000000000000000000;
    SetEvent(ExceptionTestLink, Self, 'OnClick', 'ExceptionTestLinkClick');
    SysConfigLink.SetParentComponent(Self);
    SysConfigLink.Name := 'SysConfigLink';
    SysConfigLink.Left := 189;
    SysConfigLink.Top := 38;
    SysConfigLink.Width := 77;
    SysConfigLink.Height := 15;
    SysConfigLink.Cursor := crHandPoint;
    SysConfigLink.Caption := 'System Config';
    SysConfigLink.ElementFont := efCSS;
    SysConfigLink.HeightStyle := ssAuto;
    SysConfigLink.HeightPercent := 100.000000000000000000;
    SysConfigLink.WidthPercent := 100.000000000000000000;
    SetEvent(SysConfigLink, Self, 'OnClick', 'SysConfigLinkClick');
    TTL.SetParentComponent(Self);
    TTL.Name := 'TTL';
    TTL.Left := 488;
    TTL.Top := 456;
    TTL.Width := 89;
    TTL.Height := 15;
    TTL.Cursor := crHandPoint;
    TTL.Caption := 'Lock Application';
    TTL.ElementFont := efCSS;
    TTL.HeightStyle := ssAuto;
    TTL.HeightPercent := 100.000000000000000000;
    TTL.HTMLType := tSPAN;
    TTL.WidthPercent := 100.000000000000000000;
    SetEvent(TTL, Self, 'OnClick', 'TTLClick');
    SupportLink.SetParentComponent(Self);
    SupportLink.Name := 'SupportLink';
    SupportLink.Left := 192;
    SupportLink.Top := 120;
    SupportLink.Width := 42;
    SupportLink.Height := 15;
    SupportLink.Caption := 'Support';
    SupportLink.ElementFont := efCSS;
    SupportLink.ElementPosition := epIgnore;
    SupportLink.HeightStyle := ssAuto;
    SupportLink.HeightPercent := 100.000000000000000000;
    SupportLink.WidthStyle := ssAuto;
    SupportLink.WidthPercent := 100.000000000000000000;
    SetEvent(SupportLink, Self, 'OnClick', 'SupportLinkClick');
    ImportLink.SetParentComponent(Self);
    ImportLink.Name := 'ImportLink';
    ImportLink.Left := 28;
    ImportLink.Top := 50;
    ImportLink.Width := 36;
    ImportLink.Height := 15;
    ImportLink.Cursor := crHandPoint;
    ImportLink.Caption := 'Import';
    ImportLink.ElementFont := efCSS;
    ImportLink.HeightStyle := ssAuto;
    ImportLink.HeightPercent := 100.000000000000000000;
    ImportLink.WidthPercent := 100.000000000000000000;
    SetEvent(ImportLink, Self, 'OnClick', 'ImportLinkClick');
    NewDonorsLabel.SetParentComponent(Self);
    NewDonorsLabel.Name := 'NewDonorsLabel';
    NewDonorsLabel.Left := 28;
    NewDonorsLabel.Top := 152;
    NewDonorsLabel.Width := 65;
    NewDonorsLabel.Height := 15;
    NewDonorsLabel.Cursor := crHandPoint;
    NewDonorsLabel.Caption := 'New Donors';
    NewDonorsLabel.ElementFont := efCSS;
    NewDonorsLabel.HeightStyle := ssAuto;
    NewDonorsLabel.HeightPercent := 100.000000000000000000;
    NewDonorsLabel.Visible := False;
    NewDonorsLabel.WidthPercent := 100.000000000000000000;
    SetEvent(NewDonorsLabel, Self, 'OnClick', 'NewDonorsLabelClick');
    DonorAdminLabel.SetParentComponent(Self);
    DonorAdminLabel.Name := 'DonorAdminLabel';
    DonorAdminLabel.Left := 28;
    DonorAdminLabel.Top := 120;
    DonorAdminLabel.Width := 38;
    DonorAdminLabel.Height := 15;
    DonorAdminLabel.Cursor := crHandPoint;
    DonorAdminLabel.Caption := 'Donors';
    DonorAdminLabel.ElementFont := efCSS;
    DonorAdminLabel.ElementPosition := epIgnore;
    DonorAdminLabel.HeightStyle := ssAuto;
    DonorAdminLabel.HeightPercent := 100.000000000000000000;
    DonorAdminLabel.WidthStyle := ssAuto;
    DonorAdminLabel.WidthPercent := 100.000000000000000000;
    SetEvent(DonorAdminLabel, Self, 'OnClick', 'DonorAdminLabelClick');
    GiftAidLink.SetParentComponent(Self);
    GiftAidLink.Name := 'GiftAidLink';
    GiftAidLink.Left := 28;
    GiftAidLink.Top := 201;
    GiftAidLink.Width := 36;
    GiftAidLink.Height := 15;
    GiftAidLink.Cursor := crHandPoint;
    GiftAidLink.Caption := 'Claims';
    GiftAidLink.ElementFont := efCSS;
    GiftAidLink.HeightStyle := ssAuto;
    GiftAidLink.HeightPercent := 100.000000000000000000;
    GiftAidLink.WidthStyle := ssAuto;
    GiftAidLink.WidthPercent := 100.000000000000000000;
    SetEvent(GiftAidLink, Self, 'OnClick', 'GiftAidLinkClick');
    UnclaimedDonationsLink.SetParentComponent(Self);
    UnclaimedDonationsLink.Name := 'UnclaimedDonationsLink';
    UnclaimedDonationsLink.Left := 30;
    UnclaimedDonationsLink.Top := 233;
    UnclaimedDonationsLink.Width := 114;
    UnclaimedDonationsLink.Height := 15;
    UnclaimedDonationsLink.Cursor := crHandPoint;
    UnclaimedDonationsLink.Caption := 'Unclaimed Donations';
    UnclaimedDonationsLink.ElementFont := efCSS;
    UnclaimedDonationsLink.HeightStyle := ssAuto;
    UnclaimedDonationsLink.HeightPercent := 100.000000000000000000;
    UnclaimedDonationsLink.WidthStyle := ssAuto;
    UnclaimedDonationsLink.WidthPercent := 100.000000000000000000;
    SetEvent(UnclaimedDonationsLink, Self, 'OnClick', 'UnclaimedDonationsLinkClick');
    MakeAClaimLink.SetParentComponent(Self);
    MakeAClaimLink.Name := 'MakeAClaimLink';
    MakeAClaimLink.Left := 30;
    MakeAClaimLink.Top := 265;
    MakeAClaimLink.Width := 72;
    MakeAClaimLink.Height := 15;
    MakeAClaimLink.Cursor := crHandPoint;
    MakeAClaimLink.Caption := 'Make a Claim';
    MakeAClaimLink.ElementFont := efCSS;
    MakeAClaimLink.HeightStyle := ssAuto;
    MakeAClaimLink.HeightPercent := 100.000000000000000000;
    MakeAClaimLink.WidthStyle := ssAuto;
    MakeAClaimLink.WidthPercent := 100.000000000000000000;
    SetEvent(MakeAClaimLink, Self, 'OnClick', 'MakeAClaimLinkClick');
    GAConfigLink.SetParentComponent(Self);
    GAConfigLink.Name := 'GAConfigLink';
    GAConfigLink.Left := 189;
    GAConfigLink.Top := 59;
    GAConfigLink.Width := 76;
    GAConfigLink.Height := 15;
    GAConfigLink.Cursor := crHandPoint;
    GAConfigLink.Caption := 'GiftAid Config';
    GAConfigLink.ElementFont := efCSS;
    GAConfigLink.HeightStyle := ssAuto;
    GAConfigLink.HeightPercent := 100.000000000000000000;
    GAConfigLink.WidthPercent := 100.000000000000000000;
    SetEvent(GAConfigLink, Self, 'OnClick', 'GAConfigLinkClick');
    MainMessageDlg.SetParentComponent(Self);
    MainMessageDlg.Name := 'MainMessageDlg';
    MainMessageDlg.Left := 472;
    MainMessageDlg.Top := 336;
    MainMessageDlg.Width := 24;
    MainMessageDlg.Height := 24;
    MainMessageDlg.Buttons := [];
    MainMessageDlg.Opacity := 0.200000000000000000;
    MainMessageDlg.ElementButtonClassName := 'btn';
    MainMessageDlg.ElementDialogClassName := 'shadow-lg mb-5 bg-white rounded';
    MainMessageDlg.ElementTitleClassName := 'text-body';
    MainMessageDlg.ElementContentClassName := 'text-body';
    StopTimersCheckbox.SetParentComponent(Self);
    StopTimersCheckbox.Name := 'StopTimersCheckbox';
    StopTimersCheckbox.Left := 296;
    StopTimersCheckbox.Top := 8;
    StopTimersCheckbox.Width := 113;
    StopTimersCheckbox.Height := 22;
    StopTimersCheckbox.Caption := 'Stop timers';
    StopTimersCheckbox.ChildOrder := 27;
    StopTimersCheckbox.Color := clNone;
    StopTimersCheckbox.ElementClassName := 'form-control';
    StopTimersCheckbox.ElementLabelClassName := 'custom-control-label';
    StopTimersCheckbox.ElementFont := efCSS;
    StopTimersCheckbox.HeightStyle := ssAuto;
    StopTimersCheckbox.HeightPercent := 100.000000000000000000;
    StopTimersCheckbox.State := cbUnchecked;
    StopTimersCheckbox.WidthPercent := 100.000000000000000000;
    JobsDataset.SetParentComponent(Self);
    JobsDataset.Name := 'JobsDataset';
    JobsDataset.AfterOpen := JobsDatasetAfterOpen;
    JobsDataset.AfterPost := JobsDatasetAfterPost;
    JobsDataset.EntitySetName := 'Job';
    JobsDataset.Connection := MainData.DataConnection;
    JobsDataset.Left := 480;
    JobsDataset.Top := 104;
    JobsDatasetId.SetParentComponent(JobsDataset);
    JobsDatasetId.Name := 'JobsDatasetId';
    JobsDatasetId.FieldName := 'Id';
    JobsDatasetJobType.SetParentComponent(JobsDataset);
    JobsDatasetJobType.Name := 'JobsDatasetJobType';
    JobsDatasetJobType.FieldName := 'JobType';
    JobsDatasetJobType.Size := 25;
    JobsDatasetSubmittedBy.SetParentComponent(JobsDataset);
    JobsDatasetSubmittedBy.Name := 'JobsDatasetSubmittedBy';
    JobsDatasetSubmittedBy.FieldName := 'SubmittedBy';
    JobsDatasetDateSubmitted.SetParentComponent(JobsDataset);
    JobsDatasetDateSubmitted.Name := 'JobsDatasetDateSubmitted';
    JobsDatasetDateSubmitted.FieldName := 'DateSubmitted';
    JobsDatasetDateSubmitted.DisplayFormat := 'dd-mmm-yyyy hh:nn';
    JobsDatasetStatus.SetParentComponent(JobsDataset);
    JobsDatasetStatus.Name := 'JobsDatasetStatus';
    JobsDatasetStatus.FieldName := 'Status';
    JobsDatasetStatus.Size := 10;
    JobsDatasetTimeTaken.SetParentComponent(JobsDataset);
    JobsDatasetTimeTaken.Name := 'JobsDatasetTimeTaken';
    JobsDatasetTimeTaken.FieldName := 'TimeTaken';
    JobsDatasetDateRun.SetParentComponent(JobsDataset);
    JobsDatasetDateRun.Name := 'JobsDatasetDateRun';
    JobsDatasetDateRun.FieldName := 'DateRun';
    JobsDatasetDateRun.DisplayFormat := 'dd-mmm-yyyy hh:nn';
    JobsDatasetVisibility.SetParentComponent(JobsDataset);
    JobsDatasetVisibility.Name := 'JobsDatasetVisibility';
    JobsDatasetVisibility.FieldName := 'Visibility';
    JobsDatasetVisibility.Size := 9;
    JobsDatasetData.SetParentComponent(JobsDataset);
    JobsDatasetData.Name := 'JobsDatasetData';
    JobsDatasetData.FieldName := 'Data';
    JobsDatasetData.Size := 4096;
    JobsDatasetOutputOption.SetParentComponent(JobsDataset);
    JobsDatasetOutputOption.Name := 'JobsDatasetOutputOption';
    JobsDatasetOutputOption.FieldName := 'OutputOption';
    JobsDatasetOutputOption.Size := 50;
    JobsDatasetCompletedBy.SetParentComponent(JobsDataset);
    JobsDatasetCompletedBy.Name := 'JobsDatasetCompletedBy';
    JobsDatasetCompletedBy.FieldName := 'CompletedBy';
    JobsDatasetOwnedBy.SetParentComponent(JobsDataset);
    JobsDatasetOwnedBy.Name := 'JobsDatasetOwnedBy';
    JobsDatasetOwnedBy.FieldName := 'OwnedBy';
    JobsDatasetTitle.SetParentComponent(JobsDataset);
    JobsDatasetTitle.Name := 'JobsDatasetTitle';
    JobsDatasetTitle.FieldName := 'Title';
    JobsDatasetTitle.Size := 255;
    JobsDatasetNotifyOnCompletion.SetParentComponent(JobsDataset);
    JobsDatasetNotifyOnCompletion.Name := 'JobsDatasetNotifyOnCompletion';
    JobsDatasetNotifyOnCompletion.FieldName := 'NotifyOnCompletion';
    JobsDatasetDateCompleted.SetParentComponent(JobsDataset);
    JobsDatasetDateCompleted.Name := 'JobsDatasetDateCompleted';
    JobsDatasetDateCompleted.FieldName := 'DateCompleted';
    JobsDatasetDateCompleted.DisplayFormat := 'dd-mmm-yyyy hh:nn';
    JobsDatasetProgress.SetParentComponent(JobsDataset);
    JobsDatasetProgress.Name := 'JobsDatasetProgress';
    JobsDatasetProgress.FieldName := 'Progress';
    JobsDatasetRunLevel.SetParentComponent(JobsDataset);
    JobsDatasetRunLevel.Name := 'JobsDatasetRunLevel';
    JobsDatasetRunLevel.FieldName := 'RunLevel';
    JobsDatasetRunLevel.Size := 10;
    JobsDatasetHasErrors.SetParentComponent(JobsDataset);
    JobsDatasetHasErrors.Name := 'JobsDatasetHasErrors';
    JobsDatasetHasErrors.FieldName := 'HasErrors';
    JobsDatasetStats.SetParentComponent(JobsDataset);
    JobsDatasetStats.Name := 'JobsDatasetStats';
    JobsDatasetStats.FieldName := 'Stats';
    JobsDatasetStats.Size := 4096;
    JobsDatasetDownloads.SetParentComponent(JobsDataset);
    JobsDatasetDownloads.Name := 'JobsDatasetDownloads';
    JobsDatasetDownloads.FieldName := 'Downloads';
    JobsDatasetDownloads.Size := 4096;
    JobsDatasetJobCategory.SetParentComponent(JobsDataset);
    JobsDatasetJobCategory.Name := 'JobsDatasetJobCategory';
    JobsDatasetJobCategory.FieldName := 'JobCategory';
    JobsDatasetJobCategory.Size := 8;
    JobsDatasetLastPolled.SetParentComponent(JobsDataset);
    JobsDatasetLastPolled.Name := 'JobsDatasetLastPolled';
    JobsDatasetLastPolled.FieldName := 'LastPolled';
    JobsDatasetPollInterval.SetParentComponent(JobsDataset);
    JobsDatasetPollInterval.Name := 'JobsDatasetPollInterval';
    JobsDatasetPollInterval.FieldName := 'PollInterval';
    JobsDatasetPollCount.SetParentComponent(JobsDataset);
    JobsDatasetPollCount.Name := 'JobsDatasetPollCount';
    JobsDatasetPollCount.FieldName := 'PollCount';
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 30000;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 472;
    WebTimer1.Top := 176;
    MainToast.SetParentComponent(Self);
    MainToast.Name := 'MainToast';
    MainToast.AutoHideDelay := 3000;
    MainToast.Position := tpBottomRight;
    MainToast.Left := 472;
    MainToast.Top := 264;
    TTLTimer.SetParentComponent(Self);
    TTLTimer.Name := 'TTLTimer';
    TTLTimer.Enabled := False;
    SetEvent(TTLTimer, Self, 'OnTimer', 'TTLTimerTimer');
    TTLTimer.Left := 560;
    TTLTimer.Top := 176;
  finally
    UserAdminLabel.AfterLoadDFMValues;
    DashboardLabel.AfterLoadDFMValues;
    LoggedInUserLabel.AfterLoadDFMValues;
    LogoutLabel.AfterLoadDFMValues;
    UserScopeLabel.AfterLoadDFMValues;
    LocationTypeLabel.AfterLoadDFMValues;
    UpdateMe.AfterLoadDFMValues;
    ExceptionTestLink.AfterLoadDFMValues;
    SysConfigLink.AfterLoadDFMValues;
    TTL.AfterLoadDFMValues;
    SupportLink.AfterLoadDFMValues;
    ImportLink.AfterLoadDFMValues;
    NewDonorsLabel.AfterLoadDFMValues;
    DonorAdminLabel.AfterLoadDFMValues;
    GiftAidLink.AfterLoadDFMValues;
    UnclaimedDonationsLink.AfterLoadDFMValues;
    MakeAClaimLink.AfterLoadDFMValues;
    GAConfigLink.AfterLoadDFMValues;
    MainMessageDlg.AfterLoadDFMValues;
    StopTimersCheckbox.AfterLoadDFMValues;
    JobsDataset.AfterLoadDFMValues;
    JobsDatasetId.AfterLoadDFMValues;
    JobsDatasetJobType.AfterLoadDFMValues;
    JobsDatasetSubmittedBy.AfterLoadDFMValues;
    JobsDatasetDateSubmitted.AfterLoadDFMValues;
    JobsDatasetStatus.AfterLoadDFMValues;
    JobsDatasetTimeTaken.AfterLoadDFMValues;
    JobsDatasetDateRun.AfterLoadDFMValues;
    JobsDatasetVisibility.AfterLoadDFMValues;
    JobsDatasetData.AfterLoadDFMValues;
    JobsDatasetOutputOption.AfterLoadDFMValues;
    JobsDatasetCompletedBy.AfterLoadDFMValues;
    JobsDatasetOwnedBy.AfterLoadDFMValues;
    JobsDatasetTitle.AfterLoadDFMValues;
    JobsDatasetNotifyOnCompletion.AfterLoadDFMValues;
    JobsDatasetDateCompleted.AfterLoadDFMValues;
    JobsDatasetProgress.AfterLoadDFMValues;
    JobsDatasetRunLevel.AfterLoadDFMValues;
    JobsDatasetHasErrors.AfterLoadDFMValues;
    JobsDatasetStats.AfterLoadDFMValues;
    JobsDatasetDownloads.AfterLoadDFMValues;
    JobsDatasetJobCategory.AfterLoadDFMValues;
    JobsDatasetLastPolled.AfterLoadDFMValues;
    JobsDatasetPollInterval.AfterLoadDFMValues;
    JobsDatasetPollCount.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
    MainToast.AfterLoadDFMValues;
    TTLTimer.AfterLoadDFMValues;
  end;
end;

end.
