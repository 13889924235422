unit DonorList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.Lists,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  WEBLib.WebCtrls,
  WEBLib.Toast,
  XData.Web.Client,
  App.Utils,
  SMX.Shared, Vcl.Controls;

type
  TDonorListForm = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetTitle: TStringField;
    MainDatasetFirstName: TStringField;
    MainDatasetLastName: TStringField;
    MainDatasetAdd1: TStringField;
    MainDatasetAdd2: TStringField;
    MainDatasetAdd3: TStringField;
    MainDatasetCity: TStringField;
    MainDatasetPostCode: TStringField;
    MainDatasetEmail: TStringField;
    MainDatasetMobile: TStringField;
    MainDatasetTelephone: TStringField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetComments: TStringField;
    MainDatasetDateSigned: TDateField;
    MainDatasetContactByPhone: TBooleanField;
    MainDatasetContactByPost: TBooleanField;
    MainDatasetContactByText: TBooleanField;
    MainDatasetContactByEmail: TBooleanField;
    MainDatasetMatchHash: TStringField;
    MainDatasetMasterId: TIntegerField;
    AdvancedSearchButton: TButton;
    MainDatasetClaimStatusText: TStringField;
    MainDatasetFullName: TStringField;
    MainDatasetFullAddress: TStringField;
    MainDatasetMiddleName: TStringField;
    MainDatasetSuffix: TStringField;
    MainDatasetDOB: TDateField;
    MainDatasetHearingStatus: TStringField;
    MainDatasetEmailStatus: TStringField;
    MainDatasetLatitude: TFloatField;
    MainDatasetLongitude: TFloatField;
    MainDatasetExternalId: TStringField;
    MainDatasetNotUK: TBooleanField;
    MainDatasetCountry: TStringField;
    MainDatasetCountryCode: TStringField;
    procedure AdvancedSearchButtonClick(Sender: TObject);
    procedure MainDatasetCalcFields(Dataset: TDataSet);
    procedure MainDatasetClaimStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure WebFormCreate(Sender: TObject);
  private
    FBuildingQuery: Boolean;
    [async]
    procedure ShowDonationsRecord(const ARecordId: Integer); async;
    [async]
    procedure DoAdvancedSearch; async;
    { Private declarations }
  protected
    procedure PrepareForm; override;
    procedure LoadData; override;
    function RecordViewOption: TViewOption; override;
    function GetEditFormClass: TFormClass; override;
    procedure SearchData(const Value: string); override;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  DonorListForm: TDonorListForm;

implementation

uses
  MainDataModule,
  DonorForm,
  Grid.Settings,
  DonorBaseViewForm,
  AdvancedSearchForm, App.Layout.Utils, Contact.Utils, DonationList;

{$R *.dfm}

procedure TDonorListForm.WebFormCreate(Sender: TObject);
begin
  inherited;
end;

{ TDonorListForm }

procedure TDonorListForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure SalesClick(Sender: TObject);
  begin
    ShowDonationsRecord(ARecordId.ToInteger);
  end;

var
  Span, RefundSpan: THTMLSpan;
begin
  inherited;

  if RecordViewOption <> voNone then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-pound-sign');
    Span.OnClick := @SalesClick;
  end;

end;

procedure TDonorListForm.AdvancedSearchButtonClick(Sender: TObject);
begin
  DoAdvancedSearch;
end;

procedure TDonorListForm.DoAdvancedSearch;
var
  AForm: TAdvancedSearch;
  lRetval: TModalResult;
  lQuery: string;
begin
  AForm := TAdvancedSearch.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TAdvancedSearch, AForm.Load());
    lRetval := await(TModalResult, AForm.Execute);
    if lRetval = mrOK then
    begin
      lQuery := AForm.FilterQuery;
      if lQuery <> '' then
        FGridPlugin.SetPreparedFilter(lQuery);
    end;
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

function TDonorListForm.GetEditFormClass: TFormClass;
begin
  Result := TDonorEditForm;
end;

procedure TDonorListForm.LoadData;
begin
  UnLockControls(True);
end;

procedure TDonorListForm.MainDatasetCalcFields(Dataset: TDataSet);
begin
  MainDatasetClaimStatusText.Value := TSysUtils.SplitOnCaps(MainDatasetClaimStatus.Value);
  MainDatasetFullName.Value := TContactUtils.FullName(MainDataset);
  MainDatasetFullAddress.Value := TContactUtils.FormattedAddress(MainDataset);
end;

procedure TDonorListForm.MainDatasetClaimStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := TSysUtils.SplitOnCaps(Text);
end;

procedure TDonorListForm.PrepareForm;
begin
  FIdField := 'Id';
end;

function TDonorListForm.RecordViewOption: TViewOption;
begin
  Result := voEdit;
end;

procedure TDonorListForm.SearchData(const Value: string);
var
  lPostCode: TPostCodePart;
  lShopQuery, lQuery: string;
begin
  { TODO : Handle if Shop selected }
  if FBuildingQuery then
    Exit;
  FBuildingQuery := True;

  lPostCode := TContactUtils.IsPostCode(Value);
  if lPostCode = pcFull then
    lQuery := format('(PostCode eq ''%s'')', [Uppercase(Value)])
  else if lPostCode = pcPartial then
    lQuery := format('(startswith(PostCode, ''%s''))', [Uppercase(Value)])
  else if TSysUtils.IsNumber(Value) then
    lQuery := format('((Id eq %s) or (ExternalId eq ''%s''))', [Value, Value])
  else if Pos('@', Value) > 0 then
    lQuery := format('(Email eq ''%s'')', [Lowercase(Value)])
  else
  begin
    lQuery := format('startswith(upper(LastName), ''%s'') or startswith(upper(Add1), ''%s'') or startswith(upper(FirstName), ''%s'') or (ExternalId eq ''%s'')',
      [Uppercase(Value), Uppercase(Value), Uppercase(Value), Uppercase(Value)]);
  end;
  FGridPlugin.SetPreparedFilter(lShopQuery + lQuery);

  FBuildingQuery := False;
end;

procedure TDonorListForm.ShowDonationsRecord(const ARecordId: Integer);
var
  AForm: TDonations_List;
begin
  AForm := TDonations_List.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    AForm.DonorId := ARecordId;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TDonorListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  AdvancedSearchButton := TButton.Create('AdvancedSearchButton');
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetTitle := TStringField.Create(Self);
  MainDatasetFirstName := TStringField.Create(Self);
  MainDatasetLastName := TStringField.Create(Self);
  MainDatasetAdd1 := TStringField.Create(Self);
  MainDatasetAdd2 := TStringField.Create(Self);
  MainDatasetAdd3 := TStringField.Create(Self);
  MainDatasetCity := TStringField.Create(Self);
  MainDatasetPostCode := TStringField.Create(Self);
  MainDatasetEmail := TStringField.Create(Self);
  MainDatasetMobile := TStringField.Create(Self);
  MainDatasetTelephone := TStringField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetComments := TStringField.Create(Self);
  MainDatasetDateSigned := TDateField.Create(Self);
  MainDatasetContactByPhone := TBooleanField.Create(Self);
  MainDatasetContactByPost := TBooleanField.Create(Self);
  MainDatasetContactByText := TBooleanField.Create(Self);
  MainDatasetContactByEmail := TBooleanField.Create(Self);
  MainDatasetMatchHash := TStringField.Create(Self);
  MainDatasetMasterId := TIntegerField.Create(Self);
  MainDatasetClaimStatusText := TStringField.Create(Self);
  MainDatasetFullName := TStringField.Create(Self);
  MainDatasetFullAddress := TStringField.Create(Self);
  MainDatasetMiddleName := TStringField.Create(Self);
  MainDatasetSuffix := TStringField.Create(Self);
  MainDatasetDOB := TDateField.Create(Self);
  MainDatasetHearingStatus := TStringField.Create(Self);
  MainDatasetEmailStatus := TStringField.Create(Self);
  MainDatasetLatitude := TFloatField.Create(Self);
  MainDatasetLongitude := TFloatField.Create(Self);
  MainDatasetExternalId := TStringField.Create(Self);
  MainDatasetNotUK := TBooleanField.Create(Self);
  MainDatasetCountry := TStringField.Create(Self);
  MainDatasetCountryCode := TStringField.Create(Self);

  edSearch.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  AdvancedSearchButton.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetTitle.BeforeLoadDFMValues;
  MainDatasetFirstName.BeforeLoadDFMValues;
  MainDatasetLastName.BeforeLoadDFMValues;
  MainDatasetAdd1.BeforeLoadDFMValues;
  MainDatasetAdd2.BeforeLoadDFMValues;
  MainDatasetAdd3.BeforeLoadDFMValues;
  MainDatasetCity.BeforeLoadDFMValues;
  MainDatasetPostCode.BeforeLoadDFMValues;
  MainDatasetEmail.BeforeLoadDFMValues;
  MainDatasetMobile.BeforeLoadDFMValues;
  MainDatasetTelephone.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetComments.BeforeLoadDFMValues;
  MainDatasetDateSigned.BeforeLoadDFMValues;
  MainDatasetContactByPhone.BeforeLoadDFMValues;
  MainDatasetContactByPost.BeforeLoadDFMValues;
  MainDatasetContactByText.BeforeLoadDFMValues;
  MainDatasetContactByEmail.BeforeLoadDFMValues;
  MainDatasetMatchHash.BeforeLoadDFMValues;
  MainDatasetMasterId.BeforeLoadDFMValues;
  MainDatasetClaimStatusText.BeforeLoadDFMValues;
  MainDatasetFullName.BeforeLoadDFMValues;
  MainDatasetFullAddress.BeforeLoadDFMValues;
  MainDatasetMiddleName.BeforeLoadDFMValues;
  MainDatasetSuffix.BeforeLoadDFMValues;
  MainDatasetDOB.BeforeLoadDFMValues;
  MainDatasetHearingStatus.BeforeLoadDFMValues;
  MainDatasetEmailStatus.BeforeLoadDFMValues;
  MainDatasetLatitude.BeforeLoadDFMValues;
  MainDatasetLongitude.BeforeLoadDFMValues;
  MainDatasetExternalId.BeforeLoadDFMValues;
  MainDatasetNotUK.BeforeLoadDFMValues;
  MainDatasetCountry.BeforeLoadDFMValues;
  MainDatasetCountryCode.BeforeLoadDFMValues;
  try
    edSearch.Hint := 'Any Id, Last Name, Post Code, 1st line of address';
    edSearch.ShowHint := True;
    edSearch.TextHint := 'Any Id, Last Name, Post Code, 1st line of address';
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ExternalId';
      Title := 'External Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'FirstName';
      Title := 'First Name';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'LastName';
      Title := 'Surname';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'PostCode';
      Title := 'Post Code';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimStatusText';
      Title := 'Claim Status';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Telephone';
      Title := 'Telephone';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    AddButton.Visible := False;
    AdvancedSearchButton.SetParentComponent(Self);
    AdvancedSearchButton.Name := 'AdvancedSearchButton';
    AdvancedSearchButton.Left := 574;
    AdvancedSearchButton.Top := 65;
    AdvancedSearchButton.Width := 56;
    AdvancedSearchButton.Height := 25;
    AdvancedSearchButton.Caption := 'Advanced Search';
    AdvancedSearchButton.ChildOrder := 10;
    AdvancedSearchButton.ElementClassName := 'btn btn-primary';
    AdvancedSearchButton.ElementFont := efCSS;
    AdvancedSearchButton.ElementPosition := epIgnore;
    AdvancedSearchButton.HeightStyle := ssAuto;
    AdvancedSearchButton.HeightPercent := 100.000000000000000000;
    AdvancedSearchButton.WidthStyle := ssAuto;
    AdvancedSearchButton.WidthPercent := 100.000000000000000000;
    SetEvent(AdvancedSearchButton, Self, 'OnClick', 'AdvancedSearchButtonClick');
    SetEvent(MainDataset, Self, 'OnCalcFields', 'MainDatasetCalcFields');
    MainDataset.EntitySetName := 'PersonLocation';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetTitle.SetParentComponent(MainDataset);
    MainDatasetTitle.Name := 'MainDatasetTitle';
    MainDatasetTitle.FieldName := 'Title';
    MainDatasetTitle.Size := 25;
    MainDatasetFirstName.SetParentComponent(MainDataset);
    MainDatasetFirstName.Name := 'MainDatasetFirstName';
    MainDatasetFirstName.FieldName := 'FirstName';
    MainDatasetFirstName.Size := 50;
    MainDatasetLastName.SetParentComponent(MainDataset);
    MainDatasetLastName.Name := 'MainDatasetLastName';
    MainDatasetLastName.FieldName := 'LastName';
    MainDatasetLastName.Size := 50;
    MainDatasetAdd1.SetParentComponent(MainDataset);
    MainDatasetAdd1.Name := 'MainDatasetAdd1';
    MainDatasetAdd1.FieldName := 'Add1';
    MainDatasetAdd1.Size := 75;
    MainDatasetAdd2.SetParentComponent(MainDataset);
    MainDatasetAdd2.Name := 'MainDatasetAdd2';
    MainDatasetAdd2.FieldName := 'Add2';
    MainDatasetAdd2.Size := 75;
    MainDatasetAdd3.SetParentComponent(MainDataset);
    MainDatasetAdd3.Name := 'MainDatasetAdd3';
    MainDatasetAdd3.FieldName := 'Add3';
    MainDatasetAdd3.Size := 75;
    MainDatasetCity.SetParentComponent(MainDataset);
    MainDatasetCity.Name := 'MainDatasetCity';
    MainDatasetCity.FieldName := 'City';
    MainDatasetCity.Size := 75;
    MainDatasetPostCode.SetParentComponent(MainDataset);
    MainDatasetPostCode.Name := 'MainDatasetPostCode';
    MainDatasetPostCode.FieldName := 'PostCode';
    MainDatasetPostCode.Size := 15;
    MainDatasetEmail.SetParentComponent(MainDataset);
    MainDatasetEmail.Name := 'MainDatasetEmail';
    MainDatasetEmail.FieldName := 'Email';
    MainDatasetEmail.Size := 75;
    MainDatasetMobile.SetParentComponent(MainDataset);
    MainDatasetMobile.Name := 'MainDatasetMobile';
    MainDatasetMobile.FieldName := 'Mobile';
    MainDatasetMobile.Size := 25;
    MainDatasetTelephone.SetParentComponent(MainDataset);
    MainDatasetTelephone.Name := 'MainDatasetTelephone';
    MainDatasetTelephone.FieldName := 'Telephone';
    MainDatasetTelephone.Size := 25;
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    SetEvent(MainDatasetClaimStatus, Self, 'OnGetText', 'MainDatasetClaimStatusGetText');
    MainDatasetClaimStatus.Size := 22;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetComments.SetParentComponent(MainDataset);
    MainDatasetComments.Name := 'MainDatasetComments';
    MainDatasetComments.FieldName := 'Comments';
    MainDatasetComments.Size := 255;
    MainDatasetDateSigned.SetParentComponent(MainDataset);
    MainDatasetDateSigned.Name := 'MainDatasetDateSigned';
    MainDatasetDateSigned.FieldName := 'DateSigned';
    MainDatasetContactByPhone.SetParentComponent(MainDataset);
    MainDatasetContactByPhone.Name := 'MainDatasetContactByPhone';
    MainDatasetContactByPhone.FieldName := 'ContactByPhone';
    MainDatasetContactByPost.SetParentComponent(MainDataset);
    MainDatasetContactByPost.Name := 'MainDatasetContactByPost';
    MainDatasetContactByPost.FieldName := 'ContactByPost';
    MainDatasetContactByText.SetParentComponent(MainDataset);
    MainDatasetContactByText.Name := 'MainDatasetContactByText';
    MainDatasetContactByText.FieldName := 'ContactByText';
    MainDatasetContactByEmail.SetParentComponent(MainDataset);
    MainDatasetContactByEmail.Name := 'MainDatasetContactByEmail';
    MainDatasetContactByEmail.FieldName := 'ContactByEmail';
    MainDatasetMatchHash.SetParentComponent(MainDataset);
    MainDatasetMatchHash.Name := 'MainDatasetMatchHash';
    MainDatasetMatchHash.FieldName := 'MatchHash';
    MainDatasetMatchHash.Size := 64;
    MainDatasetMasterId.SetParentComponent(MainDataset);
    MainDatasetMasterId.Name := 'MainDatasetMasterId';
    MainDatasetMasterId.FieldName := 'MasterId';
    MainDatasetClaimStatusText.SetParentComponent(MainDataset);
    MainDatasetClaimStatusText.Name := 'MainDatasetClaimStatusText';
    MainDatasetClaimStatusText.FieldKind := fkCalculated;
    MainDatasetClaimStatusText.FieldName := 'ClaimStatusText';
    MainDatasetClaimStatusText.Size := 25;
    MainDatasetClaimStatusText.Calculated := True;
    MainDatasetFullName.SetParentComponent(MainDataset);
    MainDatasetFullName.Name := 'MainDatasetFullName';
    MainDatasetFullName.FieldKind := fkCalculated;
    MainDatasetFullName.FieldName := 'FullName';
    MainDatasetFullName.Size := 50;
    MainDatasetFullName.Calculated := True;
    MainDatasetFullAddress.SetParentComponent(MainDataset);
    MainDatasetFullAddress.Name := 'MainDatasetFullAddress';
    MainDatasetFullAddress.FieldKind := fkCalculated;
    MainDatasetFullAddress.FieldName := 'FullAddress';
    MainDatasetFullAddress.Size := 250;
    MainDatasetFullAddress.Calculated := True;
    MainDatasetMiddleName.SetParentComponent(MainDataset);
    MainDatasetMiddleName.Name := 'MainDatasetMiddleName';
    MainDatasetMiddleName.FieldName := 'MiddleName';
    MainDatasetMiddleName.Size := 50;
    MainDatasetSuffix.SetParentComponent(MainDataset);
    MainDatasetSuffix.Name := 'MainDatasetSuffix';
    MainDatasetSuffix.FieldName := 'Suffix';
    MainDatasetSuffix.Size := 50;
    MainDatasetDOB.SetParentComponent(MainDataset);
    MainDatasetDOB.Name := 'MainDatasetDOB';
    MainDatasetDOB.FieldName := 'DOB';
    MainDatasetHearingStatus.SetParentComponent(MainDataset);
    MainDatasetHearingStatus.Name := 'MainDatasetHearingStatus';
    MainDatasetHearingStatus.FieldName := 'HearingStatus';
    MainDatasetHearingStatus.Required := True;
    MainDatasetHearingStatus.Size := 8;
    MainDatasetEmailStatus.SetParentComponent(MainDataset);
    MainDatasetEmailStatus.Name := 'MainDatasetEmailStatus';
    MainDatasetEmailStatus.FieldName := 'EmailStatus';
    MainDatasetEmailStatus.Required := True;
    MainDatasetEmailStatus.Size := 10;
    MainDatasetLatitude.SetParentComponent(MainDataset);
    MainDatasetLatitude.Name := 'MainDatasetLatitude';
    MainDatasetLatitude.FieldName := 'Latitude';
    MainDatasetLongitude.SetParentComponent(MainDataset);
    MainDatasetLongitude.Name := 'MainDatasetLongitude';
    MainDatasetLongitude.FieldName := 'Longitude';
    MainDatasetExternalId.SetParentComponent(MainDataset);
    MainDatasetExternalId.Name := 'MainDatasetExternalId';
    MainDatasetExternalId.FieldName := 'ExternalId';
    MainDatasetExternalId.Size := 64;
    MainDatasetNotUK.SetParentComponent(MainDataset);
    MainDatasetNotUK.Name := 'MainDatasetNotUK';
    MainDatasetNotUK.FieldName := 'NotUK';
    MainDatasetNotUK.Required := True;
    MainDatasetCountry.SetParentComponent(MainDataset);
    MainDatasetCountry.Name := 'MainDatasetCountry';
    MainDatasetCountry.FieldName := 'Country';
    MainDatasetCountry.Size := 64;
    MainDatasetCountryCode.SetParentComponent(MainDataset);
    MainDatasetCountryCode.Name := 'MainDatasetCountryCode';
    MainDatasetCountryCode.FieldName := 'CountryCode';
    MainDatasetCountryCode.Size := 3;
  finally
    edSearch.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    AdvancedSearchButton.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetTitle.AfterLoadDFMValues;
    MainDatasetFirstName.AfterLoadDFMValues;
    MainDatasetLastName.AfterLoadDFMValues;
    MainDatasetAdd1.AfterLoadDFMValues;
    MainDatasetAdd2.AfterLoadDFMValues;
    MainDatasetAdd3.AfterLoadDFMValues;
    MainDatasetCity.AfterLoadDFMValues;
    MainDatasetPostCode.AfterLoadDFMValues;
    MainDatasetEmail.AfterLoadDFMValues;
    MainDatasetMobile.AfterLoadDFMValues;
    MainDatasetTelephone.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetComments.AfterLoadDFMValues;
    MainDatasetDateSigned.AfterLoadDFMValues;
    MainDatasetContactByPhone.AfterLoadDFMValues;
    MainDatasetContactByPost.AfterLoadDFMValues;
    MainDatasetContactByText.AfterLoadDFMValues;
    MainDatasetContactByEmail.AfterLoadDFMValues;
    MainDatasetMatchHash.AfterLoadDFMValues;
    MainDatasetMasterId.AfterLoadDFMValues;
    MainDatasetClaimStatusText.AfterLoadDFMValues;
    MainDatasetFullName.AfterLoadDFMValues;
    MainDatasetFullAddress.AfterLoadDFMValues;
    MainDatasetMiddleName.AfterLoadDFMValues;
    MainDatasetSuffix.AfterLoadDFMValues;
    MainDatasetDOB.AfterLoadDFMValues;
    MainDatasetHearingStatus.AfterLoadDFMValues;
    MainDatasetEmailStatus.AfterLoadDFMValues;
    MainDatasetLatitude.AfterLoadDFMValues;
    MainDatasetLongitude.AfterLoadDFMValues;
    MainDatasetExternalId.AfterLoadDFMValues;
    MainDatasetNotUK.AfterLoadDFMValues;
    MainDatasetCountry.AfterLoadDFMValues;
    MainDatasetCountryCode.AfterLoadDFMValues;
  end;
end;

end.
