unit ImportData;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseCoreForm,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.DB,
  WEBLib.Grids,
  WEBLib.DBCtrls;

type
  TImportDataList = class(TCoreWebForm)
    ImportDonationsTable: TDBTableControl;
    MessageLabel: TLabel;
    ImportDonationSource: TDataSource;
    ImportDonationData: TXDataWebDataSet;
    ImportDonationDataId: TIntegerField;
    ImportDonationDataDateAdded: TDateTimeField;
    ImportDonationDataAddedBy: TIntegerField;
    ImportDonationDataLastUpdatedBy: TIntegerField;
    ImportDonationDataLastUpdated: TDateTimeField;
    ImportDonationDataTitle: TStringField;
    ImportDonationDataFirstName: TStringField;
    ImportDonationDataMiddleName: TStringField;
    ImportDonationDataLastName: TStringField;
    ImportDonationDataSuffix: TStringField;
    ImportDonationDataAdd1: TStringField;
    ImportDonationDataAdd2: TStringField;
    ImportDonationDataAdd3: TStringField;
    ImportDonationDataCity: TStringField;
    ImportDonationDataPostCode: TStringField;
    ImportDonationDataEmail: TStringField;
    ImportDonationDataMobile: TStringField;
    ImportDonationDataTelephone: TStringField;
    ImportDonationDataMatchHash: TStringField;
    ImportDonationDataContactByPhone: TBooleanField;
    ImportDonationDataContactByPost: TBooleanField;
    ImportDonationDataContactByText: TBooleanField;
    ImportDonationDataContactByEmail: TBooleanField;
    ImportDonationDataDOB: TDateField;
    ImportDonationDataEmailStatus: TStringField;
    ImportDonationDataLatitude: TFloatField;
    ImportDonationDataLongitude: TFloatField;
    ImportDonationDataExternalId: TStringField;
    ImportDonationDataNotUK: TBooleanField;
    ImportDonationDataCountry: TStringField;
    ImportDonationDataCountryCode: TStringField;
    ImportDonationDataClaimStatus: TStringField;
    ImportDonationDataCharityTenant: TStringField;
    ImportDonationDataImportState: TStringField;
    ImportDonationDataErrorText: TStringField;
    ImportDonationDataImportBatchId: TIntegerField;
    ImportDonationDataSponsored: TBooleanField;
    ImportDonationDataDonationDate: TDateTimeField;
    ImportDonationDataGiftAid: TFloatField;
    ImportDonationDataAmount: TFloatField;
    ImportDonationDataExternalDonationId: TStringField;
    ImportDonationDataMatchedPerson: TIntegerField;
    DonationsCloseButton: TButton;
    procedure DonationsCloseButtonClick(Sender: TObject);
  private
    { Private declarations }
    FImportId: Integer;
    procedure SetImportId(const Value: Integer);

  public
    procedure AfterCreated;
    property ImportId: Integer write SetImportId;
  protected procedure LoadDFMValues; override; end;

var
  ImportDataList: TImportDataList;

implementation

{$R *.dfm}

uses
  MainDataModule;
{ TForm1 }

procedure TImportDataList.AfterCreated;
begin
  ImportDonationData.QueryString := '$filter=ImportBatchId eq ' + FImportId.ToString;
  ImportDonationData.Load;
end;

procedure TImportDataList.DonationsCloseButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TImportDataList.SetImportId(const Value: Integer);
begin
  FImportId := Value;
end;

procedure TImportDataList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MessageLabel := TLabel.Create('MessageLabel');
  ImportDonationsTable := TDBTableControl.Create('ImportDonationsTable');
  DonationsCloseButton := TButton.Create('DonationsCloseButton');
  ImportDonationSource := TDataSource.Create(Self);
  ImportDonationData := TXDataWebDataSet.Create(Self);
  ImportDonationDataId := TIntegerField.Create(Self);
  ImportDonationDataDateAdded := TDateTimeField.Create(Self);
  ImportDonationDataAddedBy := TIntegerField.Create(Self);
  ImportDonationDataLastUpdatedBy := TIntegerField.Create(Self);
  ImportDonationDataLastUpdated := TDateTimeField.Create(Self);
  ImportDonationDataTitle := TStringField.Create(Self);
  ImportDonationDataFirstName := TStringField.Create(Self);
  ImportDonationDataMiddleName := TStringField.Create(Self);
  ImportDonationDataLastName := TStringField.Create(Self);
  ImportDonationDataSuffix := TStringField.Create(Self);
  ImportDonationDataAdd1 := TStringField.Create(Self);
  ImportDonationDataAdd2 := TStringField.Create(Self);
  ImportDonationDataAdd3 := TStringField.Create(Self);
  ImportDonationDataCity := TStringField.Create(Self);
  ImportDonationDataPostCode := TStringField.Create(Self);
  ImportDonationDataEmail := TStringField.Create(Self);
  ImportDonationDataMobile := TStringField.Create(Self);
  ImportDonationDataTelephone := TStringField.Create(Self);
  ImportDonationDataMatchHash := TStringField.Create(Self);
  ImportDonationDataContactByPhone := TBooleanField.Create(Self);
  ImportDonationDataContactByPost := TBooleanField.Create(Self);
  ImportDonationDataContactByText := TBooleanField.Create(Self);
  ImportDonationDataContactByEmail := TBooleanField.Create(Self);
  ImportDonationDataDOB := TDateField.Create(Self);
  ImportDonationDataEmailStatus := TStringField.Create(Self);
  ImportDonationDataLatitude := TFloatField.Create(Self);
  ImportDonationDataLongitude := TFloatField.Create(Self);
  ImportDonationDataExternalId := TStringField.Create(Self);
  ImportDonationDataNotUK := TBooleanField.Create(Self);
  ImportDonationDataCountry := TStringField.Create(Self);
  ImportDonationDataCountryCode := TStringField.Create(Self);
  ImportDonationDataClaimStatus := TStringField.Create(Self);
  ImportDonationDataCharityTenant := TStringField.Create(Self);
  ImportDonationDataImportState := TStringField.Create(Self);
  ImportDonationDataErrorText := TStringField.Create(Self);
  ImportDonationDataImportBatchId := TIntegerField.Create(Self);
  ImportDonationDataSponsored := TBooleanField.Create(Self);
  ImportDonationDataDonationDate := TDateTimeField.Create(Self);
  ImportDonationDataGiftAid := TFloatField.Create(Self);
  ImportDonationDataAmount := TFloatField.Create(Self);
  ImportDonationDataExternalDonationId := TStringField.Create(Self);
  ImportDonationDataMatchedPerson := TIntegerField.Create(Self);

  MessageLabel.BeforeLoadDFMValues;
  CloseThisButton.BeforeLoadDFMValues;
  ImportDonationsTable.BeforeLoadDFMValues;
  DonationsCloseButton.BeforeLoadDFMValues;
  ImportDonationSource.BeforeLoadDFMValues;
  ImportDonationData.BeforeLoadDFMValues;
  ImportDonationDataId.BeforeLoadDFMValues;
  ImportDonationDataDateAdded.BeforeLoadDFMValues;
  ImportDonationDataAddedBy.BeforeLoadDFMValues;
  ImportDonationDataLastUpdatedBy.BeforeLoadDFMValues;
  ImportDonationDataLastUpdated.BeforeLoadDFMValues;
  ImportDonationDataTitle.BeforeLoadDFMValues;
  ImportDonationDataFirstName.BeforeLoadDFMValues;
  ImportDonationDataMiddleName.BeforeLoadDFMValues;
  ImportDonationDataLastName.BeforeLoadDFMValues;
  ImportDonationDataSuffix.BeforeLoadDFMValues;
  ImportDonationDataAdd1.BeforeLoadDFMValues;
  ImportDonationDataAdd2.BeforeLoadDFMValues;
  ImportDonationDataAdd3.BeforeLoadDFMValues;
  ImportDonationDataCity.BeforeLoadDFMValues;
  ImportDonationDataPostCode.BeforeLoadDFMValues;
  ImportDonationDataEmail.BeforeLoadDFMValues;
  ImportDonationDataMobile.BeforeLoadDFMValues;
  ImportDonationDataTelephone.BeforeLoadDFMValues;
  ImportDonationDataMatchHash.BeforeLoadDFMValues;
  ImportDonationDataContactByPhone.BeforeLoadDFMValues;
  ImportDonationDataContactByPost.BeforeLoadDFMValues;
  ImportDonationDataContactByText.BeforeLoadDFMValues;
  ImportDonationDataContactByEmail.BeforeLoadDFMValues;
  ImportDonationDataDOB.BeforeLoadDFMValues;
  ImportDonationDataEmailStatus.BeforeLoadDFMValues;
  ImportDonationDataLatitude.BeforeLoadDFMValues;
  ImportDonationDataLongitude.BeforeLoadDFMValues;
  ImportDonationDataExternalId.BeforeLoadDFMValues;
  ImportDonationDataNotUK.BeforeLoadDFMValues;
  ImportDonationDataCountry.BeforeLoadDFMValues;
  ImportDonationDataCountryCode.BeforeLoadDFMValues;
  ImportDonationDataClaimStatus.BeforeLoadDFMValues;
  ImportDonationDataCharityTenant.BeforeLoadDFMValues;
  ImportDonationDataImportState.BeforeLoadDFMValues;
  ImportDonationDataErrorText.BeforeLoadDFMValues;
  ImportDonationDataImportBatchId.BeforeLoadDFMValues;
  ImportDonationDataSponsored.BeforeLoadDFMValues;
  ImportDonationDataDonationDate.BeforeLoadDFMValues;
  ImportDonationDataGiftAid.BeforeLoadDFMValues;
  ImportDonationDataAmount.BeforeLoadDFMValues;
  ImportDonationDataExternalDonationId.BeforeLoadDFMValues;
  ImportDonationDataMatchedPerson.BeforeLoadDFMValues;
  try
    MessageLabel.SetParentComponent(Self);
    MessageLabel.Name := 'MessageLabel';
    MessageLabel.Left := 232;
    MessageLabel.Top := 24;
    MessageLabel.Width := 74;
    MessageLabel.Height := 15;
    MessageLabel.Caption := 'MessageLabel';
    MessageLabel.ElementFont := efCSS;
    MessageLabel.ElementPosition := epIgnore;
    MessageLabel.HeightStyle := ssAuto;
    MessageLabel.HeightPercent := 100.000000000000000000;
    MessageLabel.HTMLType := tDIV;
    MessageLabel.WidthStyle := ssAuto;
    MessageLabel.WidthPercent := 100.000000000000000000;
    CloseThisButton.Visible := False;
    ImportDonationsTable.SetParentComponent(Self);
    ImportDonationsTable.Name := 'ImportDonationsTable';
    ImportDonationsTable.Left := 32;
    ImportDonationsTable.Top := 104;
    ImportDonationsTable.Width := 513;
    ImportDonationsTable.Height := 249;
    ImportDonationsTable.HeightStyle := ssAuto;
    ImportDonationsTable.WidthStyle := ssAuto;
    ImportDonationsTable.BorderColor := clSilver;
    ImportDonationsTable.ChildOrder := 4;
    ImportDonationsTable.ElementFont := efCSS;
    ImportDonationsTable.ElementHeaderClassName := 'thead-light';
    ImportDonationsTable.ElementPosition := epIgnore;
    ImportDonationsTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    ImportDonationsTable.Columns.Clear;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'FirstName';
      Title := 'Firstname';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'LastName';
      Title := 'Lastname';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'DonationDate';
      Title := 'Date';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'PostCode';
      Title := 'Post Code';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'ImportState';
      Title := 'State';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'ErrorText';
      Title := 'Errors';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    ImportDonationsTable.DataSource := ImportDonationSource;
    DonationsCloseButton.SetParentComponent(Self);
    DonationsCloseButton.Name := 'DonationsCloseButton';
    DonationsCloseButton.Left := 449;
    DonationsCloseButton.Top := 372;
    DonationsCloseButton.Width := 96;
    DonationsCloseButton.Height := 25;
    DonationsCloseButton.Caption := 'Close';
    DonationsCloseButton.ChildOrder := 3;
    DonationsCloseButton.ElementClassName := 'btn btn-light';
    DonationsCloseButton.ElementFont := efCSS;
    DonationsCloseButton.ElementPosition := epIgnore;
    DonationsCloseButton.HeightStyle := ssAuto;
    DonationsCloseButton.HeightPercent := 100.000000000000000000;
    DonationsCloseButton.WidthStyle := ssAuto;
    DonationsCloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(DonationsCloseButton, Self, 'OnClick', 'DonationsCloseButtonClick');
    ImportDonationSource.SetParentComponent(Self);
    ImportDonationSource.Name := 'ImportDonationSource';
    ImportDonationSource.DataSet := ImportDonationData;
    ImportDonationSource.Left := 144;
    ImportDonationSource.Top := 8;
    ImportDonationData.SetParentComponent(Self);
    ImportDonationData.Name := 'ImportDonationData';
    ImportDonationData.EntitySetName := 'ImportDonation';
    ImportDonationData.Connection := MainData.DataConnection;
    ImportDonationData.Left := 64;
    ImportDonationData.Top := 8;
    ImportDonationDataId.SetParentComponent(ImportDonationData);
    ImportDonationDataId.Name := 'ImportDonationDataId';
    ImportDonationDataId.FieldName := 'Id';
    ImportDonationDataId.Required := True;
    ImportDonationDataDateAdded.SetParentComponent(ImportDonationData);
    ImportDonationDataDateAdded.Name := 'ImportDonationDataDateAdded';
    ImportDonationDataDateAdded.FieldName := 'DateAdded';
    ImportDonationDataDateAdded.Required := True;
    ImportDonationDataAddedBy.SetParentComponent(ImportDonationData);
    ImportDonationDataAddedBy.Name := 'ImportDonationDataAddedBy';
    ImportDonationDataAddedBy.FieldName := 'AddedBy';
    ImportDonationDataAddedBy.Required := True;
    ImportDonationDataLastUpdatedBy.SetParentComponent(ImportDonationData);
    ImportDonationDataLastUpdatedBy.Name := 'ImportDonationDataLastUpdatedBy';
    ImportDonationDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    ImportDonationDataLastUpdated.SetParentComponent(ImportDonationData);
    ImportDonationDataLastUpdated.Name := 'ImportDonationDataLastUpdated';
    ImportDonationDataLastUpdated.FieldName := 'LastUpdated';
    ImportDonationDataTitle.SetParentComponent(ImportDonationData);
    ImportDonationDataTitle.Name := 'ImportDonationDataTitle';
    ImportDonationDataTitle.FieldName := 'Title';
    ImportDonationDataTitle.Size := 25;
    ImportDonationDataFirstName.SetParentComponent(ImportDonationData);
    ImportDonationDataFirstName.Name := 'ImportDonationDataFirstName';
    ImportDonationDataFirstName.FieldName := 'FirstName';
    ImportDonationDataFirstName.Size := 50;
    ImportDonationDataMiddleName.SetParentComponent(ImportDonationData);
    ImportDonationDataMiddleName.Name := 'ImportDonationDataMiddleName';
    ImportDonationDataMiddleName.FieldName := 'MiddleName';
    ImportDonationDataMiddleName.Size := 50;
    ImportDonationDataLastName.SetParentComponent(ImportDonationData);
    ImportDonationDataLastName.Name := 'ImportDonationDataLastName';
    ImportDonationDataLastName.FieldName := 'LastName';
    ImportDonationDataLastName.Size := 50;
    ImportDonationDataSuffix.SetParentComponent(ImportDonationData);
    ImportDonationDataSuffix.Name := 'ImportDonationDataSuffix';
    ImportDonationDataSuffix.FieldName := 'Suffix';
    ImportDonationDataSuffix.Size := 50;
    ImportDonationDataAdd1.SetParentComponent(ImportDonationData);
    ImportDonationDataAdd1.Name := 'ImportDonationDataAdd1';
    ImportDonationDataAdd1.FieldName := 'Add1';
    ImportDonationDataAdd1.Size := 75;
    ImportDonationDataAdd2.SetParentComponent(ImportDonationData);
    ImportDonationDataAdd2.Name := 'ImportDonationDataAdd2';
    ImportDonationDataAdd2.FieldName := 'Add2';
    ImportDonationDataAdd2.Size := 75;
    ImportDonationDataAdd3.SetParentComponent(ImportDonationData);
    ImportDonationDataAdd3.Name := 'ImportDonationDataAdd3';
    ImportDonationDataAdd3.FieldName := 'Add3';
    ImportDonationDataAdd3.Size := 75;
    ImportDonationDataCity.SetParentComponent(ImportDonationData);
    ImportDonationDataCity.Name := 'ImportDonationDataCity';
    ImportDonationDataCity.FieldName := 'City';
    ImportDonationDataCity.Size := 75;
    ImportDonationDataPostCode.SetParentComponent(ImportDonationData);
    ImportDonationDataPostCode.Name := 'ImportDonationDataPostCode';
    ImportDonationDataPostCode.FieldName := 'PostCode';
    ImportDonationDataPostCode.Size := 15;
    ImportDonationDataEmail.SetParentComponent(ImportDonationData);
    ImportDonationDataEmail.Name := 'ImportDonationDataEmail';
    ImportDonationDataEmail.FieldName := 'Email';
    ImportDonationDataEmail.Size := 75;
    ImportDonationDataMobile.SetParentComponent(ImportDonationData);
    ImportDonationDataMobile.Name := 'ImportDonationDataMobile';
    ImportDonationDataMobile.FieldName := 'Mobile';
    ImportDonationDataMobile.Size := 25;
    ImportDonationDataTelephone.SetParentComponent(ImportDonationData);
    ImportDonationDataTelephone.Name := 'ImportDonationDataTelephone';
    ImportDonationDataTelephone.FieldName := 'Telephone';
    ImportDonationDataTelephone.Size := 25;
    ImportDonationDataMatchHash.SetParentComponent(ImportDonationData);
    ImportDonationDataMatchHash.Name := 'ImportDonationDataMatchHash';
    ImportDonationDataMatchHash.FieldName := 'MatchHash';
    ImportDonationDataMatchHash.Size := 64;
    ImportDonationDataContactByPhone.SetParentComponent(ImportDonationData);
    ImportDonationDataContactByPhone.Name := 'ImportDonationDataContactByPhone';
    ImportDonationDataContactByPhone.FieldName := 'ContactByPhone';
    ImportDonationDataContactByPhone.Required := True;
    ImportDonationDataContactByPost.SetParentComponent(ImportDonationData);
    ImportDonationDataContactByPost.Name := 'ImportDonationDataContactByPost';
    ImportDonationDataContactByPost.FieldName := 'ContactByPost';
    ImportDonationDataContactByPost.Required := True;
    ImportDonationDataContactByText.SetParentComponent(ImportDonationData);
    ImportDonationDataContactByText.Name := 'ImportDonationDataContactByText';
    ImportDonationDataContactByText.FieldName := 'ContactByText';
    ImportDonationDataContactByText.Required := True;
    ImportDonationDataContactByEmail.SetParentComponent(ImportDonationData);
    ImportDonationDataContactByEmail.Name := 'ImportDonationDataContactByEmail';
    ImportDonationDataContactByEmail.FieldName := 'ContactByEmail';
    ImportDonationDataContactByEmail.Required := True;
    ImportDonationDataDOB.SetParentComponent(ImportDonationData);
    ImportDonationDataDOB.Name := 'ImportDonationDataDOB';
    ImportDonationDataDOB.FieldName := 'DOB';
    ImportDonationDataEmailStatus.SetParentComponent(ImportDonationData);
    ImportDonationDataEmailStatus.Name := 'ImportDonationDataEmailStatus';
    ImportDonationDataEmailStatus.FieldName := 'EmailStatus';
    ImportDonationDataEmailStatus.Required := True;
    ImportDonationDataEmailStatus.Size := 10;
    ImportDonationDataLatitude.SetParentComponent(ImportDonationData);
    ImportDonationDataLatitude.Name := 'ImportDonationDataLatitude';
    ImportDonationDataLatitude.FieldName := 'Latitude';
    ImportDonationDataLongitude.SetParentComponent(ImportDonationData);
    ImportDonationDataLongitude.Name := 'ImportDonationDataLongitude';
    ImportDonationDataLongitude.FieldName := 'Longitude';
    ImportDonationDataExternalId.SetParentComponent(ImportDonationData);
    ImportDonationDataExternalId.Name := 'ImportDonationDataExternalId';
    ImportDonationDataExternalId.FieldName := 'ExternalId';
    ImportDonationDataExternalId.Size := 64;
    ImportDonationDataNotUK.SetParentComponent(ImportDonationData);
    ImportDonationDataNotUK.Name := 'ImportDonationDataNotUK';
    ImportDonationDataNotUK.FieldName := 'NotUK';
    ImportDonationDataNotUK.Required := True;
    ImportDonationDataCountry.SetParentComponent(ImportDonationData);
    ImportDonationDataCountry.Name := 'ImportDonationDataCountry';
    ImportDonationDataCountry.FieldName := 'Country';
    ImportDonationDataCountry.Size := 64;
    ImportDonationDataCountryCode.SetParentComponent(ImportDonationData);
    ImportDonationDataCountryCode.Name := 'ImportDonationDataCountryCode';
    ImportDonationDataCountryCode.FieldName := 'CountryCode';
    ImportDonationDataCountryCode.Size := 3;
    ImportDonationDataClaimStatus.SetParentComponent(ImportDonationData);
    ImportDonationDataClaimStatus.Name := 'ImportDonationDataClaimStatus';
    ImportDonationDataClaimStatus.FieldName := 'ClaimStatus';
    ImportDonationDataClaimStatus.Required := True;
    ImportDonationDataClaimStatus.Size := 22;
    ImportDonationDataCharityTenant.SetParentComponent(ImportDonationData);
    ImportDonationDataCharityTenant.Name := 'ImportDonationDataCharityTenant';
    ImportDonationDataCharityTenant.FieldName := 'CharityTenant';
    ImportDonationDataImportState.SetParentComponent(ImportDonationData);
    ImportDonationDataImportState.Name := 'ImportDonationDataImportState';
    ImportDonationDataImportState.FieldName := 'ImportState';
    ImportDonationDataImportState.Required := True;
    ImportDonationDataImportState.Size := 5;
    ImportDonationDataErrorText.SetParentComponent(ImportDonationData);
    ImportDonationDataErrorText.Name := 'ImportDonationDataErrorText';
    ImportDonationDataErrorText.FieldName := 'ErrorText';
    ImportDonationDataErrorText.Size := 1000;
    ImportDonationDataImportBatchId.SetParentComponent(ImportDonationData);
    ImportDonationDataImportBatchId.Name := 'ImportDonationDataImportBatchId';
    ImportDonationDataImportBatchId.FieldName := 'ImportBatchId';
    ImportDonationDataImportBatchId.Required := True;
    ImportDonationDataSponsored.SetParentComponent(ImportDonationData);
    ImportDonationDataSponsored.Name := 'ImportDonationDataSponsored';
    ImportDonationDataSponsored.FieldName := 'Sponsored';
    ImportDonationDataSponsored.Required := True;
    ImportDonationDataDonationDate.SetParentComponent(ImportDonationData);
    ImportDonationDataDonationDate.Name := 'ImportDonationDataDonationDate';
    ImportDonationDataDonationDate.FieldName := 'DonationDate';
    ImportDonationDataDonationDate.Required := True;
    ImportDonationDataGiftAid.SetParentComponent(ImportDonationData);
    ImportDonationDataGiftAid.Name := 'ImportDonationDataGiftAid';
    ImportDonationDataGiftAid.FieldName := 'GiftAid';
    ImportDonationDataGiftAid.Required := True;
    ImportDonationDataAmount.SetParentComponent(ImportDonationData);
    ImportDonationDataAmount.Name := 'ImportDonationDataAmount';
    ImportDonationDataAmount.FieldName := 'Amount';
    ImportDonationDataAmount.Required := True;
    ImportDonationDataExternalDonationId.SetParentComponent(ImportDonationData);
    ImportDonationDataExternalDonationId.Name := 'ImportDonationDataExternalDonationId';
    ImportDonationDataExternalDonationId.FieldName := 'ExternalDonationId';
    ImportDonationDataExternalDonationId.Size := 255;
    ImportDonationDataMatchedPerson.SetParentComponent(ImportDonationData);
    ImportDonationDataMatchedPerson.Name := 'ImportDonationDataMatchedPerson';
    ImportDonationDataMatchedPerson.FieldName := 'MatchedPerson';
  finally
    MessageLabel.AfterLoadDFMValues;
    CloseThisButton.AfterLoadDFMValues;
    ImportDonationsTable.AfterLoadDFMValues;
    DonationsCloseButton.AfterLoadDFMValues;
    ImportDonationSource.AfterLoadDFMValues;
    ImportDonationData.AfterLoadDFMValues;
    ImportDonationDataId.AfterLoadDFMValues;
    ImportDonationDataDateAdded.AfterLoadDFMValues;
    ImportDonationDataAddedBy.AfterLoadDFMValues;
    ImportDonationDataLastUpdatedBy.AfterLoadDFMValues;
    ImportDonationDataLastUpdated.AfterLoadDFMValues;
    ImportDonationDataTitle.AfterLoadDFMValues;
    ImportDonationDataFirstName.AfterLoadDFMValues;
    ImportDonationDataMiddleName.AfterLoadDFMValues;
    ImportDonationDataLastName.AfterLoadDFMValues;
    ImportDonationDataSuffix.AfterLoadDFMValues;
    ImportDonationDataAdd1.AfterLoadDFMValues;
    ImportDonationDataAdd2.AfterLoadDFMValues;
    ImportDonationDataAdd3.AfterLoadDFMValues;
    ImportDonationDataCity.AfterLoadDFMValues;
    ImportDonationDataPostCode.AfterLoadDFMValues;
    ImportDonationDataEmail.AfterLoadDFMValues;
    ImportDonationDataMobile.AfterLoadDFMValues;
    ImportDonationDataTelephone.AfterLoadDFMValues;
    ImportDonationDataMatchHash.AfterLoadDFMValues;
    ImportDonationDataContactByPhone.AfterLoadDFMValues;
    ImportDonationDataContactByPost.AfterLoadDFMValues;
    ImportDonationDataContactByText.AfterLoadDFMValues;
    ImportDonationDataContactByEmail.AfterLoadDFMValues;
    ImportDonationDataDOB.AfterLoadDFMValues;
    ImportDonationDataEmailStatus.AfterLoadDFMValues;
    ImportDonationDataLatitude.AfterLoadDFMValues;
    ImportDonationDataLongitude.AfterLoadDFMValues;
    ImportDonationDataExternalId.AfterLoadDFMValues;
    ImportDonationDataNotUK.AfterLoadDFMValues;
    ImportDonationDataCountry.AfterLoadDFMValues;
    ImportDonationDataCountryCode.AfterLoadDFMValues;
    ImportDonationDataClaimStatus.AfterLoadDFMValues;
    ImportDonationDataCharityTenant.AfterLoadDFMValues;
    ImportDonationDataImportState.AfterLoadDFMValues;
    ImportDonationDataErrorText.AfterLoadDFMValues;
    ImportDonationDataImportBatchId.AfterLoadDFMValues;
    ImportDonationDataSponsored.AfterLoadDFMValues;
    ImportDonationDataDonationDate.AfterLoadDFMValues;
    ImportDonationDataGiftAid.AfterLoadDFMValues;
    ImportDonationDataAmount.AfterLoadDFMValues;
    ImportDonationDataExternalDonationId.AfterLoadDFMValues;
    ImportDonationDataMatchedPerson.AfterLoadDFMValues;
  end;
end;

end.
