unit BaseCoreForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  XData.Web.Client,
  WEBLib.Toast;

type
  TCoreWebForm = class(TForm)
    CloseThisButton: TButton;
    procedure CloseThisButtonClick(Sender: TObject);
  private
    { Private declarations }
    FOnSelfClose: TNotifyEvent;
  protected
    procedure SelfClose;
    procedure ControlValid(const AElementId: string; const SetValidClass: boolean = False);
    procedure ControlInvalid(const AElementId: string);
    procedure ControlVoid(const AElementId: string);
    procedure ControlNoValidity(const AElementId: string);
    function ControlValidity(const AElementId: string; const AIsValid: boolean;
      const SetValidClass: boolean = False): boolean;
    function ControlValidOrNone(const AElementId: string; const AIsValid: boolean): boolean;
    procedure ButtonValid(const AButton: TButton);

    procedure ButtonInvalid(const AButton: TButton);
    procedure ButtonValidity(const AButton: TButton; const AIsValid: boolean);
    procedure ShowAToast(const ATitle, AMessage, AImage: string);
     procedure ShowAnAwesomeToast(const ATitle, AMessage, AIcon: string);
    // function GetWebClient: TXDataWebClient;
    [async]
    procedure ShowAPopUp(AFormClass: TFormClass); async;

    [async]
    function InputQuery(const ACaption, APrompt: string; var AValue: string; const AEditType: TEditType): boolean; async;
    // procedure ControlRemoveValidity(const AElementId: string);
  public
    { Public declarations }

    function CanClose: boolean; virtual;
    procedure AfterCreated; virtual;
    property OnSelfClose: TNotifyEvent write FOnSelfClose;
  protected procedure LoadDFMValues; override; end;

implementation

uses
  App.Helper,
  MainDataModule,
  MainForm,
  InputQueryModule;

{$R *.dfm}
{ TCoreWebForm }

procedure TCoreWebForm.AfterCreated;
begin
//
end;

procedure TCoreWebForm.ButtonInvalid(const AButton: TButton);
begin
  AButton.Enabled := False;
  THTMLHelper.removeClass(AButton.ElementID, 'btn-success');
  THTMLHelper.addClass(AButton.ElementID, 'btn-secondary');
end;

procedure TCoreWebForm.ButtonValid(const AButton: TButton);
begin
  AButton.Enabled := True;
  THTMLHelper.removeClass(AButton.ElementID, 'btn-secondary');
  THTMLHelper.addClass(AButton.ElementID, 'btn-success');
end;

procedure TCoreWebForm.ButtonValidity(const AButton: TButton; const AIsValid: boolean);
begin
  if AIsValid then
    ButtonValid(AButton)
  else
    ButtonInvalid(AButton);
end;

function TCoreWebForm.CanClose: boolean;
begin
  result := True;
end;

procedure TCoreWebForm.CloseThisButtonClick(Sender: TObject);
begin
  if CanClose then
    SelfClose;
end;

procedure TCoreWebForm.ControlInvalid(const AElementId: string);
begin
  THTMLHelper.removeClass(AElementId, 'is-valid');
  THTMLHelper.addClass(AElementId, 'is-invalid');
end;

procedure TCoreWebForm.ControlNoValidity(const AElementId: string);
begin
  THTMLHelper.removeClass(AElementId, 'is-invalid');
  THTMLHelper.removeClass(AElementId, 'is-valid');
end;

procedure TCoreWebForm.ControlValid(const AElementId: string; const SetValidClass: boolean);
begin
  THTMLHelper.removeClass(AElementId, 'is-invalid');
  if SetValidClass then
    THTMLHelper.addClass(AElementId, 'is-valid');
end;

function TCoreWebForm.ControlValidity(const AElementId: string; const AIsValid: boolean;
  const SetValidClass: boolean = False): boolean;
begin
  result := AIsValid;
  if AIsValid then
    ControlValid(AElementId, SetValidClass)
  else
    ControlInvalid(AElementId);
end;

function TCoreWebForm.ControlValidOrNone(const AElementId: string; const AIsValid: boolean): boolean;
begin
  result := AIsValid;
  if AIsValid then
    ControlValid(AElementId, True)
  else
    ControlNoValidity(AElementId);
end;

procedure TCoreWebForm.ControlVoid(const AElementId: string);
begin
  THTMLHelper.removeClass(AElementId, 'is-invalid');
  THTMLHelper.removeClass(AElementId, 'is-valid');
  THTMLHelper.addClass(AElementId, 'is-void');
end;

function TCoreWebForm.InputQuery(const ACaption, APrompt: string; var AValue: string;
  const AEditType: TEditType): boolean;
var
  AForm: TInputQueryForm;
  lRetval: TModalResult;
begin
  AForm := TInputQueryForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TForm, AForm.Load());
    AForm.Prompt := APrompt;
    AForm.EditType := AEditType;
    AForm.InputValue := AValue;
    lRetval := await(TModalResult, AForm.Execute);
    Result := lRetval = mrOK;

    if Result then
      AValue := AForm.InputValue;

  finally
    AForm.Free;
    AForm := nil;
  end;

end;

procedure TCoreWebForm.SelfClose;
begin
  if Assigned(FOnSelfClose) then
    FOnSelfClose(Self);
end;

procedure TCoreWebForm.ShowAToast(const ATitle, AMessage, AImage: string);
begin
  MainPage.ShowAToast(ATitle, AMessage, AImage);
end;

procedure TCoreWebForm.ShowAnAwesomeToast(const ATitle, AMessage, AIcon: string);
begin
  MainPage.ShowAnAwesomeToast(ATitle, AMessage, AIcon);
end;

procedure TCoreWebForm.ShowAPopUp(AFormClass: TFormClass);
var
  AForm: TForm;
begin
  AForm := AFormClass.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    await(TModalResult, AForm.Execute);
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TCoreWebForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CloseThisButton := TButton.Create('CloseThisButton');

  CloseThisButton.BeforeLoadDFMValues;
  try
    Name := 'CoreWebForm';
    Width := 960;
    Height := 720;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    CloseThisButton.SetParentComponent(Self);
    CloseThisButton.Name := 'CloseThisButton';
    CloseThisButton.Left := 900;
    CloseThisButton.Top := 12;
    CloseThisButton.Width := 48;
    CloseThisButton.Height := 38;
    CloseThisButton.Margins.Left := 5;
    CloseThisButton.Margins.Top := 5;
    CloseThisButton.Margins.Right := 5;
    CloseThisButton.Margins.Bottom := 5;
    CloseThisButton.Caption := 'X';
    CloseThisButton.ElementClassName := 'btn btn-secondary';
    CloseThisButton.ElementFont := efCSS;
    CloseThisButton.ElementPosition := epIgnore;
    CloseThisButton.HeightStyle := ssAuto;
    CloseThisButton.HeightPercent := 100.000000000000000000;
    CloseThisButton.WidthStyle := ssAuto;
    CloseThisButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseThisButton, Self, 'OnClick', 'CloseThisButtonClick');
  finally
    CloseThisButton.AfterLoadDFMValues;
  end;
end;

end.
