unit ForgottenPasswordForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseCoreForm, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.WebCtrls,
  XData.Web.Client;

type
  TFortgottenPassword = class(TCoreWebForm)
    RequestButton: TButton;
    CancelButton: TButton;
    FPUserName: TEdit;
    FPXDataWebClient: TXDataWebClient;
    procedure CancelButtonClick(Sender: TObject);
    procedure RequestButtonClick(Sender: TObject);
  private
    { Private declarations }
    [async]
    procedure RequestPassword; async;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

uses
  MainDataModule, App.Utils;

{$R *.dfm}

procedure TFortgottenPassword.CancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TFortgottenPassword.RequestButtonClick(Sender: TObject);
begin
  RequestPassword;
end;

procedure TFortgottenPassword.RequestPassword;
var lUserName: String;
begin
  lUserName := FPUserName.Text;
  await(TXDataClientResponse, FPXDataWebClient.RawInvokeAsync(ILOGINSVC_FORGOTPASSWORD, [lUserName]));
  ModalResult := mrOK;
end;

procedure TFortgottenPassword.LoadDFMValues;
begin
  inherited LoadDFMValues;

  RequestButton := TButton.Create('RequestButton');
  CancelButton := TButton.Create('CancelButton');
  FPUserName := TEdit.Create('FPUserName');
  FPXDataWebClient := TXDataWebClient.Create(Self);

  CloseThisButton.BeforeLoadDFMValues;
  RequestButton.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  FPUserName.BeforeLoadDFMValues;
  FPXDataWebClient.BeforeLoadDFMValues;
  try
    Height := 286;
    ElementClassName := 'PopUpForm';
    CloseThisButton.Visible := False;
    RequestButton.SetParentComponent(Self);
    RequestButton.Name := 'RequestButton';
    RequestButton.Left := 88;
    RequestButton.Top := 192;
    RequestButton.Width := 96;
    RequestButton.Height := 25;
    RequestButton.Caption := 'Request';
    RequestButton.ChildOrder := 1;
    RequestButton.ElementClassName := 'btn btn-primary';
    RequestButton.ElementFont := efCSS;
    RequestButton.ElementPosition := epIgnore;
    RequestButton.HeightStyle := ssAuto;
    RequestButton.HeightPercent := 100.000000000000000000;
    RequestButton.WidthStyle := ssAuto;
    RequestButton.WidthPercent := 100.000000000000000000;
    SetEvent(RequestButton, Self, 'OnClick', 'RequestButtonClick');
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 224;
    CancelButton.Top := 192;
    CancelButton.Width := 96;
    CancelButton.Height := 25;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 1;
    CancelButton.ElementClassName := 'btn btn-secondary';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    FPUserName.SetParentComponent(Self);
    FPUserName.Name := 'FPUserName';
    FPUserName.Left := 104;
    FPUserName.Top := 96;
    FPUserName.Width := 121;
    FPUserName.Height := 22;
    FPUserName.ChildOrder := 3;
    FPUserName.ElementClassName := 'form-control';
    FPUserName.ElementFont := efCSS;
    FPUserName.ElementPosition := epIgnore;
    FPUserName.HeightStyle := ssAuto;
    FPUserName.HeightPercent := 100.000000000000000000;
    FPUserName.WidthStyle := ssAuto;
    FPUserName.WidthPercent := 100.000000000000000000;
    FPXDataWebClient.SetParentComponent(Self);
    FPXDataWebClient.Name := 'FPXDataWebClient';
    FPXDataWebClient.Connection := MainData.AuthConnection;
    FPXDataWebClient.Left := 312;
    FPXDataWebClient.Top := 104;
  finally
    CloseThisButton.AfterLoadDFMValues;
    RequestButton.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    FPUserName.AfterLoadDFMValues;
    FPXDataWebClient.AfterLoadDFMValues;
  end;
end;

end.