unit DashDataForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  Vcl.Controls;

type
  TDashBoardData = class(TForm)
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  DashBoardData: TDashBoardData;

implementation

{$R *.dfm}

procedure TDashBoardData.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'DashBoardData';
    Width := 389;
    Height := 813;
  finally
  end;
end;

end.