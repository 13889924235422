unit App.Utils;

interface

uses
  System.SysUtils,
  WebLib.ExtCtrls,
  XData.Web.Dataset,
  WebLib.CDS,
  JS,
  Data.DB;

type

  TViewOption = (voNone, voReadOnly, voEdit, voCreateAndEdit);
  TValueChangeState = (vcsNoChange, vcsValid, vcsInvalid);

  TSysUtils = class
    class function IsRef(const Value: string): Boolean;
    class function IsNumber(const Value: string): Boolean;

    class function JobActive(const AStatus: string): Boolean;

    class function SplitOnCaps(const Value: string): string;
    class function CapitaliseFirstLetter(const Value: string): string;
    class function CapitaliseWords(const Value: string): string;

    class function ConCat(Value: array of string; const ADelim: string): string;

    class function SecondsAsTime(const ASeconds: Int64): string;
    class function DateStrToDate(const Value: string): TDate;

    class procedure LookUpValuesLoad(Source, Target: TLookupValues; const StartIndex: Integer = 1);
    class function FindLookUpValue(Source: TLookupValues; const AValue: string): string;
    class function LoadXDataRows(Source: TXDataWebDataset): JS.TJSArray;
    class procedure JsonToClientDatset(Source: JS.TJSArray; Target: TClientDataset);
    class procedure XDataToClientDataset(Source: TXDataWebDataset; Target: TClientDataset);

  end;

  TRefStatus = (RefEmpty, RefNotChecked, RefChecking, RefUnique, RefInUse);

const
  YesNo: array [Boolean] of string = ('No', 'Yes');
  SYS_DATE_FORMAT = 'dd/mm/yyyy';
  SYS_DATETIME_FORMAT = 'dd/mm/yyyy hh:nn';
  SYS_MONEY_FORMAT = '#,##0.00';
  GiftAid_Scheme: array [Boolean] of string = ('MethodA', 'Standard');
  key_tab = 9;
  key_enter = 13;
  key_space = 32;
  key_left = 37;
  key_up = 38;
  key_right = 39;
  key_down = 40;
  smWordDelimiters: array of Char = [' ', ';', ':', ',', ',', ')', '-', #10, #13, #160];
  ChangeStateOk = [vcsNoChange, vcsValid];


  SVC_CHECK_USERNAME = 'ISMXUtilsService.IsUserNameUnique';
  SVC_UPDATEPASSWORD = 'ISMXUtilsService.UpdatePassword'; // (const CurrentPassword, NewPassword: String): String;
  SVC_SETPASSWORD = 'ISMXUtilsService.SetPassword'; // UserId, Password
  SVC_GENERATEPASSWORD = 'ISMXUtilsService.GeneratePassword'; // UserId
  SVC_GETNEXTID = 'ISMXUtilsService.GetNextSequenceNo'; // (const ASequenceName: String): Integer;
  SVC_GETRECUSERS = 'ISMXUtilsService.GetRecordUser'; // (const AAddedBy, AUpdatedBy: Integer): TAuditUsers;
  SVC_GET_THINGS_TODO = 'ISMXUtilsService.GetThingsToDo'; // : TList<TThingsToDo>;
  SVC_FILEEXISTS = 'ISMXUtilsService.FileExists'; //(const AStore, APath, AFile: String): Boolean;

  IJOBSVC_USERS = 'IJobsService.GetJobUsers'; // SubmittedBy, OwnedBy, CompletedBy
  IJOBSVC_GETFILE = 'IJobsService.GetFile'; // JobId returns Stream
  IJOBSVC_SCHEDULEJOB = 'IJobsService.ScheduleJob';
  // (JobClass; JobVisibility; AParams; OutputOption,RunLevel): JobId(Integer)
  IJOBSVC_SCHEDULE_FILE_JOB = 'IJobsService.ScheduleJobWithFile';
  // (JobClass,JobVisibility,AParams,OutputOption,AFileName,AFile): Integer;

  ILOGINSVC_FORGOTPASSWORD = 'ILoginService.ForgottenPassword'; // (const UserName: string): string;';

  ISMXSUPPORTSVC_REFLECT = 'IsmxSupportService.Reflect';
  ISMXSUPPORTSVC_REPORT_ERROR = 'IsmxSupportService.ReportError';
  // (const AMessage, AFile: string; const ALineNumber, AColNumber: Integer; const AStack, AError: String);
  ISMXSUPPORTSVC_CONTACT_SUPPORT = 'IsmxSupportService.ContactSupport';
  // (const ASubject, AFrom, AMessage, AError: String);
  ISMXSUPPORTSVC_LOG_ERROR = 'IsmxSupportService.LogError';
  // (const AMessage, AFile: string; const ALineNumber,AColNumber: Integer; const AStack: string; const ACount: Integer);

  USERLEVEL_NONE = 0;
  USERLEVEL_READONLY = 1;
  USERLEVEL_USER = 2;
  USERLEVEL_ADMINUSER = 3;
  USERLEVEL_SUPERUSER = 5;
  USERLEVEL_SYSUSER = 6;


implementation

uses
  System.DateUtils,
  System.StrUtils;

{ TSysHelper }

class function TSysUtils.JobActive(const AStatus: string): Boolean;
begin
  result := (AStatus <> 'Complete') and (AStatus <> 'Failed') and (AStatus <> 'Cancelled');
end;

class procedure TSysUtils.JsonToClientDatset(Source: JS.TJSArray; Target: TClientDataset);
begin
  Target.Rows := Source;
end;

class function TSysUtils.CapitaliseFirstLetter(const Value: string): string;
begin
  if Value = '' then
    Exit('');
  result := Value.Substring(0, 1).ToUpper + Value.Substring(1).ToLower;
end;

class function TSysUtils.CapitaliseWords(const Value: string): string;
var
  i: Integer;
  c, lc: Char;
  lWord: string;
  lWords: TArray<string>;
begin

  result := '';
  lWords := Value.Split(smWordDelimiters);
  for lWord in lWords do
  begin
    if lWord = '' then
      Continue;
    result := result + lWord.Substring(0, 1).ToUpper + lWord.Substring(1).ToLower + ' ';
  end;

  result := result.TrimRight;

end;

class function TSysUtils.ConCat(Value: array of string; const ADelim: string): string;
var
  i: Integer;
begin
  result := '';
  for i := 0 to Length(Value) - 1 do
  begin
    if Value[i] <> '' then
      result := result + Value[i] + ADelim;
  end;
  result := result.Substring(0, result.Length - ADelim.Length);
end;

class function TSysUtils.SecondsAsTime(const ASeconds: Int64): string;
var
  lTime: TDateTime;
begin
  lTime := IncSecond(0.0, ASeconds);
  result := FormatDateTime('hh:nn:ss', lTime);
  if lTime >= 1 then
    result := Trunc(lTime).ToString + 'days ' + result;
end;

class function TSysUtils.DateStrToDate(const Value: string): TDate;
var
  lDate: TArray<string>;
begin
  try
    lDate := Value.Split(['-']);
    result := EncodeDate(lDate[0].ToInteger, lDate[1].ToInteger, lDate[2].ToInteger);
  except
    result := 0;
  end;
end;

class function TSysUtils.FindLookUpValue(Source: TLookupValues; const AValue: string): string;
var
  i: Integer;
begin
  result := '';
  for i := 0 to Source.Count - 1 do
  begin
    if TLookupValueItem(Source.Items[i]).Value = AValue then
      Exit(TLookupValueItem(Source.Items[i]).DisplayText);
  end;
end;

class function TSysUtils.IsNumber(const Value: string): Boolean;
var
  i: Integer;
begin
  result := False;
  for i := 1 to Length(Value) do
  begin
    result := (Value[i] in ['0' .. '9']);
    if not result then
      Exit;
  end;
end;

class function TSysUtils.IsRef(const Value: string): Boolean;
var
  lLenVal: Integer;
begin
  result := False;
  lLenVal := Length(Value);
  if lLenVal < 3 then
    Exit;
  result := (Upcase(Value[1]) in ['A' .. 'Z']) and (Value[2] in ['0' .. '9']) and (Value[3] in ['0' .. '9']);
  if result and (lLenVal > 3) then // Old Donor Id
  begin
    if lLenVal > 7 then
      Exit(False);

    result := (Value[4] in ['0' .. '9']) and (Value[5] in ['0' .. '9']) and (Value[6] in ['0' .. '9']) and
      (Value[7] in ['0' .. '9']);

  end;

end;

class function TSysUtils.LoadXDataRows(Source: TXDataWebDataset): JS.TJSArray;
begin
  { TODO : Really should use a bookmark }
  result := TJSArray.new;
  Source.First;
  while not Source.Eof do
  begin
    result.push(Source.CurrentData);
    Source.Next;
  end;
  Source.First;
end;

class procedure TSysUtils.LookUpValuesLoad(Source, Target: TLookupValues; const StartIndex: Integer = 1);
var
  i: Integer;
begin
  for i := StartIndex to Source.Count - 1 do
  begin
    Target.AddPair(TLookupValueItem(Source.Items[i]).Value, TLookupValueItem(Source.Items[i]).DisplayText);
  end;

end;

class function TSysUtils.SplitOnCaps(const Value: string): string;
var
  i: Integer;
  c: Char;
begin
  if Value.Length < 4 then
    Exit(Value);

  result := Value.Chars[0];
  if Value.Length = 1 then
    Exit;
  for i := 1 to Value.Length - 1 do
  begin
    c := Value.Chars[i];
    if (c in ['A' .. 'Z']) then
      result := result + ' ' + c
    else
      result := result + c;
  end;
end;

class procedure TSysUtils.XDataToClientDataset(Source: TXDataWebDataset; Target: TClientDataset);
var
  ARows: JS.TJSArray;
begin
  ARows := LoadXDataRows(Source);
  JsonToClientDatset(ARows, Target);
end;

end.
