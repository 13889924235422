unit MainDataModule;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Modules,
  WEBLib.Forms,
  XData.Web.Connection,
  AppConfig,
  WEBLib.REST,
  App.Utils,
  XData.Web.Client,
  App.Types;

type

  TMainData = class(TDataModule)
    DataConnection: TXDataWebConnection;
    WebClient: TXDataWebClient;
    AuthConnection: TXDataWebConnection;
    AuthClient: TXDataWebClient;
    procedure WebDataModuleCreate(Sender: TObject);
    procedure DataConnectionError(Error: TXDataWebConnectionError);
    procedure DataConnectionRequest(Args: TXDataWebConnectionRequest);
    procedure DataConnectionResponse(Args: TXDataWebConnectionResponse);
    procedure WebClientError(Error: TXDataClientError);
  private
    { Private declarations }
    FLocalErrorHandling: TApplicationErrorType;
    FShowMessageProc: TShowMessageProc;
    FErrorProc: TErrorMessageProc;
    FXDataErrorProc: TXDataErrorProc;
    FUnauthorizedAccessProc: TUnauthorizedAccessProc;
    FOnAuthUpdatedProc: TNotifyEvent;
    FLastError: TApplicationError;
    FLastErrorTime: TDateTime;
    FErrorCount: Integer;
  public
    procedure OnApplicationError(Sender: TObject; AError: TApplicationError; var Handled: Boolean);
    procedure InitApp(SuccessProc: TSuccessProc; UnauthorizedAccessProc: TUnauthorizedAccessProc);
    property ShowMessageProc: TShowMessageProc write FShowMessageProc;
    property ErrorProc: TErrorMessageProc write FErrorProc;
    property XDataErrorProc: TXDataErrorProc read FXDataErrorProc write FXDataErrorProc;
    property OnAuthUpdatedProc: TNotifyEvent read FOnAuthUpdatedProc write FOnAuthUpdatedProc;
  protected procedure LoadDFMValues; override; end;

var
  MainData: TMainData;

implementation

uses
  System.DateUtils,
  Auth.Service,
  WEBLib.Dialogs;

{%CLASSGROUP 'Vcl.Controls.TControl'}
{$R *.dfm}

procedure TMainData.WebDataModuleCreate(Sender: TObject);
begin
  FormatSettings.DateSeparator := '/';
  FormatSettings.ShortDateFormat := 'dd/mm/yyyy';
{$IFDEF RELEASE}
  FLocalErrorHandling := aeDialog;
  Application.ErrorType := aeSilent; // aeSilent, aeDialog, aeAlert, aeFooter
  Application.OnError := self.OnApplicationError;
{$ENDIF}
{$IFDEF DEBUG}
  FLocalErrorHandling := aeFooter;
  Application.ErrorType := aeFooter; // aeSilent, aeDialog, aeAlert, aeFooter
{$ENDIF}
end;

procedure TMainData.DataConnectionError(Error: TXDataWebConnectionError);
begin
  //
end;

procedure TMainData.DataConnectionRequest(Args: TXDataWebConnectionRequest);
begin
  if AuthService.Authenticated then
    Args.Request.Headers.SetValue('Authorization', 'Bearer ' + AuthService.GetToken);
end;

procedure TMainData.DataConnectionResponse(Args: TXDataWebConnectionResponse);
var
  LToken: string;
begin
  if Args.Response.StatusCode = 401 then
    FUnauthorizedAccessProc(Format('%d: %s', [Args.Response.StatusCode, Args.Response.ContentAsText]))
  else if Args.Response.Headers.GetIfExists('jwtupd', LToken) then
  begin
    // Update JWT
    AuthService.SetToken(LToken);
    if Assigned(FOnAuthUpdatedProc) then
      FOnAuthUpdatedProc(self);
  end;

end;

procedure TMainData.InitApp(SuccessProc: TSuccessProc; UnauthorizedAccessProc: TUnauthorizedAccessProc);

  procedure ConfigLoaded(Config: TAppConfig);
  begin

    if Config.BaseUrl <> '' then
    begin
      DataConnection.URL := Config.BaseUrl;
    end;

    if Config.AuthURL <> '' then
      AuthConnection.URL := Config.AuthURL
    else
      AuthConnection.URL := Config.BaseUrl;

    AuthConnection.Open(SuccessProc);

  end;

begin
  FUnauthorizedAccessProc := UnauthorizedAccessProc;
  LoadConfig(@ConfigLoaded);
end;

procedure TMainData.OnApplicationError(Sender: TObject; AError: TApplicationError; var Handled: Boolean);
begin

  if (AError.ALineNumber = FLastError.ALineNumber) and (SecondsBetween(Now, FLastErrorTime) < 60) then
  begin
    Inc(FErrorCount);
    FLastErrorTime := Now;
    if FErrorCount mod 100 = 0 then
      WebClient.RawInvoke(ISMXSUPPORTSVC_LOG_ERROR, [AError.AMessage, AError.AFile, AError.ALineNumber, AError.AColNumber,
        AError.AStack, FErrorCount]);

    Handled := True;
  end
  else if FErrorCount > 1 then
  begin
    WebClient.RawInvoke(ISMXSUPPORTSVC_LOG_ERROR, [FLastError.AMessage, FLastError.AFile, FLastError.ALineNumber,
      FLastError.AColNumber, FLastError.AStack, FErrorCount]);
  end;

  FErrorCount := 1;
  FLastErrorTime := Now;
  FLastError.AMessage := AError.AMessage;
  FLastError.AFile := AError.AFile;
  FLastError.ALineNumber := AError.ALineNumber;
  FLastError.AColNumber := AError.AColNumber;
  FLastError.AStack := AError.AStack;
  // FLastError.AError

  if Assigned(FErrorProc) then
  begin
    FErrorProc(AError, Handled);
    Handled := True;
  end
  else
  begin
    WebClient.RawInvoke(ISMXSUPPORTSVC_LOG_ERROR, [AError.AMessage, AError.AFile, AError.ALineNumber,
      AError.AColNumber, AError.AStack, JS.TJSJSON.stringify(AError.AError)]);

    if Assigned(FShowMessageProc) and (FLocalErrorHandling = aeDialog) then
    begin
      FShowMessageProc('There has been an error: ' + AError.AMessage +
        '.<br /> This has been notified to the developers.', TMsgDlgType.mtError);

      Handled := True;
    end;
  end;
end;

procedure TMainData.WebClientError(Error: TXDataClientError);
begin
  if Assigned(FXDataErrorProc) then
  begin

  end
  else
  begin

//  (const AMessage, AFile: string; const ALineNumber, AColNumber: Integer; const AStack, AError: String);
//    WebClient.RawInvoke(ISMXUTILSSVC_REPORT_ERROR, [Error.ErrorMessage, 'XData', Error.ErrorCode,
//      Error., AError.AStack, JS.TJSJSON.stringify(AError.AError)]);

    if Assigned(FShowMessageProc) and (FLocalErrorHandling = aeDialog) then
    begin
      FShowMessageProc('There has been an error: ' + Error.ErrorCode + ' - ' + Error.ErrorMessage +
        '.<br /> Status Code: ' + Error.StatusCode.ToString, TMsgDlgType.mtError);
    end;

  end;

end;

procedure TMainData.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DataConnection := TXDataWebConnection.Create(Self);
  WebClient := TXDataWebClient.Create(Self);
  AuthConnection := TXDataWebConnection.Create(Self);
  AuthClient := TXDataWebClient.Create(Self);

  DataConnection.BeforeLoadDFMValues;
  WebClient.BeforeLoadDFMValues;
  AuthConnection.BeforeLoadDFMValues;
  AuthClient.BeforeLoadDFMValues;
  try
    Name := 'MainData';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    Height := 150;
    Width := 323;
    DataConnection.SetParentComponent(Self);
    DataConnection.Name := 'DataConnection';
    DataConnection.URL := 'http://localhost:2017/giftaider';
    SetEvent(DataConnection, Self, 'OnError', 'DataConnectionError');
    SetEvent(DataConnection, Self, 'OnRequest', 'DataConnectionRequest');
    SetEvent(DataConnection, Self, 'OnResponse', 'DataConnectionResponse');
    DataConnection.Left := 40;
    DataConnection.Top := 24;
    WebClient.SetParentComponent(Self);
    WebClient.Name := 'WebClient';
    WebClient.Connection := DataConnection;
    SetEvent(WebClient, Self, 'OnError', 'WebClientError');
    WebClient.Left := 40;
    WebClient.Top := 88;
    AuthConnection.SetParentComponent(Self);
    AuthConnection.Name := 'AuthConnection';
    AuthConnection.Left := 136;
    AuthConnection.Top := 24;
    AuthClient.SetParentComponent(Self);
    AuthClient.Name := 'AuthClient';
    AuthClient.Connection := AuthConnection;
    AuthClient.Left := 136;
    AuthClient.Top := 88;
  finally
    DataConnection.AfterLoadDFMValues;
    WebClient.AfterLoadDFMValues;
    AuthConnection.AfterLoadDFMValues;
    AuthClient.AfterLoadDFMValues;
  end;
end;

end.
