unit App.Layout.Utils;

interface

uses
  Web,
  WebLib.WebCtrls;

type

  TLayoutUtils = class
  public

    class function RowActionSpan(const AParentElement: TJSHTMLElement; const AFontAwesomeClass: string): THTMLSpan;
  end;

implementation

uses
System.SysUtils,
WebLib.Controls;

{ TLayoutUtils }

class function TLayoutUtils.RowActionSpan(const AParentElement: TJSHTMLElement;
  const AFontAwesomeClass: string): THTMLSpan;
begin
  result := THTMLSpan.Create(nil);
  result.Cursor := crHandPoint;
  result.ElementPosition := epIgnore;
  result.HeightStyle := ssAuto;
  result.WidthStyle := ssAuto;
  result.ParentElement := AParentElement;
  if AFontAwesomeClass.StartsWith('<') then
    result.HTML.Text := AFontAwesomeClass
  else
    result.HTML.Text := format('<i class="%s fa-fw fa-lg"></i> ', [AFontAwesomeClass]);
end;

end.
