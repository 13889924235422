unit BaseList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.DB,
  WEBLib.Lists,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Grid.Plugins,
  App.Types,
  BaseForm,
  BaseCoreForm,
  WEBLib.Actions,
  WEBLib.WebCtrls,
  App.Utils,
  WEBLib.Toast,
  smx.webcore.types, Vcl.Imaging.GIFImg, WEBLib.ExtCtrls;

type
  TBaseListForm = class(TCoreWebForm)
    MainDataset: TXDataWebDataSet;
    MainDataSource: TDataSource;
    cbPageSize: TComboBox;
    edSearch: TEdit;
    lbPaginationInfo: TLabel;
    lcPaginator: TListControl;
    DataTable: TDBTableControl;
    AddButton: TButton;
    MessageLabel: TLabel;
    SearchButton: TButton;
    WebElementActionList1: TElementActionList;
    ListWaitMessage: TWaitMessage;
    ListCloseTimer: TTimer;
    procedure WebFormDestroy(Sender: TObject);
    procedure AddButtonClick(Sender: TObject);
    procedure cbPageSizeChange(Sender: TObject);
    procedure DataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; AElement:
      TJSHTMLElementRecord);
    procedure ListCloseTimerTimer(Sender: TObject);
    procedure MainDatasetAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
    procedure MainDatasetAfterPost(DataSet: TDataSet);
    procedure SearchButtonClick(Sender: TObject);
    procedure WebElementActionList1Actions0Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    { Private declarations }

  protected
    FGridPlugin: TGridPlugin;
    FIdField: string;
    function RecordViewOption: TViewOption; virtual;
    function InitSortCol: Integer; virtual;
    function InitSortDirection: TSortDirection; virtual;
    procedure UnLockControls(const AUnLock: Boolean); virtual;
    function CanClose: boolean; override;
    [async]
    procedure EditRecord(const ARecordId: string); async; virtual;
    procedure AddRecord(const ARecordId: string); virtual;
    procedure SetEditFormLookUps(AForm: TForm); virtual;
    /// <param name="AFontAwesomeClass">
    ///   this is the 'fad fa-edit' part of '&lt;i class="fad fa-edit
    ///   fa-lg"&gt;&lt;/i&gt;'
    /// </param>
    function RowActionSpan(const AParentElement: TJSHTMLElement; const AFontAwesomeClass: string): THTMLSpan;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); virtual;
    procedure PrepareForm; virtual; abstract;
    procedure LoadData; virtual; abstract;
    function GetEditFormClass: TFormClass; virtual; abstract;
    procedure SearchData(const Value: string); virtual;

  public
    { Public declarations }
    procedure AfterCreated; override;
  protected procedure LoadDFMValues; override; end;

implementation

uses
  MainDataModule;

{$R *.dfm}

procedure TBaseListForm.WebFormDestroy(Sender: TObject);
begin
  FGridPlugin.Free;
  inherited;
end;

procedure TBaseListForm.AddButtonClick(Sender: TObject);
begin
   AddRecord('');
end;

procedure TBaseListForm.AddRecord(const ARecordId: string);
begin
   EditRecord(ARecordId);
end;

procedure TBaseListForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure EditClick(Sender: TObject);
  begin
    EditRecord(ARecordId);
  end;

var
  Span: THTMLSpan;
begin
  if (RecordViewOption in [voEdit, voCreateAndEdit]) then
  begin
    Span := RowActionSpan(AParentElement, 'fad fa-edit');
    Span.OnClick := @EditClick;
  end
  else if RecordViewOption = voReadOnly then
  begin
    Span := RowActionSpan(AParentElement, 'fad fa-eye');
    Span.OnClick := @EditClick;
  end;
end;

procedure TBaseListForm.AfterCreated;
begin
  LoadData;
end;

function TBaseListForm.CanClose: boolean;
begin
  result := True;
end;

procedure TBaseListForm.cbPageSizeChange(Sender: TObject);
begin
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text), True);
end;

procedure TBaseListForm.DataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
var
  RecordId: string;
begin
  if ARow = 0 then
    Exit;

  if DataTable.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := MainDataset.FieldByName(FIdField).AsString;
    AddRowActions(RecordId, AElement.element);
  end;
end;

procedure TBaseListForm.EditRecord(const ARecordId: string);
var
  AForm: TBaseEditForm;
begin
  AForm := TBaseEditForm(GetEditFormClass.Create(Self));
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TBaseEditForm, AForm.Load());
    AForm.IdField := FIdField;
    SetEditFormLookUps(AForm);
    AForm.RecordViewOption := self.RecordViewOption;

    if ARecordId = '' then
      MainDataset.Insert
    else
      MainDataset.Locate(FIdField, ARecordId, []);

    AForm.Dataset := MainDataset;

    await(TModalResult, AForm.Execute);

  finally
    AForm.Free;
    AForm := Nil;
  end;
end;

function TBaseListForm.InitSortCol: Integer;
begin
  result := -1;
end;

function TBaseListForm.InitSortDirection: TSortDirection;
begin
  result := sdAsc
end;

procedure TBaseListForm.ListCloseTimerTimer(Sender: TObject);
begin
  ListCloseTimer.Enabled := False;
  ListWaitMessage.Hide;
end;

procedure TBaseListForm.MainDatasetAfterOpen(DataSet: TDataSet);
begin
  UnLockControls(True);
end;

procedure TBaseListForm.UnLockControls(const AUnLock: Boolean);
begin
  AddButton.Enabled := (RecordViewOption = voCreateAndEdit) and AUnLock;
  SearchButton.Enabled := AUnLock;
  cbPageSize.Enabled := AUnLock;
  edSearch.Enabled := AUnLock;
end;

procedure TBaseListForm.WebFormCreate(Sender: TObject);
begin
  MessageLabel.Caption := '';
  AddButton.Enabled := (RecordViewOption = voCreateAndEdit);
  AddButton.Visible := AddButton.Enabled;

  PrepareForm;
  FGridPlugin := TGridPlugin.Create(DataTable, MainDataset, lcPaginator, lbPaginationInfo,
     TSortPair.Create(InitSortCol, InitSortDirection));
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text));
  FGridPlugin.SetFilterText(edSearch.Text);

  UnLockControls(False);

end;

procedure TBaseListForm.MainDatasetAfterPost(DataSet: TDataSet);
begin
  MainDataset.ApplyUpdates;
end;

function TBaseListForm.RecordViewOption: TViewOption;
begin
  result := voCreateAndEdit;
end;

function TBaseListForm.RowActionSpan(const AParentElement: TJSHTMLElement; const AFontAwesomeClass: string): THTMLSpan;
begin
  Result := THTMLSpan.Create(nil);
  Result.Cursor := crHandPoint;
  Result.ElementPosition := epIgnore;
  Result.HeightStyle := ssAuto;
  Result.WidthStyle := ssAuto;
  Result.ParentElement := AParentElement;
  if AFontAwesomeClass.StartsWith('<') then
    Result.HTML.Text := AFontAwesomeClass
  else
    Result.HTML.Text := format('<i class="%s fa-fw fa-lg"></i> ', [AFontAwesomeClass]);
end;

procedure TBaseListForm.SearchButtonClick(Sender: TObject);
begin
  SearchData(edSearch.Text);
end;

procedure TBaseListForm.SearchData(const Value: string);
begin
  FGridPlugin.SetFilterText(Value, True);
end;

procedure TBaseListForm.SetEditFormLookUps(AForm: TForm);
begin
//For descendants
end;

procedure TBaseListForm.WebElementActionList1Actions0Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event:
  TJSEventParameter);
begin

  if TJSKeyboardEvent(Event.JSEvent).Key = 'Enter' then
  begin
    Event.JSEvent.preventDefault;
    SearchData(edSearch.Text);
  end;
end;

procedure TBaseListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbPaginationInfo := TLabel.Create('PageInfo');
  MessageLabel := TLabel.Create('MessageLabel');
  cbPageSize := TComboBox.Create('PageSizeSelect');
  edSearch := TEdit.Create('SearchText');
  lcPaginator := TListControl.Create('Pagination');
  DataTable := TDBTableControl.Create('DataTable');
  AddButton := TButton.Create('AddButton');
  SearchButton := TButton.Create('SearchButton');
  ListWaitMessage := TWaitMessage.Create(Self);
  MainDataset := TXDataWebDataSet.Create(Self);
  MainDataSource := TDataSource.Create(Self);
  WebElementActionList1 := TElementActionList.Create(Self);
  ListCloseTimer := TTimer.Create(Self);

  lbPaginationInfo.BeforeLoadDFMValues;
  MessageLabel.BeforeLoadDFMValues;
  cbPageSize.BeforeLoadDFMValues;
  edSearch.BeforeLoadDFMValues;
  lcPaginator.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  SearchButton.BeforeLoadDFMValues;
  ListWaitMessage.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDataSource.BeforeLoadDFMValues;
  WebElementActionList1.BeforeLoadDFMValues;
  ListCloseTimer.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    lbPaginationInfo.SetParentComponent(Self);
    lbPaginationInfo.Name := 'lbPaginationInfo';
    lbPaginationInfo.Left := 8;
    lbPaginationInfo.Top := 376;
    lbPaginationInfo.Width := 112;
    lbPaginationInfo.Height := 15;
    lbPaginationInfo.Caption := 'No records to display';
    lbPaginationInfo.ElementFont := efCSS;
    lbPaginationInfo.ElementPosition := epIgnore;
    lbPaginationInfo.HeightStyle := ssAuto;
    lbPaginationInfo.HeightPercent := 100.000000000000000000;
    lbPaginationInfo.WidthStyle := ssAuto;
    lbPaginationInfo.WidthPercent := 100.000000000000000000;
    MessageLabel.SetParentComponent(Self);
    MessageLabel.Name := 'MessageLabel';
    MessageLabel.Left := 232;
    MessageLabel.Top := 24;
    MessageLabel.Width := 74;
    MessageLabel.Height := 15;
    MessageLabel.Caption := 'MessageLabel';
    MessageLabel.ElementFont := efCSS;
    MessageLabel.ElementPosition := epIgnore;
    MessageLabel.HeightStyle := ssAuto;
    MessageLabel.HeightPercent := 100.000000000000000000;
    MessageLabel.HTMLType := tDIV;
    MessageLabel.WidthStyle := ssAuto;
    MessageLabel.WidthPercent := 100.000000000000000000;
    cbPageSize.SetParentComponent(Self);
    cbPageSize.Name := 'cbPageSize';
    cbPageSize.Left := 8;
    cbPageSize.Top := 67;
    cbPageSize.Width := 81;
    cbPageSize.Height := 23;
    cbPageSize.ElementClassName := 'form-select';
    cbPageSize.ElementFont := efCSS;
    cbPageSize.ElementPosition := epIgnore;
    cbPageSize.HeightStyle := ssAuto;
    cbPageSize.HeightPercent := 100.000000000000000000;
    cbPageSize.Text := '10';
    cbPageSize.WidthStyle := ssAuto;
    cbPageSize.WidthPercent := 100.000000000000000000;
    SetEvent(cbPageSize, Self, 'OnChange', 'cbPageSizeChange');
    cbPageSize.ItemIndex := 0;
    cbPageSize.Items.BeginUpdate;
    try
      cbPageSize.Items.Clear;
      cbPageSize.Items.Add('10');
      cbPageSize.Items.Add('25');
      cbPageSize.Items.Add('50');
      cbPageSize.Items.Add('100');
    finally
      cbPageSize.Items.EndUpdate;
    end;
    edSearch.SetParentComponent(Self);
    edSearch.Name := 'edSearch';
    edSearch.Left := 408;
    edSearch.Top := 67;
    edSearch.Width := 121;
    edSearch.Height := 21;
    edSearch.ChildOrder := 1;
    edSearch.ElementClassName := 'form-control';
    edSearch.ElementFont := efCSS;
    edSearch.ElementPosition := epIgnore;
    edSearch.HeightStyle := ssAuto;
    edSearch.HeightPercent := 100.000000000000000000;
    edSearch.WidthStyle := ssAuto;
    edSearch.WidthPercent := 100.000000000000000000;
    lcPaginator.SetParentComponent(Self);
    lcPaginator.Name := 'lcPaginator';
    lcPaginator.Left := 240;
    lcPaginator.Top := 365;
    lcPaginator.Width := 289;
    lcPaginator.Height := 25;
    lcPaginator.HeightStyle := ssAuto;
    lcPaginator.WidthStyle := ssAuto;
    lcPaginator.HeightPercent := 100.000000000000000000;
    lcPaginator.WidthPercent := 100.000000000000000000;
    lcPaginator.ChildOrder := 3;
    lcPaginator.DefaultItemClassName := 'page-item';
    lcPaginator.DefaultItemLinkClassName := 'page-link';
    lcPaginator.ElementFont := efCSS;
    lcPaginator.ElementPosition := epIgnore;
    lcPaginator.ElementListClassName := 'pagination';
    lcPaginator.Style := lsPagination;
    DataTable.SetParentComponent(Self);
    DataTable.Name := 'DataTable';
    DataTable.Left := 32;
    DataTable.Top := 104;
    DataTable.Width := 513;
    DataTable.Height := 249;
    DataTable.HeightStyle := ssAuto;
    DataTable.WidthStyle := ssAuto;
    DataTable.BorderColor := clSilver;
    DataTable.ChildOrder := 4;
    DataTable.ElementFont := efCSS;
    DataTable.ElementHeaderClassName := 'thead-light';
    DataTable.ElementPosition := epIgnore;
    DataTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    SetEvent(DataTable, Self, 'OnGetCellChildren', 'DataTableGetCellChildren');
    DataTable.DataSource := MainDataSource;
    AddButton.SetParentComponent(Self);
    AddButton.Name := 'AddButton';
    AddButton.Left := 408;
    AddButton.Top := 408;
    AddButton.Width := 96;
    AddButton.Height := 25;
    AddButton.Caption := 'Add';
    AddButton.ChildOrder := 5;
    AddButton.ElementClassName := 'btn btn-primary';
    AddButton.ElementFont := efCSS;
    AddButton.ElementPosition := epIgnore;
    AddButton.HeightStyle := ssAuto;
    AddButton.HeightPercent := 100.000000000000000000;
    AddButton.WidthStyle := ssAuto;
    AddButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddButton, Self, 'OnClick', 'AddButtonClick');
    SearchButton.SetParentComponent(Self);
    SearchButton.Name := 'SearchButton';
    SearchButton.Left := 535;
    SearchButton.Top := 65;
    SearchButton.Width := 33;
    SearchButton.Height := 25;
    SearchButton.Caption := 'Find';
    SearchButton.ChildOrder := 7;
    SearchButton.ElementClassName := 'btn btn-primary input-group-append';
    SearchButton.ElementFont := efCSS;
    SearchButton.ElementPosition := epIgnore;
    SearchButton.HeightStyle := ssAuto;
    SearchButton.HeightPercent := 100.000000000000000000;
    SearchButton.WidthStyle := ssAuto;
    SearchButton.WidthPercent := 100.000000000000000000;
    SetEvent(SearchButton, Self, 'OnClick', 'SearchButtonClick');
    ListWaitMessage.SetParentComponent(Self);
    ListWaitMessage.Name := 'ListWaitMessage';
    ListWaitMessage.Left := 392;
    ListWaitMessage.Top := 24;
    ListWaitMessage.Width := 24;
    ListWaitMessage.Height := 24;
    ListWaitMessage.HeightStyle := ssAuto;
    ListWaitMessage.WidthStyle := ssAuto;
    ListWaitMessage.Opacity := 0.200000000000000000;
    ListWaitMessage.PictureURL := 'images/Whirligig.gif';
    MainDataset.SetParentComponent(Self);
    MainDataset.Name := 'MainDataset';
    MainDataset.AfterOpen := MainDatasetAfterOpen;
    MainDataset.AfterPost := MainDatasetAfterPost;
    MainDataset.Connection := MainData.DataConnection;
    MainDataset.Left := 64;
    MainDataset.Top := 8;
    MainDataSource.SetParentComponent(Self);
    MainDataSource.Name := 'MainDataSource';
    MainDataSource.DataSet := MainDataset;
    MainDataSource.Left := 144;
    MainDataSource.Top := 8;
    WebElementActionList1.SetParentComponent(Self);
    WebElementActionList1.Name := 'WebElementActionList1';
    WebElementActionList1.Actions.Clear;
    with WebElementActionList1.Actions.Add do
    begin
      Control := edSearch;
      Event := heKeypress;
      ID := 'SearchText';
      Name := 'TrapEnter';
      PreventDefault := False;
      SetEvent(Self, 'OnExecute', 'WebElementActionList1Actions0Execute');
    end;
    WebElementActionList1.Left := 312;
    WebElementActionList1.Top := 48;
    ListCloseTimer.SetParentComponent(Self);
    ListCloseTimer.Name := 'ListCloseTimer';
    ListCloseTimer.Enabled := False;
    SetEvent(ListCloseTimer, Self, 'OnTimer', 'ListCloseTimerTimer');
    ListCloseTimer.Left := 432;
    ListCloseTimer.Top := 16;
  finally
    lbPaginationInfo.AfterLoadDFMValues;
    MessageLabel.AfterLoadDFMValues;
    cbPageSize.AfterLoadDFMValues;
    edSearch.AfterLoadDFMValues;
    lcPaginator.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    SearchButton.AfterLoadDFMValues;
    ListWaitMessage.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDataSource.AfterLoadDFMValues;
    WebElementActionList1.AfterLoadDFMValues;
    ListCloseTimer.AfterLoadDFMValues;
  end;
end;

end.

